
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CheckboxInput',
  mixins: [simpleMixin],
})
export default class CheckboxInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Boolean,
    required: true,
  })
  value: boolean;

  change(value) {
    this.$emit('input', value);
  }
}
