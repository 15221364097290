
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import simpleEmit from 'components/form/mixin';
import { IContactDTO } from 'dto/IContactDTO';
import { IWidget } from 'rt/Contracts/UI/IWidget';

@Component({
  name: 'SurnameInput',
  mixins: [simpleEmit],
})
export default class SurnameInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: String,
    required: false,
  })
  value: string;

  @Prop({
    type: Object,
    required: true,
  })
  data: { name: string; surname: string };
}
