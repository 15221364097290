
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { IAlarmDTO } from 'dto/IAlarmDTO';
import { IAlarmNotificationDTO } from 'dto/IAlarmNotificationDTO';
import { AlarmController } from 'rt/UIApiControllers/BusinessObject/DTO';

@Component({
  name: 'AlarmInput',
})
export default class AlarmInput extends simpleMixin {
  alarmController = new AlarmController(Vue.axios);

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Object,
    required: false,
  })
  value: IAlarmDTO;

  emptyValue: IAlarmDTO = null;

  get alarm() {
    return this.value || this.emptyValue;
  }

  async mounted() {
    if (this.value == null) {
      this.emptyValue = await this.alarmController.GetNewInstance();
    }
  }

  handlePlusClick() {
    const newNotification = {
      actionType: this.$enums.AlarmActionType.PopUp,
      timeValue: 10,
      timeValueType: this.$enums.AlarmTimeValueType.Minutes,
    };
    this.simpleEmit({
      ...this.value,
      crossId: 1,
      notifications: [...this.alarm.notifications, newNotification],
    });
  }

  handleNotificationChange(idx: number, prop: string, v: any) {
    const notifications = [...this.alarm.notifications];
    const nn = [...notifications.slice(0, idx), { ...notifications[idx], [prop]: v }, ...notifications.slice(idx + 1)];
    this.simpleEmit({
      ...this.value,
      notifications: nn,
    });
  }

  handleRemoveNotification(idx) {
    const notifications = [...this.alarm.notifications];
    this.$emit('input', {
      ...this.value,
      notifications: [...notifications.slice(0, idx), ...notifications.slice(idx + 1)],
    });
  }
}
