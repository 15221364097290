
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ReferrerCategoryInput',
})
export default class ReferrerCategoryInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: true,
  })
  value: number[];

  change(data) {
    this.$emit(
      'input',
      data.map((d) => d),
    );
  }
}
