
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'MailingProfileInfo',
})
export default class MailingProfileInfo extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  value: any;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  showPercentage: boolean;

  get dailyLeft() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const dl = this.value?.dailyLimit - this?.value?.dayUsage;
    if (!isNaN(dl)) {
      if (dl > 0) {
        const pl = this.periodLeft;
        if (pl !== null && pl < dl) {
          return pl;
        }
        return dl;
      }
      return 0;
    }
    return null;
  }

  get periodLeft() {
    if (this.value?.periodLimit > 0) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const dl = this.value?.periodLimit - this?.value?.periodUsage;
      if (!isNaN(dl)) {
        if (dl > 0) {
          return dl;
        }
        return 0;
      }
    }
    return null;
  }

  get periodPercentage() {
    if (this.value?.periodLimit > 0) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return (this?.value?.periodUsage / this.value?.periodLimit) * 100;
    }
    return null;
  }

  get dailyPercentage() {
    if (this.value?.dailyLimit > 0) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return (this?.value?.dayUsage / this.value?.dailyLimit) * 100;
    }
    return null;
  }

  get limitPercentage() {
    const pp = this.periodPercentage ?? -1;
    const dp = this.dailyPercentage ?? -1;
    const lp = Math.max(pp, dp);
    if (lp >= 0) {
      return Math.ceil(lp);
    }
    return null;
  }

  get alertClass() {
    const lp = this.limitPercentage;
    if (lp > 90) {
      return 'text-danger';
    }
    if (lp > 50) {
      return 'text-warning';
    }
    // return 'text-primary';
    return null;
  }

  get percentageStatus() {
    const lp = this.limitPercentage;
    if (lp > 90) {
      return 'exception';
    }
    if (lp > 50) {
      return 'warning';
    }
    // return 'text-primary';
    return null;
  }
}
