//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IOrderDTO } from '../../../DTO/IOrderDTO';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IERPRowGroupingDTO } from '../../../DTO/IERPRowGroupingDTO';
import { IAddProductsByCodeRequest } from '../IAddProductsByCodeRequest';
import { ICloneRowsGroupsRequest } from '../ICloneRowsGroupsRequest';
import { IImportRowsRequest } from '../IImportRowsRequest';
import { IERPRowDTO } from '../../../DTO/IERPRowDTO';
import { IFilePreview } from './IFilePreview';

//element.Name=OrderController result.Name=OrderController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.OrderController);
/** WebApiController: OrderController */
export class OrderController extends DefaultDtoControllerProxy<IOrderDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Orders', BusinessObjectType.Order);
	}
	public RequestedByChanged(crossId: number, crossType: BusinessObjectType, erpType: BusinessObjectType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/RequestedByChanged?&crossId='+crossId+'&crossType='+crossType+'&erpType='+erpType, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OnAnagraphicChanged(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.post('Orders/OnAnagraphicChanged', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetPriceListCurrency(listId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Orders/GetPriceListCurrency?&listId='+listId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SendEmailWithPrintTemplate(dto: IActivityDTO, parentId: number, parentType: BusinessObjectType, templateName?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Orders/SendEmailWithPrintTemplate?&parentId='+parentId+'&parentType='+parentType+((templateName === null || templateName === undefined) ? '' : ('&templateName='+encodeURIComponent(templateName))), dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OnGetNewEmptyGroupRow(dto: IOrderDTO, isFooterGroup: boolean, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/OnGetNewEmptyGroupRow?&isFooterGroup='+isFooterGroup, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OnGroupByRowType(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/OnGroupByRowType', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OnGroupByProductCategory(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/OnGroupByProductCategory', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OnDiscountFormulaGroupingChanged(dto: IERPRowGroupingDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/OnDiscountFormulaGroupingChanged', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OnGroupTaxableAmountWithDiscountChanged(dto: IERPRowGroupingDTO, taxableAmountWithDiscount: number, extensionPartIdx?: number, decimals: number = 2, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/OnGroupTaxableAmountWithDiscountChanged?&taxableAmountWithDiscount='+taxableAmountWithDiscount+((extensionPartIdx === null || extensionPartIdx === undefined) ? '' : ('&extensionPartIdx='+extensionPartIdx))+'&decimals='+decimals, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetInfo(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/GetInfo', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AddProductsByCode(r: IAddProductsByCodeRequest, insertPosition: number, overrideCurrent: boolean, lang: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/AddProductsByCode?&insertPosition='+insertPosition+'&overrideCurrent='+overrideCurrent+((lang === null || lang === undefined) ? '' : ('&lang='+encodeURIComponent(lang))), r, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CloneRowsGroups(r: ICloneRowsGroupsRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/CloneRowsGroups', r, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ImportRows(r: IImportRowsRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ImportRows', r, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetNewRowInstance(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.get('Orders/GetNewRowInstance', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ApplyCalculateTotalDocument(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ApplyCalculateTotalDocument', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ApplyCalculateTotalDocumentWithFooterTotals(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/ApplyCalculateTotalDocumentWithFooterTotals', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public NewEmptyRow(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/NewEmptyRow', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogCodeChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/CatalogCodeChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LinkedProducts(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/LinkedProducts?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PriceListChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/PriceListChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnitPriceChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/UnitPriceChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public QtaChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/QtaChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnitMeasureChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/UnitMeasureChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public TaxCodeChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/TaxCodeChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DiscountFormulaChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/DiscountFormulaChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CostChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/CostChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GainChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/GainChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PercentageGainChanged(dto: IOrderDTO, rowIndex: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IERPRowDTO>
	{
		return (this.axios.post('Orders/PercentageGainChanged?&rowIndex='+rowIndex, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProcessOnChangeListId(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ProcessOnChangeListId', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProcessOnChangeCurrency(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/ProcessOnChangeCurrency', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ExportRowsInExcel(dto: IOrderDTO, exportDescription: boolean, exportDescriptionNewLine: boolean, exportExtensions: boolean, exportFreeFields: boolean, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/ExportRowsInExcel?&exportDescription='+exportDescription+'&exportDescriptionNewLine='+exportDescriptionNewLine+'&exportExtensions='+exportExtensions+'&exportFreeFields='+exportFreeFields, dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RebuildGrouping(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.post('Orders/RebuildGrouping', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RecalcDocumentTotal(dto: IOrderDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/RecalcDocumentTotal', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Orders/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Orders/Download?&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Orders/HtmlPreview?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Orders/Previews?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Orders/Preview?&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintHtml(dto: IOrderDTO, template: string, addHeaderAndFooter: boolean = false, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Orders/PrintHtml?'+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+'&addHeaderAndFooter='+addHeaderAndFooter+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Orders/GetDownloadUrl?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Orders/Archive?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Orders/PrintParameters?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOrderDTO>
	{
		return (this.axios.get('Orders/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
