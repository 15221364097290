//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { ISignature } from '../../../Interfaces/WebMail/ISignature';

//element.Name=ParseController result.Name=ParseController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.ParseController);
/** WebApiController: ParseController */
export class ParseController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in ParseController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Signature(body: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISignature>
	{
		return (this.axios.post('Parse/Signature', JSON.stringify(body), { ...axiosConfig, headers: { ...(axiosConfig || {}).headers, 'content-type': 'application/json' }, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Parse/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Parse/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
