
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'HeaderPage',
})
export default class HeaderPage extends Vue {
  @Prop({
    type: Boolean,
    default: true,
    required: false,
  })
  header: boolean;

  isMenuOpen = false;

  openMenu(v: boolean) {
    this.isMenuOpen = v;
    this.$root.$emit('openHeaderMenu', v);
  }
}
