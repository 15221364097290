
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { ISideBarSpoolerStatusResponse } from 'rt/UIApiControllers/UI/SideBar/ISideBarSpoolerStatusResponse';
import { Prop } from 'vue-property-decorator';
import TSpoolerSpoolerButton from './SpoolerSpoolerButton.vue';
import TSpoolerProfileInfo from './SpoolerProfileInfo.vue';

// tslint:disable-next-line:variable-name
const MailingGetter = namespace('mailing').Getter;
// tslint:disable-next-line:variable-name
const MailingAction = namespace('mailing').Action;

@Component({
  name: 'SpoolerStatus',
  components: {
    TSpoolerSpoolerButton,
    TSpoolerProfileInfo,
  },
})
export default class SpoolerStatus extends Vue {
  @MailingAction watchSpoolerStatus: () => void;

  @MailingAction unwatchSpoolerStatus: () => void;

  @MailingGetter('spoolerStatus') status: ISideBarSpoolerStatusResponse;

  @MailingGetter spoolerCommandPending: boolean;

  @MailingAction pauseSpool: () => void;

  @MailingAction resumeSpool: () => void;

  @MailingAction stopSpool: () => void;

  async mounted() {
    if (this.$modules.isModuleLoaded('mailing')) {
      this.watchSpoolerStatus();
    }
  }

  async beforeDestroy() {
    this.unwatchSpoolerStatus();
  }

  get total(): number {
    if (!this.status) {
      return 0;
    }
    return this.spooled + this.queued;
  }

  get spooled(): number {
    if (!this.status) {
      return 0;
    }
    return this.status.spooledMails + this.status.sentMails;
  }

  get queued(): number {
    if (!this.status) {
      return 0;
    }
    return this.status.pausedMails + this.status.preLoadedMails + this.status.waitingMails;
  }

  get percentage() {
    if (!this.total) {
      return 0;
    }
    return parseInt(((this.spooled / this.total) * 100).toString(), 10);
  }

  get progressStatus(): string {
    if (!this.status) {
      return '';
    }
    if (this.status.hasError) {
      return 'exception';
    }
    if (this.status.hasWarning) {
      return 'warning';
    }
    return 'success';
  }

  handleCommand(cmd: string) {}
}
