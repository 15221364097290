//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IGoogleMapsResolveClass } from '../../../Base/GoogleMaps/IGoogleMapsResolveClass';
import { IMapViewService } from '../../../Interfaces/Maps/IMapViewService';
import { IRepeaterUpdateSelectedGroupInfoRequestStructure } from '../../RepeaterControllers/RepeaterStructure/IRepeaterUpdateSelectedGroupInfoRequestStructure';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';

//element.Name=MapsController result.Name=MapsController element.FullName=Digita.Tustena.UIApiControllers.UI.Maps.MapsController);
/** WebApiController: MapsController */
export class MapsController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in MapsController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public SearchAnagraphicsInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchAnagraphicsInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchCompaniesInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchCompaniesInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchContactsInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchContactsInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchLeadsInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchLeadsInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchJobOrderInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchJobOrderInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchJobOrderTaskInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchJobOrderTaskInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SearchAppointmentsInsideCircle(lat: number, lng: number, distance: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.get('Maps/SearchAppointmentsInsideCircle?&lat='+lat+'&lng='+lng+'&distance='+distance, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetMapViewService(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMapViewService>
	{
		return (this.axios.get('Maps/GetMapViewService', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public IsGeoServiceAvailable(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.get('Maps/IsGeoServiceAvailable', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSelectedGeographicElement(rrq: IRepeaterUpdateSelectedGroupInfoRequestStructure, boType: BusinessObjectType, top?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGoogleMapsResolveClass[]>
	{
		return (this.axios.post('Maps/GetSelectedGeographicElement?&boType='+boType+((top === null || top === undefined) ? '' : ('&top='+top)), rrq, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GeoLocalize(boType: BusinessObjectType, boId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Maps/GeoLocalize?&boType='+boType+'&boId='+boId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Maps/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Maps/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
