//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MailingListElementListDataAction {
	AllElements = 0,
	LoadedFromDynamic = 1,
	LoadedFromStatic = 2,
	WithMailingListFlag = 3,
	WithoutMailingListFlag = 4,
	WithEMailAddress = 5,
	WithoutEMailAddress = 6
}
