
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TASideButton',
})
export default class TASideButton extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  icon: string;
}
