
import Vue from 'vue';
import Component from 'vue-class-component';
import jwtManager from 'utils/jwtManager';

@Component({
  name: 'AnonymousLanding',
})
export default class AnonymousLanding extends Vue {
  mounted() {
    jwtManager.disable();
  }
}
