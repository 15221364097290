
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import simpleMixin from './mixin';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'MailingListInput',
  mixins: [simpleMixin],
})
export default class MailingListInput extends Vue {
  @Getter('user') user: IUser;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Boolean,
    required: false,
  })
  value: boolean;

  get isAbuse() {
    return this.data?.mailingListRights?.isAbuse;
  }
}
