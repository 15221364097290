
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'Alert',
  props: {
    schema: {
      type: Object,
      required: true,
    },
  },
})
export default class Alert extends Vue {
  schema: any;
}
