
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseTree from './BaseTree';
import { AccountTreeViewController } from 'rt/UIApiControllers/TreeView/AccountTreeViewController';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'AccountTree',
})
export default class AccountTree extends BaseTree {
  accountTreeViewController = new AccountTreeViewController(Vue.axios);

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlyWithCalendarRights: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlySubAccounts: boolean;

  getTreeViewController(): any {
    return this.accountTreeViewController;
  }

  isLeaf(item): boolean {
    return item.key.startsWith('User-');
  }

  extendRequestData(requestData: any): any {
    requestData.impersonate = this.onlyWithCalendarRights;
    requestData.onlySubAccounts = this.onlySubAccounts;
    requestData.allowTeam = false;
    requestData.allowOffice = false;
    requestData.allowWorkgroup = false;
    requestData.allowOrgChart = false;
    requestData.allowSkill = false;
    requestData.allowSkillCategory = false;
    requestData.allowPortal = false;
    requestData.allowApplication = false;
    requestData.allowDisabled = false;
    return requestData;
  }
}
