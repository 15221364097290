
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import SortingColumn from './SortingColumn.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FieldHeader',
  components: {
    SortingColumn,
  },
})
export default class FieldHeader extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  tableColumn: any;

  @Prop({
    type: Object,
    required: true,
  })
  tableStore: any;

  @Prop({
    type: Boolean,
    required: true,
  })
  allowFilter: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  valid: boolean;

  @Prop({
    type: Array,
    required: false,
  })
  filter: any[];

  @Prop({
    required: false,
  })
  value: any;

  @Prop({
    type: Object,
    required: true,
  })
  operator: any;

  @Prop({
    type: Object,
    required: false,
    default: null,
  })
  sorting: any;

  popoverFilterOpen = false;

  changeOperatorFilter(newOperator) {
    this.$emit('operator-change', newOperator);
  }

  get popoverIconClass() {
    if (this.valid) {
      return 'el-icon-icon-filter3 success';
    }
    return 'el-icon-icon-filter3';
  }
}
