//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { ICitySuggestRequest } from './ICitySuggestRequest';
import { ICitySuggestResponse } from './ICitySuggestResponse';

//element.Name=CitySuggestController result.Name=CitySuggestController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.CitySuggestController);
/** WebApiController: CitySuggestController */
export class CitySuggestController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in CitySuggestController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public SearchCity(req: ICitySuggestRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICitySuggestResponse[]>
	{
		return (this.axios.post('CitySuggest/SearchCity', req, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('CitySuggest/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('CitySuggest/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
