
import Vue from 'vue';
import ElementUI from 'element-ui';
import Component from 'vue-class-component';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import TCompanyFastLayoutStudio from '../../company/views/CompanyFastLayoutStudio.vue';
import TContactFastLayoutStudio from '../../contact/views/ContactFastLayoutStudio.vue';
import TLeadFastLayoutStudio from '../../lead/views/LeadFastLayoutStudio.vue';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import eventHubManger from 'modules/mixin/views/EventHub';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { ILeadDTO } from 'dto/ILeadDTO';

// tslint:disable-next-line:variable-name
const CompanyGetter = namespace('company').Getter;
// tslint:disable-next-line:variable-name
const CompanyMutation = namespace('company').Mutation;
// tslint:disable-next-line:variable-name
const ContactGetter = namespace('contact').Getter;
// tslint:disable-next-line:variable-name
const LeadGetter = namespace('lead').Getter;
// tslint:disable-next-line:variable-name
const LeadMutation = namespace('lead').Mutation;
@Component({
  name: 'AnagraphicFastLayoutStudio',
  components: {
    TCompanyFastLayoutStudio,
    TContactFastLayoutStudio,
    TLeadFastLayoutStudio,
  },
})
export default class AnagraphicFastLayoutStudio extends Vue {
  @Getter user: IUser;

  @CompanyGetter('changes') companyChanges: () => any;

  @ContactGetter('changes') contactChanges: () => any;

  @LeadGetter('changes') leadChanges: () => any;

  @LeadGetter('model') leadModel: ILeadDTO;

  @LeadMutation('setModel') setLeadModel: (c: ILeadDTO) => void;

  @CompanyGetter('model') companyModel: ICompanyDTO;

  @CompanyMutation('setModel') setCompanyModel: (c: ICompanyDTO) => void;

  @Prop({
    type: String,
  })
  title: string;

  @Prop({
    default: false,
    type: Boolean,
  })
  visible: boolean;

  @Prop({
    type: Object,
  })
  defaults: any;

  @Prop({
    default: true,
    type: Boolean,
  })
  company: boolean;

  @Prop({
    default: true,
    type: Boolean,
  })
  contact: boolean;

  @Prop({
    default: true,
    type: Boolean,
  })
  lead: boolean;

  @Watch('visible') handleVisibleChange() {
    this.mode = 'form';
  }

  mode: 'form' | 'map' = 'form';

  get currentLocation() {
    if (this.activeName === 'Lead') {
      return this.leadModel?.geoLocalization?.locationObject;
    }
    if (this.activeName === 'Company') {
      return this.companyModel?.geoLocalization?.locationObject;
    }
    return null;
  }

  activeName = 'Lead';

  get activeTitle(): string {
    return this.title ?? (this.$t(`${this.activeName.toLowerCase()}.new`) as string);
  }

  handleCompanyCreated(id: number) {
    this.$emit('update:visible', false);
    this.$emit('created', { id, type: this.$enums.BusinessObjectType.Company });
  }

  goToCompleteCompanySheet() {
    this.$emit('update:visible', false);
    this.$router.push({
      name: this.$routeNames.company,
      params: {
        id: this.$hashids.encode(0),
        defaults: JSON.stringify(this.companyChanges()),
      },
    });
  }

  saveCompany() {
    return eventHubManger.getHub('company.fast').$emit('save');
  }

  handleContactCreated(id: number) {
    this.$emit('update:visible', false);
    this.$emit('created', { id, type: this.$enums.BusinessObjectType.Contact });
  }

  goToCompleteContactSheet() {
    this.$emit('update:visible', false);
    this.$router.push({
      name: this.$routeNames.contact,
      params: {
        id: this.$hashids.encode(0),
        defaults: JSON.stringify(this.contactChanges()),
      },
    });
  }

  saveContact() {
    return eventHubManger.getHub('contact.fast').$emit('save');
  }

  handleLeadCreated(id: number) {
    this.$emit('update:visible', false);
    this.$emit('created', { id, type: this.$enums.BusinessObjectType.Lead });
  }

  goToCompleteLeadSheet() {
    this.$emit('update:visible', false);
    this.$router.push({
      name: this.$routeNames.lead,
      params: {
        id: this.$hashids.encode(0),
        defaults: JSON.stringify(this.leadChanges()),
      },
    });
  }

  saveLead() {
    return eventHubManger.getHub('lead.fast').$emit('save');
  }

  handleLeadMapImport(dto: Partial<ILeadDTO>) {
    const m = {
      ...this.leadModel,
      ...dto,
    };
    this.setLeadModel(m);
    this.mode = 'form';
    const form = this.$refs['leadForm'] as any;
    if (form) {
      form.validate();
    }
  }

  handleCompanyMapImport(dto: Partial<ICompanyDTO>) {
    const m = {
      ...this.companyModel,
      ...dto,
    };
    this.setCompanyModel(m);
    this.mode = 'form';
    const form = this.$refs['companyForm'] as any;
    if (form) {
      form.validate();
    }
  }
}
