
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import { SimilarityController } from 'rt/UIApiControllers/UI/Smart/SimilarityController';
import { ISimilarityRequest } from 'rt/UIApiControllers/UI/Smart/ISimilarityRequest';
import { ISimilarityResponse } from 'rt/UIApiControllers/UI/Smart/ISimilarityResponse';
import TSimilarityItem from './SimilarityItem.vue';
import _ from 'lodash';

@Component({
  name: 'SimilarityPopOver',
  components: {
    TSimilarityItem,
  },
})
export default class SimilarityPopOver extends Vue {
  similarityController = new SimilarityController(Vue.axios);

  @Prop({
    required: false,
    type: String,
  })
  value: string;

  @Prop({
    required: false,
    type: String,
  })
  secondaryValue: string;

  @Prop({
    required: true,
    type: Number,
  })
  id: number;

  @Prop({
    required: true,
    type: Number,
  })
  type: number;

  @Prop({
    required: true,
    type: Number,
  })
  mode: number;

  @Prop({
    required: false,
    default: 700,
    type: Number,
  })
  delay: number;

  @Prop({
    required: false,
    default: 6,
    type: Number,
  })
  minLength: number;

  cancellationTokenSource: CancelTokenSource;

  mounted() {
    this.bouncedSimilarityCheck = _.debounce(() => this.similarityCheck(), this.delay);
  }

  active = true;

  activated() {
    this.active = true;
  }

  deactivated() {
    this.active = false;
  }

  @Watch('value')
  handleValueChange() {
    this.similarityVisible = false;
    this.bouncedSimilarityCheck();
  }

  @Watch('secondaryValue')
  handleSecondaryValueChange() {
    this.similarityVisible = false;
    this.bouncedSimilarityCheck();
  }

  @Watch('id')
  handleIdChange() {
    this.bouncedSimilarityCheck.cancel();
  }

  @Watch('type')
  handleTypeChange() {
    this.bouncedSimilarityCheck.cancel();
  }

  similarityVisible = false;

  similarities: ISimilarityResponse[] = null;

  bouncedSimilarityCheck: any;

  async similarityCheck() {
    if (!this.active) {
      return;
    }
    this.similarityVisible = false;
    if (this.cancellationTokenSource) {
      this.cancellationTokenSource.cancel();
    }
    this.cancellationTokenSource = axios.CancelToken.source();
    if (this.value && this.value.length >= this.minLength) {
      const request: ISimilarityRequest = {
        crossId: this.id,
        crossType: this.type,
        mode: this.mode,
        value: this.value,
        secondaryValue: null,
      };
      const results = await this.similarityController.Search(request, this.cancellationTokenSource.token);
      if (results && results.length) {
        this.similarityVisible = true;
        this.similarities = results.slice(0, 6);
      } else {
        this.similarities = null;
      }
    }
  }
}
