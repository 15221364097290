
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';

@Component({
  name: 'TButton',
})
export default class BackButton extends Vue {
  @Inject({
    default: false,
  })
  asDropItem: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: String,
  })
  type: string;

  @Prop({
    type: String,
    required: false,
  })
  icon: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  disabled: boolean;

  click() {
    this.$emit('click');
  }
}
