
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IListResult } from 'rt/UIApiControllers/Controls/IListResult';
import { ListsType } from 'rt/Core/ListsType';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'EnumListSelect',
})
export default class EnumListSelect extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: {
    listType: ListsType;
    text: string;
    items: IInt64ListResult[];
  };

  @Prop({
    type: [String, Number],
    required: true,
  })
  value: any;

  change(value) {
    this.$emit('input', value);
  }
}
