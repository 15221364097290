
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IButton } from 'rt/Interfaces/UI/IButton';

@Component({
  name: 'WebApiActionButton',
})
export default class WebApiActionButton extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: IButton;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  working = false;

  async executeWebApiAction() {
    if (this.working) {
      return;
    }
    try {
      this.working = true;
      const response = await this.$http.post((this.schema as any).endPoint, this.data);
    } finally {
      this.working = false;
    }
  }
}
