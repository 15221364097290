
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Mutation } from 'vuex-class';
import scriptjs from 'scriptjs';

@Component({
  name: 'TCookieDialog',
})
export default class TCookiesDialog extends Vue {
  @Getter cookiePreferences: { [key: string]: boolean };

  @Mutation setCookiePreferences: (payload: { [key: string]: boolean }) => void;

  template: string = null;

  get isSupportedLanguage(): boolean {
    return this.language != null;
  }

  get language(): string {
    return /^it|es|en\b/.exec(navigator.language)?.[0] ?? null;
  }

  mounted() {
    if (this.language) {
      this.loadOnCookieDialog(this.language);
    }
  }

  show() {
    const el = (this.$refs['cookieselection'] as any);
    if (el) {
      el.setAttribute('visible', 'yes');
    }
  }

  loadOnCookieDialog(language: string) {
    fetch(`/template/cookiedialog/${language}.html`)
      .then((r) => r.text())
      .then((template) => {
        this.template = template;
        scriptjs('/cookiesdialog/cookiesdialog.min.js', () => {
          const el = (this.$refs['cookieselection'] as any);
          if (el) {
            el.addEventListener('close', (evt: any) => {
              this.setCookiePreferences(evt.detail.options);
            });
            el.addEventListener('hide', (evt) => {
            });
            el.setAttribute('visible', this.cookiePreferences == null ? 'yes' : 'no');
          }
        });
      });
  }
}
