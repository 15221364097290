
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { ITustenaCompanyDTO } from 'dto/ITustenaCompanyDTO';
import TExpirationDateAlert from './ExpirationDateAlert.vue';

// tslint:disable-next-line:variable-name
const TustenaCompanyAction = namespace('tustenaCompany').Action;
// tslint:disable-next-line:variable-name
const TustenaCompanyGetter = namespace('tustenaCompany').Getter;
// tslint:disable-next-line:variable-name
const AccountAction = namespace('account').Action;
// tslint:disable-next-line:variable-name
const AccountGetter = namespace('account').Getter;
@Component({
  name: 'Licence',
  components: {
    TExpirationDateAlert,
  },
})
export default class Licence extends Vue {
  @Getter('user') user: IUser;

  @Getter isInBlackFriday: boolean;

  @Getter isInSDSBlackFriday: boolean;

  get expirationDays() {
    const expirationDate = this.$moment(this.user.expirationDate);
    return expirationDate.diff(this.$moment(), 'days');
  }
}
