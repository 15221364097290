
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TCircleButton',
})
export default class TCircleButton extends Vue {
  @Prop({
    type: String,
    default: '',
    required: false,
  })
  icon: string;

  @Prop({
    type: String,
    default: 'small',
    required: false,
  })
  size: string;

  @Prop({
    type: String,
    default: 'default',
    required: false,
  })
  type: string;

  @Prop({
    type: String,
    default: 'default',
    required: false,
  })
  image: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  pulse: boolean;
}
