
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';
import { IWidget } from '../../typings/Contracts/UI/IWidget';

@Component({
  name: 'AggregateInput',
  mixins: [simpleMixin],
})
export default class AggregateInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: {
    items: IWidget[];
  };

  @Prop({
    type: [Object, Array],
    required: true,
  })
  data: any;
}
