
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';
import { CervedController } from 'rt/UIApiControllers/Utils/CervedController';
import { IAnagraphic } from 'rt/Base/Cerved/EntitySearch/IAnagraphic';

@Component({
  name: 'CervedItem',
})
export default class CervedItem extends Vue {
  cervedController = new CervedController(this.$http);

  @Prop({
    required: true,
    type: Object,
  })
  value: IAnagraphic;

  async handleCervedImport() {
    const h = this.$createElement;
    let overwrite = true;
    const id = this.value.dati_anagrafici.id_soggetto;
    const action = await this.$msgbox({
      title: this.$t('cerved.import.title').toString(),
      message: h('p', null, [
        h(
          'el-checkbox',
          {
            key: id,
            props: {
              checked: overwrite,
            },
            on: {
              input: (val) => {
                overwrite = val;
              },
            },
          },
          this.$t('cerved.import.overwrite').toString(),
        ),
      ]),
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showCancelButton: true,
      confirmButtonText: this.$t('commands.ok').toString(),
      cancelButtonText: this.$t('commands.cancel').toString(),
    });
    if (action === 'confirm') {
      this.$emit('import', { overwrite, id });
    }
  }
}
