
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';

@Component({
  name: 'ContactCategoryInput',
})
export default class ContactCategoryInput extends Vue {
  @Getter('lists/list') list: (type: ListsType) => IInt64ListResult[];

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: true,
  })
  value: number[];

  change(categories: number[]) {
    this.$emit('input', categories);
  }
}
