
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import axios, { CancelToken, CancelTokenSource } from 'axios';

@Component({
  name: 't-tab-badge-counter',
})
export default class TabBadgeCounter extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  endpoint: string;

  @Prop({
    type: Number,
    required: true,
  })
  value: string;

  @Prop({
    type: String,
    required: false,
  })
  schemaSuffix: string;

  @Watch('endpoint')
  handleEndPointChange() {
    this.discoverBadgeValue();
  }

  @Watch('value')
  handleValueChange() {
    this.discoverBadgeValue();
  }

  badgeValue: any = null;

  cancellationTokenSource: CancelTokenSource;

  mounted() {
    this.cancellationTokenSource = axios.CancelToken.source();
    this.discoverBadgeValue();
  }

  private async discoverBadgeValue() {
    this.badgeValue = null;
    await this.updateCounter();
  }

  async updateCounter() {
    this.badgeValue = await Vue.axios.get(`${this.endpoint}/${this.value}${this.endpointQsParameters}`, { cancelToken: this.cancellationTokenSource.token, timeout: 20000 }).then((r) => r.data);
  }

  get endpointQsParameters() {
    return this.schemaSuffix != null ? `?suffix=${this.schemaSuffix}` : '';
  }

  beforeDestroy() {
    this.cancellationTokenSource.cancel();
  }
}
