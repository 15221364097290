
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'ZoneLookup',
  components: {
    TLookupSkin,
  },
})
export default class ZoneLookup extends BaseLookup {
  suggestionControllerName() {
    return 'Zone';
  }

  keyPrefix() {
    return 'Zone';
  }

  recalcPopper() {
    /*
    window.setTimeout(() => {
      const popper = <any>this.$refs['popover-zone-tree'];
      popper.updatePopper();
    }, 300); */
  }

  async handleZoneTreePopoverHide() {
    const zoneTree = this.$refs['zone-tree'];
    await (zoneTree as any).reset();
  }

  get treeSelectedData() {
    return this.selectedData.map((k) => ({
      ...k,
      key: k.key.toString().substring(this.keyPrefix().length + 1),
    }));
  }

  handleTreeInputChange(items) {
    if (!this.multipleSelection) {
      this.popoverOpen = false;
    }
    this.changeItems(
      items.map((m) => ({
        ...m,
        key: `${this.keyPrefix}-${m.key}`,
      })),
    );
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
