
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'TImage',
})
export default class TImage extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  src: string;

  @Prop({
    type: [String, Object],
    required: false,
  })
  imageStyle: string;

  @Watch('src') handleSrcChange() {
    this.loadImage();
  }

  loading = true;

  error = false;

  mounted() {
    this.loadImage();
  }

  loadImage() {
    this.loading = true;
    this.error = false;
    const img = new Image();
    img.onload = (e) => this.handleLoad(e, img);
    img.onerror = this.handleError.bind(this);
    // bind html attrs
    // so it can behave consistently
    Object.keys(this.$attrs).forEach((key) => {
      const value = this.$attrs[key];
      img.setAttribute(key, value);
    });
    if (this.src) {
      img.src = this.src;
    } else {
      this.loading = false;
      this.error = true;
    }
  }

  handleLoad(e, img) {
    this.loading = false;
    this.error = false;
  }

  handleError(e) {
    this.loading = false;
    this.error = true;
    this.$emit('error', e);
  }
}
