
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import THyperlinkIntegration from './HyperlinkIntegration.vue';
import TContentIntegration from './ContentIntegration.vue';
import { IAppointmentDTO } from 'rt/DTO/IAppointmentDTO';

@Component({
  name: 'ContentIntegration',
  components: {
    THyperlinkIntegration,
    TContentIntegration,
  },
})
export default class ContentIntegration extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: IAppointmentDTO;

  @Prop({
    type: Boolean,
    default: true,
  })
  button: boolean;
}
