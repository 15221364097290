//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ResourceTypology {
	User = 0,
	Team = 1,
	Anagraphic = 2,
	Asset = 3
}
