//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { ISignatureDTO } from '../../../DTO/ISignatureDTO';

//element.Name=SignatureController result.Name=SignatureController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.SignatureController);
/** WebApiController: SignatureController */
export class SignatureController extends DefaultDtoControllerProxy<ISignatureDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Signatures', BusinessObjectType.Signature);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISignatureDTO>
	{
		return (this.axios.get('Signatures/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
