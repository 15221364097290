//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IAdvancedSearchModel } from './IAdvancedSearchModel';
import { IAdvancedSearchField } from '../../Report/AdvancedSearch/IAdvancedSearchField';
import { IDateTimeFilterOption } from './IDateTimeFilterOption';
import { IAccountFilterOption } from './IAccountFilterOption';
import { IAdvancedSearchConditionsCache } from '../../Report/AdvancedSearch/Cache/IAdvancedSearchConditionsCache';
import { IInt64ListResult } from '../Controls/IInt64ListResult';
import { IReportSearchConditionDTO } from '../../DTO/IReportSearchConditionDTO';
import { IListResult } from '../Controls/IListResult';

//element.Name=AdvancedSearchController result.Name=AdvancedSearchController element.FullName=Digita.Tustena.UIApiControllers.AdvancedSearch.AdvancedSearchController);
/** WebApiController: AdvancedSearchController */
export class AdvancedSearchController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in AdvancedSearchController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public GetAdvancedSearchModel(reportSchema: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAdvancedSearchModel>
	{
		return (this.axios.get('AdvancedSearch/GetAdvancedSearchModel?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Fields(reportSchema: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAdvancedSearchField[]>
	{
		return (this.axios.get('AdvancedSearch/Fields?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DateTimeFilterOptions(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IDateTimeFilterOption[]>
	{
		return (this.axios.get('AdvancedSearch/DateTimeFilterOptions', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AccountFilterOptions(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAccountFilterOption[]>
	{
		return (this.axios.get('AdvancedSearch/AccountFilterOptions', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public History(reportSchema: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAdvancedSearchConditionsCache[]>
	{
		return (this.axios.get('AdvancedSearch/History?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public TypeConditionMapping(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<{ [key:number]: IInt64ListResult[] }>
	{
		return (this.axios.get('AdvancedSearch/TypeConditionMapping', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetHistoryConditions(reportSchema: string, id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReportSearchConditionDTO[]>
	{
		return (this.axios.post('AdvancedSearch/GetHistoryConditions?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema)))+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSelectOptions(reportSchema: string, virtualName: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListResult[]>
	{
		return (this.axios.get('AdvancedSearch/GetSelectOptions?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema)))+((virtualName === null || virtualName === undefined) ? '' : ('&virtualName='+encodeURIComponent(virtualName))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EnqueueConditions(reportSchema: string, conditions: IReportSearchConditionDTO[], id: number = 0, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('AdvancedSearch/EnqueueConditions?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema)))+'&id='+id, conditions, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Describe(reportSchema: string, conditions: IReportSearchConditionDTO[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('AdvancedSearch/Describe?'+((reportSchema === null || reportSchema === undefined) ? '' : ('&reportSchema='+encodeURIComponent(reportSchema))), conditions, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('AdvancedSearch/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('AdvancedSearch/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
