
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter, Action, namespace } from 'vuex-class';
import {
 IRepeaterColumn, IRowDataInfoValue, IRepeaterSavedSearch, IRepeaterRequestStructure, IRepeaterResponseStructure,
} from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { BaseRepeaterController } from 'rt/UIApiControllers/RepeaterControllers/BaseRepeaterController';
import { GridsState, IGridState, GridState } from 'modules/grids/store/state';

// tslint:disable-next-line:variable-name
const GridsGetter = namespace('grids').Getter;
// tslint:disable-next-line:variable-name
const GridsAction = namespace('grids').Action;

@Component({
  name: 'SheetPaging',
})
export default class CrmGrid extends Vue {
  @GridsGetter('getState') getState: (repeater: string) => IGridState;

  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  @Prop({
    required: true,
  })
  repeater: string;

  controller: BaseRepeaterController;

  async mounted() {
    this.controller = new BaseRepeaterController(this.$http, this.repeater);
  }

  async prev() {
    const loading = this.$loading({});
    try {
      const prevId = +(await this.controller.Previous(this.value.toString(), this.getLastRequestStructure()));
      if (prevId > 0) {
        // this.$emit('input', prevId);
        this.$router.push({
          name: this.$route.name,
          params: {
            id: this.$hashids.encode(prevId),
          },
        });
      }
    } finally {
      loading.close();
    }
  }

  async next() {
    const loading = this.$loading({});
    try {
      const nextId = +(await this.controller.Next(this.value.toString(), this.getLastRequestStructure()));
      if (nextId > 0) {
        // this.$emit('input', nextId);
        this.$router.push({
          name: this.$route.name,
          params: {
            id: this.$hashids.encode(nextId),
          },
        });
      }
    } finally {
      loading.close();
    }
  }

  get currentState(): IGridState {
    return this.getState(this.repeater);
  }

  getLastRequestStructure(): IRepeaterRequestStructure {
    const postObject = {} as IRepeaterRequestStructure;

    if (this.currentState.trackedCustomParameterData) {
      postObject.customParameterData = JSON.stringify(this.currentState.trackedCustomParameterData);
    }
    postObject.sortColumns = this.currentState.orders;
    postObject.advancedSearchId = +this.currentState.advancedSearchId;
    postObject.savedSearchId = +this.currentState.savedSearchId;

    const filter = this.currentState.filters;
    postObject.filters = Object.keys(filter).map((key) => ({
      columnName: key,
      value: filter[key][0].value,
      operator: filter[key][0].operator,
    }));
    return postObject;
  }
}
