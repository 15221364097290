
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'StorageFileCommands',
})
export default class StorageFileCommands extends Vue {
  @Getter user: IUser;

  @Prop({ type: Boolean, required: false, default: true })
  download: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  rename: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  move: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  delete: boolean;

  get allowDelete() {
    return this.delete;
  }

  @Prop({ type: Boolean, required: false, default: true })
  details: boolean;
}
