//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IGroupDTO } from '../../../DTO/IGroupDTO';

//element.Name=GroupController result.Name=GroupController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.GroupController);
/** WebApiController: GroupController */
export class GroupController extends DefaultDtoControllerProxy<IGroupDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Groups', BusinessObjectType.Group);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IGroupDTO>
	{
		return (this.axios.get('Groups/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
