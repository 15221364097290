//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IStorageDTO } from '../../../DTO/IStorageDTO';
import { IWorkPlanDTO } from '../../../DTO/IWorkPlanDTO';

//element.Name=WorkPlanController result.Name=WorkPlanController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.WorkPlanController);
/** WebApiController: WorkPlanController */
export class WorkPlanController extends DefaultDtoControllerProxy<IWorkPlanDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'WorkPlans', BusinessObjectType.WorkPlan);
	}
	public Scheduled(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('WorkPlans/Scheduled?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ScheduledIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('WorkPlans/ScheduledIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ScheduledCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('WorkPlans/ScheduledCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Done(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('WorkPlans/Done?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Done2(id: number, activityId: number, insertAfter?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('WorkPlans/Done?&id='+id+'&activityId='+activityId+((insertAfter === null || insertAfter === undefined) ? '' : ('&insertAfter='+insertAfter)), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DoneIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('WorkPlans/DoneIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DoneCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('WorkPlans/DoneCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Schedule(id: number, activityId: number, insertAfter?: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('WorkPlans/Schedule?&id='+id+'&activityId='+activityId+((insertAfter === null || insertAfter === undefined) ? '' : ('&insertAfter='+insertAfter)), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Documents(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO[]>
	{
		return (this.axios.get('WorkPlans/Documents?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('WorkPlans/DocumentsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('WorkPlans/DocumentsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Activities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('WorkPlans/Activities?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('WorkPlans/ActivitiesIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('WorkPlans/ActivitiesCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IWorkPlanDTO>
	{
		return (this.axios.get('WorkPlans/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
