
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { Prop, Watch } from 'vue-property-decorator';
import StorageBrowserMixin from './StorageBrowserMixin';
import TStorageBrowserTable from './StorageBrowserTable.vue';
import TStorageDetail from './StorageDetail.vue';
import axios, { CancelTokenSource } from 'axios';
import { IStorageItem } from './storage';
import _ from 'lodash';

@Component({
  name: 'StorageBrowserLookup',
  components: {
    TStorageBrowserTable,
    TStorageDetail,
  },
})
export default class StorageBrowserLookup extends StorageBrowserMixin {
  tempSearchKey: string = null;

  filter: string = null;

  @Prop({ type: Boolean, default: false })
  visible: boolean;

  tab: 'files' | 'recents' | 'upload' | 'preview' = 'files';

  cancelTokenSource: CancelTokenSource;

  categoryId = 0;

  filePreview: IStorageDTO = null;

  @Watch('filter')
  handleFilterChange() {
    this.browseDirectory();
  }

  handleTabChange(v: 'files' | 'recents' | 'upload' | 'preview') {
    if (v === 'preview' && !this.filePreview) {
      return;
    }
    this.tab = v;
    this.browseDirectory();
  }

  async goToFilePreview(item: IStorageItem) {
    if (item.type === 'file') {
      this.filePreview = null;
      this.tab = 'preview';
      this.filePreview = await this.controller.Get(item.id);
    }
  }

  get onlyFiles() {
    if (this.filter) {
      return true;
    }
    if (this.tab === 'recents') {
      return true;
    }
    if (this.tab === 'upload') {
      return true;
    }
    return false;
  }

  get currentData(): IStorageItem[] {
    if (this.onlyFiles) {
      return [...this.files.map(this.fromStorageDtoToGridDate)];
    }
    return [
      ...this.currentCategories.map((d) => ({
        type: 'category',
        id: d.id,
        description: d.description,
        lastModifiedById: d.lastModifiedById,
        lastModifiedDate: d.lastModifiedDate,
        size: null,
        icon: 'el-icon-icon-folder',
      } as IStorageItem)),
      ...this.files.map(this.fromStorageDtoToGridDate),
    ];
  }

  protected async loadFiles(page: number, pageSize: number): Promise<IStorageDTO[]> {
    if (!this.user.isAuthorizedToRead(BusinessObjectType.Storage)) {
      return [];
    }
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
    this.cancelTokenSource = axios.CancelToken.source();
    if (this.tab === 'upload') {
      return [];
    }
    if (this.tab === 'recents') {
      return await this.controller.SearchByODataCriteria(`lastModifiedById eq ${this.user.userId} or createdById eq ${this.user.userId}`, 'lastModifiedDate desc', (this.page - 1) * this.pageSize, this.pageSize, null, this.cancelTokenSource.token);
    }
    let filter = null;
    if (this.filter) {
      filter = `contains(fileName, '${encodeURIComponent(this.filter)}')`;
      return await this.controller.SearchByODataCriteria(filter, 'fileName', (this.page - 1) * this.pageSize, this.pageSize, null, this.cancelTokenSource.token);
    }
    const files = (await this.categoriesController.Documents(this.categoryId, filter, 'fileName', this.pageSize, (this.page - 1) * this.pageSize, null, this.cancelTokenSource.token)) as IStorageDTO[];
    return files;
  }

  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
  }

  handleClick(row) {
    switch (row.type) {
      case 'category':
        this.categoryId = row.id;
        break;
      case 'file':
        this.$emit('select', row.id);
        break;
    }
  }
}
