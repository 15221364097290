
import Vue from 'vue';
import Component from 'vue-class-component';
import LookupMixin from './lookupMixin';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ListsType } from 'rt/Core/ListsType';
import { Prop } from 'vue-property-decorator';
// tslint:disable-next-line:variable-name
const ListsAction = namespace('lists').Action;
// tslint:disable-next-line:variable-name
const ListsGetter = namespace('lists').Getter;

@Component({
  name: 'AccountInput',
})
export default class AccountInput extends LookupMixin {
  resolveController = new ResolveController(Vue.axios);

  @ListsAction
  load: (type: ListsType) => Promise<any>;

  @ListsGetter
  list: (type: ListsType) => Array<IInt64ListResult>;

  accounts: IInt64ListResult[] = null;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlyWithCalendarRights: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlySubAccounts: boolean;

  async resolve(id: number): Promise<string> {
    if (this.accounts == null) {
      await this.load(this.$enums.ListsType.ListOwner);
      this.accounts = this.list(this.$enums.ListsType.ListOwner);
    }
    const ac = this.accounts.find((a) => a.value === id);
    if (ac != null) {
      return ac.text;
    }
    return (await this.resolveController.Account(id)).name;
  }

  protected get identifier(): string {
    return 'Account';
  }
}
