
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import store from '@/store';
import HashIds from 'hashids';
import _ from 'lodash';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';
import { ICloudUser } from '@/utils/trackings';
import { ICloudUserLimit } from '@/plugins/typings/UI/Injector/ICloudUserLimit';

@Component({
  name: 'UserProfileSummary',
})
export default class UserProfileSummary extends Vue {
  logoutPopoverVisible = false;

  @Getter
  user: ICloudUser;

  @Getter
  config: IConfig;

  @Getter baseUrl: string;

  @Getter build: string;

  @Getter brand: IBrand;

  @Getter languageDebug: boolean;

  @Action throttleLanguageDebug: () => void;

  @Action('logout') logout: () => Promise<any>;

  @Mutation togglePerformance: () => Promise<any>;

  @Getter ticks: number;

  @Getter canActivateDebug: boolean;

  private debugCounter = 0;

  private resetDebugCounter = () => { };

  private userSize = 75;

  mounted() {
    this.resetDebugCounter = _.debounce(() => {
      this.debugCounter = 0;
    }, 400);
  }

  get imageUrl() {
    const userHash = new HashIds('').encode(this.user.userId, this.user.customerId);
    const imgSize = this.$viewport.isRetina ? this.userSize * 2 : this.userSize;
    return `${this.baseUrl}/Avatar/User/${userHash}/${imgSize}?${this.ticks}`;
  }

  openSupportChat() {
    if (this.$chat.exists) {
      if (this.$chat.visible) {
        this.$chat.close();
        this.$chat.hide();
      } else {
        this.$root.$emit('help');
      }
    }
    this.$emit('close');
  }

  handleDebugPerformanceClick() {
    this.debugCounter += 1;
    this.resetDebugCounter();
    if (this.debugCounter === 5) {
      this.togglePerformance();
    }
  }
}
