//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IProfile } from './IProfile';
import { IMailingListRightsDTO } from '../../../DTO/IMailingListRightsDTO';

//element.Name=UnsubscribeController result.Name=UnsubscribeController element.FullName=Digita.Tustena.UIApiControllers.UI.MailingList.UnsubscribeController);
/** WebApiController: UnsubscribeController */
export class UnsubscribeController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in UnsubscribeController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Get(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IProfile>
	{
		return (this.axios.get('Unsubscribe', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UpdatePreferences(dto: IMailingListRightsDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Unsubscribe/UpdatePreferences', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Unsubscribe/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Unsubscribe/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
