
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { MessagesController } from 'rt/UIApiControllers/UI/Messages/MessagesController';
import { IMessageInfo } from 'rt/UIApiControllers/UI/Messages/IMessageInfo';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

// tslint:disable-next-line:variable-name
const MessagesAction = namespace('messages').Action;
// tslint:disable-next-line:variable-name
const MessagesGetter = namespace('messages').Getter;

@Component({
  name: 'LastMessages',
})
export default class LastMessages extends Vue {
  @Getter user: IUser;

  @Getter('baseUrl') baseUrl: string;

  @Getter ticks: number;

  @MessagesGetter('messagesController') messagesController: MessagesController;

  @MessagesGetter('lastMessages') messages: IMessageInfo[];

  @MessagesAction('loadLastMessages') loadLastMessages: () => Promise<void>;

  loading = false;

  async mounted() {
    if (this.user.isAuthorizedToRead(this.$enums.BusinessObjectType.Message)) {
      this.loading = true;
      await this.loadLastMessages();
      this.loading = false;
    }
  }

  get hasMessages(): boolean {
    return this.messages && this.messages.length > 0;
  }

  time(message) {
    const createdDate = this.$moment(message.createdDate);
    if (createdDate.isSame(this.$moment(), 'day')) {
      return createdDate.fromNow();
    }
    if (createdDate.isAfter(this.$moment().add(-7, 'd'))) {
      return createdDate.format('dddd, LT');
    }
    return createdDate.format('dddd, LL LT');
  }
}
