
import Vue from 'vue';
import { Prop, Provide } from 'vue-property-decorator';
import Component from 'vue-class-component';
import vueUtils from 'utils/vueUtils';
import ElementUI from 'element-ui';

@Component({
  name: 'TDropdownActions',
})
export default class TDropdownActions extends Vue {
  @Provide() get asDropItem() {
    return this.$viewport.xs;
  }

  mounted() {
    console.log('mounted TDropdownActions', this.$slots.button);
  }

  updated() {
    console.log('update TDropdownActions', this.$slots.button);
  }

  boforeUpdate() {
    console.log('beforeUpdate TDropdownActions', this.$slots.button);
  }
}
