
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'TDeleteButton',
})
export default class TDeleteButton extends Vue {
  @Prop({ type: Boolean, default: true, required: false }) circle;

  @Prop({ type: Boolean, default: true, required: false }) button;

  @Prop({ type: Boolean, default: false, required: false }) loading;

  @Prop({ type: String, default: 'el-icon-icon-bin', required: false }) icon;

  @Prop({ type: String, default: 'danger', required: false }) type: string;

  confirmationVisible = false;

  deleteConfirmed() {
    this.confirmationVisible = false;
    this.$emit('delete');
  }
}
