//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IProgressiveCodeDTO } from '../../../DTO/IProgressiveCodeDTO';

//element.Name=ProgressiveCodeController result.Name=ProgressiveCodeController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.ProgressiveCodeController);
/** WebApiController: ProgressiveCodeController */
export class ProgressiveCodeController extends DefaultDtoControllerProxy<IProgressiveCodeDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'ProgressiveCode', BusinessObjectType.ProgressiveCode);
	}
	public Preview(dto: IProgressiveCodeDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('ProgressiveCode/Preview', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IProgressiveCodeDTO>
	{
		return (this.axios.get('ProgressiveCode/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
