
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IButton } from 'rt/Interfaces/UI/IButton';

@Component({
  name: 'RedirectButton',
})
export default class RedirectButton extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: IButton;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;
}
