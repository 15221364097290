
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { Getter } from 'vuex-class';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import { ITabWidget } from 'rt/Interfaces/UI/ITabWidget';
import TTabBadgeCounter from '../misc/TabBadgeCounter.vue';
import TTabBadgePropertiesCounter from '../misc/TabBadgePropertiesCounter.vue';

@Component({
  name: 'Tabs',
  components: {
    TTabBadgeCounter,
    TTabBadgePropertiesCounter,
  },
})
export default class Tabs extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: IWidgetPage;

  @Prop({
    type: Object,
    required: true,
  })
  data: { id: number };

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  value: string;

  @Prop({
    type: String,
    required: false,
  })
  schemaSuffix: string;

  @Watch('data', { deep: true }) async handleDataChange() {
    await this.updateVisibleTabs();
  }

  @Watch('schema', { deep: true }) async handleSchemaChange() {
    await this.updateVisibleTabs();
  }

  async mounted() {
    await this.updateVisibleTabs();
  }

  currentActiveName: string = null;

  get activeName(): string {
    if (this.value) return this.value;
    return this.currentActiveName;
  }

  set activeName(t: string) {
    this.currentActiveName = t;
    this.$emit('input', t);
  }

  created() {
    if (!this.activeName) {
      this.activeName = this.findTabIdFromRouteNameOrFirst(this.$route.name);
    }
  }

  findTabIdFromRouteNameOrFirst(routeName: string) {
    const t = this.schema.tab.tabs.find((t) => t.routeName === routeName);
    if (t) return t.id;
    return this.schema.tab.tabs[0].id;
  }

  async updateVisibleTabs() {
    const tabs = this.data.id > 0 ? this.schema?.tab?.tabs : this.schema?.tab?.tabs?.filter((t) => t.primary);
    if (tabs != null) {
      const asyncdTabs = [];
      for (const tab of tabs) {
        if (tab.labelEndPoint) {
          const text = await Vue.axios.post(`${tab.labelEndPoint}`, this.data).then((c) => c.data);
          if (text) {
            asyncdTabs.push({
              ...tab,
              text,
            });
          }
        } else {
          asyncdTabs.push(tab);
        }
      }
      this.visibleTabs = asyncdTabs;
    } else {
      this.visibleTabs = tabs;
    }
  }

  visibleTabs: ITabWidget[] = null;

  handleTabClick(tab: { name: string }) {
    const ctab = this.visibleTabs.find((t) => t.id === tab.name);
    if (ctab && ctab.routeName) {
      const route = {
        name: ctab.routeName,
      } as Location;
      if (ctab.params) route.params = { ...ctab.params };
      if (Object.prototype.hasOwnProperty.call(this.$routeNames, ctab.routeName)) {
        this.$router.replace(route);
      } else {
        const firstPrimaryTab = this.schema.tab.tabs.find((t) => t.primary && t.routeName && Object.prototype.hasOwnProperty.call(this.$routeNames, t.routeName));
        if (firstPrimaryTab) {
          this.$router.replace({
            name: firstPrimaryTab.routeName,
            params: this.$route.params,
            query: {
              ...this.$route.query,
              tabName: ctab.routeName,
            },
          });
        }
      }
    }
  }

  updateCounters() {
    for (const counter of this.$refs['endpointCounter'] as TTabBadgeCounter[]) {
      counter.updateCounter();
    }
  }
}
