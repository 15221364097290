
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'PasswordInput',
  mixins: [simpleMixin],
})
export default class PasswordInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  password = '';

  passwordConfirm = '';

  isPasswordSet = false;

  mounted() {
    this.isPasswordSet = this.value.length > 0;
    this.password = this.value;
  }

  handleChangePasswordClick() {
    this.password = null;
    this.passwordConfirm = null;
    this.isPasswordSet = false;
    this.$emit('input', this.password);
  }

  passwordConfirmChange(value) {
    this.passwordConfirm = value;
    if (this.password === this.passwordConfirm) {
      this.$emit('input', this.password);
    } else this.$emit('input', '');
  }

  passwordChange(value) {
    this.password = value;
    if (this.password === this.passwordConfirm) {
      this.$emit('input', this.password);
    } else this.$emit('input', '');
  }
}
