
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Component from 'vue-class-component';
import { IPrintDtoControllerProxy } from 'modules/mixin/store/print/IPrintDtoControllerProxy';
import ITemplate from 'modules/mixin/store/print/ITemplate';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import urlParse from 'url-parse';
import { HttpErrorCode } from '@/initializers/axios';
import _ from 'lodash';
import { TemplateManagerController } from '@/plugins/typings/UIApiControllers/UI/Admin/TemplateManagerController';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { ICloudUserLimit } from 'rt/UI/Injector/ICloudUserLimit';

@Component({
  name: 'Printer',
})
export default class Printer extends Vue {
  @Getter user: IUser;

  @Prop({ type: Number, required: true })
  value: number;

  @Prop({ type: Number, required: false })
  downsizeHeight: number;

  @Prop({ type: String, required: true })
  module: string;

  @Prop({ type: String, required: false, default: null })
  previewType: 'pdf' | 'html';

  @Prop({ type: Boolean, required: false, default: true })
  enableChangeType: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  enableSelect: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  header: boolean;

  @Watch('previewType') async handlePreviewTypeChange(nv, ov) {
    if (ov != null && nv !== ov) {
      await this.print();
    }
  }

  async handleChangeTemplate(t: ITemplate) {
    if (this.template?.name !== t.name) {
      this.template = t;
      await this.print();
    }
  }

  @Prop({ type: Object, required: false })
  parameters: any;

  @Watch('parameters', { deep: true })
  async handleParameterChange() {
    if (this.cancelTokenSource) {
      await this.print();
    }
  }

  template: ITemplate = null;

  previews: any[] = null;

  htmlpreview: string = null;

  notFound = false;

  cancelTokenSource: CancelTokenSource;

  interval = 0;

  get controller(): IPrintDtoControllerProxy<{ id: number }> {
    return this.$store.getters[`${this.module}/controller`];
  }

  get templates(): ITemplate[] {
    return this.$store.getters[`${this.module}/templates`];
  }

  async loadTemplates() {
    return this.$store.dispatch(`${this.module}/loadTemplates`);
  }

  async mounted() {
    const intervalTimer = window.setInterval(() => {
      this.interval += 1;
    }, 1000);
    const limit = this.user.limits as ICloudUserLimit;
    if (limit.printTemplateLimit === null || limit.printTemplateLimit > 1) {
      await this.loadTemplates();
      const templates = this.templates;
      if (templates && templates.length > 0) {
        this.template = templates[0];
      }
    }

    const lastPrintParameters = await this.controller.PrintParameters(this.value);
    if (lastPrintParameters && this.templates != null) {
      const lastTemplate = this.templates.find((t) => t.name === lastPrintParameters.template);
      if (lastTemplate != null) {
        if (lastPrintParameters.template) {
          this.template = lastTemplate;
        }
        if (lastPrintParameters.parameters) {
          this.$emit('update:parameters', lastPrintParameters.parameters);
        }
      }
    }
    if (this.previewType == null && this.template != null) {
      this.previewType = this.template.htmlAllowed ? 'html' : 'pdf';
    }
    await this.print();
    window.clearInterval(intervalTimer);
  }

  get loadingPrintText(): string {
    const text = this.$t('printing') as string;
    const pad = _.pad('', this.interval % 4, '.');
    return `${text}${pad}`;
  }

  async print() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
    this.cancelTokenSource = axios.CancelToken.source();
    this.previews = null;
    this.htmlpreview = null;
    this.notFound = false;
    if (this.previewType === 'html' && !this.template?.htmlAllowed) {
      this.previewType = 'pdf';
      return;
    }
    try {
      if (this.previewType === 'pdf' && (this.template == null || this.template.pdfAllowed)) {
        this.previews = await this.controller.Previews(this.value, this.template?.name, this.parameters, this.cancelTokenSource.token);
      } else if (this.previewType === 'html' && (this.template == null || this.template.htmlAllowed)) {
        this.htmlpreview = await this.controller.HtmlPreview(this.value, this.template?.name, this.parameters, this.cancelTokenSource.token);
      } else throw 'Invalid print conditions';
    } catch (e) {
      if (e != null && e.status === HttpErrorCode.NotFound) {
        this.notFound = true;
      }
      this.previews = [];
    }
  }

  get previewContent() {
    if (this.htmlpreview) {
      const width = (this.$refs['iframe-content'] as HTMLElement).clientWidth;
      return `${this.htmlpreview}<style type="text/css">.preview > .header { overflow: hidden } .preview > .footer { overflow: hidden } .preview > .body { min-height: 600px } ${this.$viewport.width >= 1024 ? '' : `@media screen and (max-width: 1024px) { body { zoom: ${width / 1024}; } }`}</style>`;
    }
    return this.htmlpreview;
  }

  private getFileNameFromUrl(url: string) {
    const downloadUrl = urlParse(url);
    const name = downloadUrl.pathname.substring(downloadUrl.pathname.lastIndexOf('/') + 1);
    if (name) {
      return decodeURIComponent(name);
    }
    return name;
  }

  async open(file: any) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
    this.cancelTokenSource = axios.CancelToken.source();

    const res = await this.controller.GetDownloadUrl(this.value, this.template?.name, this.parameters, this.cancelTokenSource.token);
    const link = document.createElement('a');
    link.href = `${res}&inline=true`;
    link.target = '_blank';
    link.download = this.getFileNameFromUrl(res);
    document.body.appendChild(link);
    link.click();
  }

  async download(file: any) {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
    this.cancelTokenSource = axios.CancelToken.source();
    const res = await this.controller.GetDownloadUrl(this.value, this.template?.name, this.parameters, this.cancelTokenSource.token);
    this.$download(res, this.getFileNameFromUrl(res));
  }

  async archive() {
    return await this.controller.Archive(this.value, this.template?.name, this.parameters, this.cancelTokenSource.token);
  }

  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('aborting pending request');
    }
  }
}
