//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RecentType {
	Company = 0,
	Contact = 1,
	Lead = 2,
	PhoneActivity = 3,
	AppointmentActivity = 4,
	MailActivity = 5,
	FaxActivity = 6,
	LetterActivity = 7,
	GenericActivity = 8,
	MemoActivity = 9,
	SolutionActivity = 10,
	QuoteActivity = 11,
	Appointment = 12,
	Opportunity = 13,
	Quote = 14,
	Ticket = 15,
	Invoice = 16,
	Order = 17,
	DDT = 18,
	JobOrder = 19,
	JobOrderTask = 20,
	Contract = 21,
	TechnicalActivity = 22
}
