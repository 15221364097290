
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Sketch } from 'vue-color';

@Component({
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
})
export default class ColorPicker extends Vue {
  @Prop({
    type: String,
  })
  value: string;

  setColor(c) {
    if (c.hex8 === '#00000000') {
      this.$emit('input', null);
    } else {
      this.$emit('input', c?.hex);
    }
  }
}
