
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ElementUI from 'element-ui';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

// tslint:disable-next-line:variable-name
const PhoneBarAction = namespace('phone-bar').Action;
// tslint:disable-next-line:variable-name
const PhoneBarGetter = namespace('phone-bar').Getter;
// tslint:disable-next-line:variable-name
const PhoneBarMutation = namespace('phone-bar').Mutation;
@Component({
  name: 'VoispeedAdvertise',
  components: {},
})
export default class VoispeedAdvertise extends Vue {
  @Getter user: IUser;

  @PhoneBarGetter voispeedAdvertiseForm: {
    name: string;
    surname: string;
    companyName: string;
    email: string;
    phone: string;
  };

  @PhoneBarGetter voispeedAdvertiseDialog: boolean;

  @PhoneBarMutation setVoispeedAdvertiseDialog: (visible: boolean) => void;

  @PhoneBarMutation setAdvertiseForm: (payload: { name: string; surname: string; companyName: string; email: string; phone: string }) => void;

  @PhoneBarAction initAdvertiseForm: () => Promise<any>;

  @PhoneBarAction sendAdvertiseForm: () => Promise<any>;

  innerVisible = false;

  rules: any = {};

  mounted() {
    this.rules = {
      name: [
        {
          required: true,
          message: 'Il campo "Nome" è obbligatorio',
          trigger: 'blur',
        },
      ],
      surname: [
        {
          required: true,
          message: 'Il campo "Cognome" è obbligatorio',
          trigger: 'blur',
        },
      ],
      companyName: [
        {
          required: true,
          message: 'Il campo "Ragione Sociale" è obbligatorio',
          trigger: 'blur',
        },
      ],
      email: [
        {
          required: true,
          message: this.$t('login.validation.email'),
          trigger: 'blur',
        },
        {
          type: 'email',
          message: this.$t('login.validation.email'),
          trigger: 'blur',
        },
      ],
    };
  }

  handleFormInput(name: string, value: string) {
    this.setAdvertiseForm({
      ...this.voispeedAdvertiseForm,
      [name]: value,
    });
  }

  sendVoispeedAdvertise() {
    (this.$refs.adform as ElementUI.Form).validate(async (valid) => {
      if (valid) {
        await this.sendAdvertiseForm();
        this.innerVisible = true;
      }
      return false;
    });
  }
}
