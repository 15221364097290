
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'LsSection',
  mixins: [simpleMixin],
})
export default class LsSection extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: [Object, Array],
    required: true,
  })
  data: any;

  @Prop({
    type: Number,
    required: true,
  })
  index: number;

  @Prop({
    type: Object,
    required: false,
  })
  context: any;

  collapsed = false;

  mounted() {
    this.collapsed = this.schema.collapsed;
  }

  private fullSizeSpan = 24;

  getCurrentDynamicSpan(index: number, columns: number): number {
    if (columns === 1) return this.fullSizeSpan;
    if (this.$viewport.xs) return this.fullSizeSpan;
    return Math.floor(this.fullSizeSpan / columns);
  }

  toggleVisibility() {
    this.collapsed = !this.collapsed;
  }
}
