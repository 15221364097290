
import Vue from 'vue';
import Component from 'vue-class-component';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { Watch, Provide } from 'vue-property-decorator';
import { IActivityDTO } from 'dto/IActivityDTO';
import {
  Sidebar, BSidebar, Breadcrumb, AlarmNotificationDialog, XsHeader,
} from '../components';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import TPhoneBar from 'modules/phone-bar/views/PhoneBar.vue';
import TPerformance from '../components/Performance.vue';
import TAnagraphicFastLayoutStudio from '../components/AnagraphicFastLayoutStudio.vue';
import TFastOpportunityLayoutStudioDialog from '../../opportunity/components/TFastOpportunityLayoutStudioDialog.vue';
import TFastListLayoutStudioDialog from '../../list/components/TFastListLayoutStudioDialog.vue';
import eventHubManger from 'modules/mixin/views/EventHub';
import { ILeadDTO } from '../../../plugins/typings/DTO/ILeadDTO';
import { ICompanyDTO } from '../../../plugins/typings/DTO/ICompanyDTO';
import { IContactDTO } from '../../../plugins/typings/DTO/IContactDTO';
import { IOpportunityDTO } from '../../../plugins/typings/DTO/IOpportunityDTO';
import { IListDTO } from '../../../plugins/typings/DTO/IListDTO';
import trackings, { ICloudUser } from 'utils/trackings';
import { MessageBox } from 'element-ui';
import { PurchaseController } from '@/plugins/typings/UI/Manage/WebApi/PurchaseController';
import { IPurchasedLicence } from '@/plugins/typings/UI/Manage/WebApi/IPurchasedLicence';
import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';
// tslint:disable-next-line:variable-name
const ActivityGetter = namespace('activity').Getter;
// tslint:disable-next-line:variable-name
const ActivityAction = namespace('activity').Action;
@Component({
  name: 'Container',
  components: {
    TPerformance,
    TAnagraphicFastLayoutStudio,
    TFastOpportunityLayoutStudioDialog,
    TFastListLayoutStudioDialog,
    'app-sidebar': Sidebar,
    'app-bsidebar': BSidebar,
    'app-xsheader': XsHeader,
    't-alarm-notification-dialog': AlarmNotificationDialog,
    't-phone-bar': TPhoneBar,
    't-tour': () => import(/* webpackChunkName: "tour" */ '../../tour/components/Tour.vue'),
    't-on-boarding-story': () => import(/* webPackChunkName:"onboarding" */ '../../onboarding/components/TOnBoardingStory.vue'),
    't-activity-fast-layout-studio': () => import(/* webPackChunkName:"activity" */ 'modules/activity/views/ActivityFastLayoutStudio.vue'),
    't-activity-layout-studio-tabs-wrapper': () => import(/* webPackChunkName:"activity */ 'modules/activity/components/ActivityLayoutStudioTabsWrapper.vue'),
  },
})
export default class Container extends Vue {
  @Getter initialized: boolean;

  @Getter user: IUser;

  @Getter performance: boolean;

  @Getter brand;

  @Getter isInBlackFriday: boolean;

  @Getter isInSDSBlackFriday: boolean;

  @Getter isInSDSCampaign: boolean;

  @Getter config: IConfig;

  @ActivityGetter('changes') activityChanges: () => any;

  @ActivityGetter('model') activity: IActivityDTO;

  onboardingVisible = false;

  stopTour() {
    const q = { ...this.$route.query };
    delete q.tour;
    this.$router.replace({
      query: q,
    });
  }

  immediateStart = false;

  onlyNewTourSteps = false;

  created() {
    this.$root.$on('tour', (payload: { immediate: boolean; onlyNew: boolean }) => {
      this.immediateStart = payload.immediate;
      this.onlyNewTourSteps = payload.onlyNew;
      this.$router.replace({
        query: {
          ...this.$route.query,
          tour: null,
        },
      });
    });
    if (this.$chat.exists) {
      this.$chat.user(this.user as ICloudUser);
      this.$root.$on('help', () => {
        this.$chat.user(this.user as ICloudUser);
        this.$chat.show();
        this.$chat.open();
      });
    }
    this.$root.$on('activity', this.createActivity);
    this.$root.$on('anagraphic', this.createAnagraphic);
    this.$root.$on('opportunity', this.createOpportunity);
    this.$root.$on('list', this.createList);
    this.$root.$on('purchase-dialog', this.initPurchaseDialog);
  }

  beforeDestroy() {
    this.$root.$off('tour');
    this.$root.$off('help');
    this.$root.$off('activity');
    this.$root.$off('anagraphic');
    this.$root.$off('opportunity');
    this.$root.$off('list');
    this.$root.$off('purchase-dialog');
  }

  get tourDelay() {
    if (this.immediateStart) {
      return 0;
    }
    return 1000;
  }

  activityCreateDialogOpen = false;

  activitySubTypeId: number = null;

  defaultsActivityProperties: Partial<IActivityDTO> = {};

  activityFilterTypes: number[] = null;

  activityTitle: string = null;

  activitySchemaName: string = null;

  activityPreSave: () => Promise<void> = null;

  activityCompleteButton = true;

  goToCompleteActivitySheet() {
    this.activityCreateDialogOpen = false;
    this.$router.push({
      name: this.$routeNames.activity,
      params: {
        id: this.$hashids.encode(0),
        subTypeId: this.activity.subTypeId.toString(),
        defaults: JSON.stringify(this.activityChanges()),
      },
    });
  }

  saveActivity() {
    return eventHubManger.getHub('activity.fast').$emit('save');
  }

  createActivity(payload: { activity: Partial<IActivityDTO>; filter: number[]; title: string; schema?: string; preSave: () => Promise<void>; complete?: boolean }) {
    this.activitySubTypeId = null;
    this.defaultsActivityProperties = payload.activity;
    this.activityFilterTypes = payload.filter;
    this.activityTitle = payload.title;
    this.activitySchemaName = payload.schema;
    this.activityCreateDialogOpen = true;
    this.activityPreSave = payload.preSave;
    this.activityCompleteButton = payload.complete ?? true;
  }

  handleActivityCreated() {
    this.activityCreateDialogOpen = false;
  }

  handleActivityEMailSent() {
    this.activityCreateDialogOpen = false;
  }

  anagraphicCreateDialogOpen = false;

  anagraphicDefaults: Partial<IActivityDTO | ICompanyDTO | IContactDTO> = {};

  anagraphicTitle: string = null;

  anagraphicDialogCanCreateCompany = false;

  anagraphicDialogCanCreateContact = false;

  anagraphicDialogCanCreateLead = false;

  anagraphicCallback: (payload: { id: number; type: number }) => Promise<void> = null;

  createAnagraphic(payload: { anagraphic: Partial<IActivityDTO | ICompanyDTO | IContactDTO>; company?: boolean; contact?: boolean; lead?: boolean; title: string; callback: (payload: { id: number; type: number }) => Promise<void> }) {
    this.anagraphicDefaults = payload.anagraphic;
    this.anagraphicTitle = payload.title;
    this.anagraphicCreateDialogOpen = true;
    this.anagraphicCallback = payload.callback;
    this.anagraphicDialogCanCreateCompany = payload.company ?? true;
    this.anagraphicDialogCanCreateContact = payload.contact ?? true;
    this.anagraphicDialogCanCreateLead = payload.lead ?? true;
  }

  handleAnagraphicCreated(payload: { id: number; type: number }) {
    this.anagraphicCreateDialogOpen = false;
    if (this.anagraphicCallback) {
      this.anagraphicCallback(payload);
    }
  }

  opportunityCreateDialogOpen = false;

  opportunityDefaults: Partial<IOpportunityDTO> = {};

  opportunityCallback: (id: number) => Promise<void> = null;

  createOpportunity(payload: { defaults: Partial<IOpportunityDTO>; callback: (id: number) => Promise<void> }) {
    this.opportunityDefaults = payload.defaults;
    this.opportunityCreateDialogOpen = true;
    this.opportunityCallback = payload.callback;
  }

  handleOpportunityCreated(id: number) {
    this.opportunityCreateDialogOpen = false;
    if (this.opportunityCallback) {
      this.opportunityCallback(id);
    }
  }

  listCreateDialogOpen = false;

  listDefaults: Partial<IListDTO> = {};

  listCallback: (id: number) => Promise<void> = null;

  createList(payload: { defaults: Partial<IListDTO>; callback: (id: number) => Promise<void> }) {
    this.listDefaults = payload.defaults;
    this.listCreateDialogOpen = true;
    this.listCallback = payload.callback;
  }

  handleListCreated(id: number) {
    this.listCreateDialogOpen = false;
    if (this.listCallback) {
      this.listCallback(id);
    }
  }

  get icon(): string {
    if (this.activity && this.activity.type === this.$enums.ActivityType.Email) {
      if (this.activity.email && !this.activity.email.sent) {
        return 'el-icon-icon-paperplane';
      }
    }
    return 'el-icon-icon-floppy-disk';
  }

  userChangePurchaseTimeout = 0;

  showPurchaseDialog = false;

  toPurchaseUsers: number = null;

  licence: IPurchasedLicence = null;

  purchaseDialogShown = false;

  initPurchaseDialog(user) {
    if (user.isAdmin && !user.trial && user.freeAccounts !== null && user.freeAccounts < 0 && !this.purchaseDialogShown) {
      window.clearTimeout(this.userChangePurchaseTimeout);
      this.userChangePurchaseTimeout = window.setTimeout(async () => {
        if (!this.initialized) {
          this.initPurchaseDialog(user);
          return;
        }
        this.purchaseDialogShown = true;
        if (user.licence.indexOf('A-S') === 0) {
          const controller = new PurchaseController(this.$http);
          this.licence = await controller.Get();
          if (this.licence != null) {
            this.toPurchaseUsers = this.licence.purchasedQuantity + Math.abs(user.freeAccounts);
            this.showPurchaseDialog = true;
          }
        } else {
          await MessageBox.alert(Vue.i18n.t('account.overlimit.description', { count: Math.abs(user.freeAccounts) }) as string, Vue.i18n.t('account.overlimit.title', { count: Math.abs(user.freeAccounts) }) as string, {
            type: 'error',
            confirmButtonText: 'OK',
          });
          this.$router.push({
            name: this.$routeNames.accounts,
          });
        }
        this.showPurchaseDialog = true;
      }, 1000);
    }
  }

  async goToPurchaseLanding() {
    const controller = new PurchaseController(this.$http);
    const res = await controller.Home();
    if (res) {
      window.location.href = res;
    }
  }
}
