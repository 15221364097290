//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { ICatalogProductPriceDTO } from '../../../DTO/ICatalogProductPriceDTO';
import { ISimpleCatalogLinkDTO } from '../../../DTO/ISimpleCatalogLinkDTO';
import { IStorageDTO } from '../../../DTO/IStorageDTO';
import { ISellingStats } from '../../../Base/BusinessObjects/ISellingStats';
import { IFilePreview } from './IFilePreview';
import { ICatalogDTO } from '../../../DTO/ICatalogDTO';

//element.Name=CatalogController result.Name=CatalogController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.CatalogController);
/** WebApiController: CatalogController */
export class CatalogController extends DefaultDtoControllerProxy<ICatalogDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Catalogs', BusinessObjectType.Catalog);
	}
	public ProductPriceList(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICatalogProductPriceDTO[]>
	{
		return (this.axios.get('Catalogs/ProductPriceList?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProductPriceListIds(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Catalogs/ProductPriceListIds?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ProductPriceListCount(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Catalogs/ProductPriceListCount?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogAlternativeUnits(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Catalogs/CatalogAlternativeUnits?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogAlternativeUnitsIds(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Catalogs/CatalogAlternativeUnitsIds?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogAlternativeUnitsCount(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Catalogs/CatalogAlternativeUnitsCount?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogLink(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISimpleCatalogLinkDTO[]>
	{
		return (this.axios.get('Catalogs/CatalogLink?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogLinkIds(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Catalogs/CatalogLinkIds?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogLinkCount(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Catalogs/CatalogLinkCount?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Documents(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO[]>
	{
		return (this.axios.get('Catalogs/Documents?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Catalogs/DocumentsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Catalogs/DocumentsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SellingStats(id: number, listId?: number, crossId?: number, crossType?: BusinessObjectType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISellingStats>
	{
		return (this.axios.get('Catalogs/SellingStats?&id='+id+((listId === null || listId === undefined) ? '' : ('&listId='+listId))+((crossId === null || crossId === undefined) ? '' : ('&crossId='+crossId))+((crossType === null || crossType === undefined) ? '' : ('&crossType='+crossType)), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Catalogs/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Catalogs/Download?&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Catalogs/HtmlPreview?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Catalogs/Previews?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Catalogs/Preview?&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintHtml(dto: ICatalogDTO, template: string, addHeaderAndFooter: boolean = false, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Catalogs/PrintHtml?'+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+'&addHeaderAndFooter='+addHeaderAndFooter+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Catalogs/GetDownloadUrl?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Catalogs/Archive?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Catalogs/PrintParameters?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICatalogDTO>
	{
		return (this.axios.get('Catalogs/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
