//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { ISpfLookupResult } from '../../../UnifiedMessaging/Core/SharedClasses/ISpfLookupResult';
import { IDmarcResult } from '../../../UnifiedMessaging/Core/SharedClasses/IDmarcResult';
import { IMailingDomainDTO } from '../../../DTO/IMailingDomainDTO';
import { IDomainResult } from '../../../UnifiedMessaging/Core/SharedClasses/IDomainResult';

//element.Name=MailingDomainController result.Name=MailingDomainController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.MailingDomainController);
/** WebApiController: MailingDomainController */
export class MailingDomainController extends DefaultDtoControllerProxy<IMailingDomainDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'MailingsDomains', BusinessObjectType.MailingDomain);
	}
	public Verify(id: number, code: string, prompt?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('MailingsDomains/Verify?&id='+id+((code === null || code === undefined) ? '' : ('&code='+encodeURIComponent(code)))+((prompt === null || prompt === undefined) ? '' : ('&prompt='+encodeURIComponent(prompt))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SPFLookup(domain: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISpfLookupResult>
	{
		return (this.axios.get('MailingsDomains/SPFLookup?'+((domain === null || domain === undefined) ? '' : ('&domain='+encodeURIComponent(domain))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DMARCLookup(domain: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IDmarcResult>
	{
		return (this.axios.get('MailingsDomains/DMARCLookup?'+((domain === null || domain === undefined) ? '' : ('&domain='+encodeURIComponent(domain))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DomainLookup(dto: IMailingDomainDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IDomainResult>
	{
		return (this.axios.post('MailingsDomains/DomainLookup', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Profile(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('MailingsDomains/Profile', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMailingDomainDTO>
	{
		return (this.axios.get('MailingsDomains/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
