//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum BusinessObjectType {
	Company = 0,
	Contact = 1,
	Lead = 2,
	Activity = 3,
	Storage = 4,
	Quote = 5,
	Order = 6,
	Invoice = 7,
	Catalog = 8,
	ContractTemplate = 9,
	Contract = 10,
	RollingStock = 11,
	ServiceRequest = 12,
	DDT = 13,
	Reminder = 15,
	TaxValue = 16,
	Bank = 17,
	ServiceReplacement = 18,
	CatalogMovements = 19,
	MovementsCode = 20,
	Ticket = 21,
	Payment = 23,
	RMA = 24,
	TicketAction = 25,
	Opportunity = 26,
	Addresses = 27,
	PriceList = 28,
	Survey = 29,
	Appointment = 30,
	IncomeDocument = 31,
	KnowledgeBase = 32,
	Account = 33,
	Campaign = 34,
	CampaignStep = 35,
	Promotion = 37,
	ServiceConfiguratorBO = 38,
	Message = 39,
	CompanyMiniEdit = 40,
	ContactMiniEdit = 41,
	LeadMiniEdit = 42,
	ServicePlannedRequest = 43,
	List = 44,
	WorkRange = 45,
	SLATemplate = 46,
	TicketSLA = 47,
	ContractRenewals = 48,
	StreamBank = 49,
	PaymentMovement = 51,
	WebMailAccount = 52,
	Custom = 53,
	CampaignCrossDb = 54,
	CallLog = 55,
	RollingStockAddress = 56,
	RollingStockMovement = 57,
	WorkTeams = 58,
	TeamMembers = 59,
	Meeting = 60,
	Cati = 61,
	CatiQuestion = 62,
	CatiAnswer = 63,
	ContractTypeOfService = 64,
	Group = 65,
	CatalogCategory = 66,
	FlowTemplate = 67,
	DebtRecovery = 68,
	CatalogAlternativeUnit = 69,
	AlternativeUnit = 70,
	CalendarEvent = 71,
	DeliveryCarriage = 72,
	DeliveryAppearance = 73,
	DeliveryCause = 74,
	PriceListDescription = 75,
	Carrier = 76,
	TustenaCompany = 77,
	Currency = 78,
	StorageCategory = 79,
	JobOrder = 80,
	JobOrderType = 81,
	JobOrderStatus = 82,
	JobOrderPriority = 83,
	JobOrderTaskStatus = 84,
	JobOrderTask = 85,
	Asset = 86,
	AssetCategory = 87,
	JobOrderTaskSparePart = 88,
	Zone = 89,
	SalesPerformanceTarget = 90,
	WorkGroup = 91,
	JobOrderBudget = 92,
	JobOrderTemplate = 94,
	Alarm = 95,
	FileDossier = 96,
	PredefinedTitle = 97,
	CalendarConfiguration = 98,
	StorageStatus = 99,
	CatiQuota = 100,
	CatiQuotaUpdate = 101,
	Scheduler = 102,
	SharingTemplate = 103,
	ActivityCost = 104,
	ApprovalConfiguration = 105,
	ApprovalPending = 106,
	OpportunityPhase = 107,
	BusinessRole = 108,
	MarketingCampaign = 109,
	CatalogVariant = 110,
	CatalogVariantGrouping = 111,
	CatalogVariantProduct = 112,
	Note = 113,
	MessageChannel = 114,
	CheckList = 115,
	MailTemplate = 116,
	Report = 117,
	ActivitySubType = 118,
	Skills = 120,
	SkillCategories = 121,
	SkillValueTypes = 122,
	Roles = 123,
	MailCategory = 124,
	TicketGrabberConfiguration = 125,
	DynamoRepeater = 126,
	DynamoPage = 127,
	DynamoInput = 128,
	DynamoSelect = 129,
	DynamoChart = 130,
	CustomErpDocument = 131,
	CustomErpDocumentType = 132,
	DynamoContent = 140,
	ExpenseReportList = 141,
	ExpenseReport = 142,
	RelationType = 143,
	ExchangeCompanyConfiguration = 144,
	ExchangeAccountConfiguration = 145,
	ExpenseItem = 146,
	ExpensePaymentMethod = 147,
	ExpenseVehicle = 148,
	TustenaDB = 149,
	EventLanding = 150,
	Event = 151,
	EventItemStatus = 152,
	EventItem = 153,
	TicketArea = 154,
	TicketType = 155,
	TicketStatus = 156,
	TicketPriority = 157,
	OpportunityCategory = 158,
	OpportunityLostReason = 159,
	EMailType = 160,
	AnagraphicStatus = 161,
	AnagraphicRating = 162,
	AnagraphicInterest = 163,
	AnagraphicSource = 164,
	AnagraphicPriority = 165,
	AnagraphicIndustry = 166,
	AnagraphicCompanyType = 167,
	PhoneType = 168,
	RoboSay = 169,
	QualityIndicator = 170,
	SmsTemplate = 171,
	PrivacyTypeManagement = 172,
	PrivacyManagement = 173,
	PrivacyMotivationsImplicitConsent = 174,
	PrivacyActivityMotivationImplicitConsent = 175,
	Hook = 176,
	FlowTrigger = 177,
	PrintPermission = 178,
	Signature = 179,
	ContactCategory = 180,
	ReferrerCategory = 181,
	Tag = 182,
	Mailing = 183,
	MailingDomain = 184,
	MailingDelivery = 185,
	Flow = 186,
	PaymentMode = 187,
	ProgressiveCode = 188,
	WorkPlan = 189,
	WorkPlanStatus = 190,
	PromotionLostReason = 191,
	RollingCounter = 192,
	RollingCounterType = 193,
	RollingStockCategory = 194,
	JobOrderTypeOfService = 195,
	CrossSpareParts = 196,
	CrossJobOrderTaskPlanned = 197,
	RollingCounterCross = 198,
	DDTConfigPorto = 199,
	DDTConfigAppearance = 200,
	TSDigitalPrivacy = 201,
	ContractCategory = 202,
	PromotionCategory = 203,
	PromotionPhase = 204,
	Custom2 = 241,
	Custom3 = 242,
	Custom4 = 243,
	Custom5 = 244,
	ExtensionsPlayground = 245,
	AbsenceTypes = 250,
	PaymentReason = 251,
	CollectionCodes = 252,
	FileSharingParameters = 253,
	Offices = 254,
	KnowledgeBaseCategory = 255,
	ContractJobOrderTaskPlannedActivity = 256,
	StringTemplate = 257,
	RollingStockInContract = 258,
	RollingStockToServiceInContract = 259,
	CatalogProductSpareParts = 260,
	JobOrderTaskConfig = 261,
	JobOrderTaskConfigUser = 262
}
