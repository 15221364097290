
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter, Action, namespace } from 'vuex-class';

@Component({
  name: 'KanbanItem',
})
export default class KanbanItem extends Vue {
  @Prop({
    required: false,
    default: true,
    type: Boolean,
  })
  draggable: boolean;
}
