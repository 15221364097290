
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IButton } from 'rt/Interfaces/UI/IButton';

@Component({
  name: 'ButtonsWidgets',
})
export default class ButtonsWidgets extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  schema: { buttons: IButton[] };

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  dropdown: boolean;

  get buttons() {
    if (this.schema && this.schema.buttons) {
      return this.schema.buttons.filter((b) => b.dropdown === this.dropdown);
    }
    return [];
  }
}
