//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AnalyticModuleSectionType {
	Default = 0,
	MailingList = 1,
	SalesPerformance = 2,
	Event = 3
}
