//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { SalesTargetReference } from '../../../Core/SalesTargetReference';
import { ISalesPerformanceTargetDTO } from '../../../DTO/ISalesPerformanceTargetDTO';

//element.Name=SalesPerformanceTargetController result.Name=SalesPerformanceTargetController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.SalesPerformanceTargetController);
/** WebApiController: SalesPerformanceTargetController */
export class SalesPerformanceTargetController extends DefaultDtoControllerProxy<ISalesPerformanceTargetDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'SalesPerformanceTargets', BusinessObjectType.SalesPerformanceTarget);
	}
	public GetFilterDateAndTime(targetReference: SalesTargetReference, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('SalesPerformanceTargets/GetFilterDateAndTime?&targetReference='+targetReference, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISalesPerformanceTargetDTO>
	{
		return (this.axios.get('SalesPerformanceTargets/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
