
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'BoTag',
})
export default class BoTag extends Vue {
  @Getter('routes') routes;

  @Prop({
    required: true,
    type: Number,
  })
  value: number;

  @Prop({
    required: true,
    type: Number,
  })
  type: number;

  @Prop({
    required: false,
    type: Boolean,
  })
  closable: boolean;

  hover = false;
}
