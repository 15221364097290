
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IRepeaterColumn, IRowDataInfo } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';

@Component({
  name: 'TagsCell',
})
export default class TagsCell extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  row: IRowDataInfo;

  get tags() {
    if (this.row) {
      const { value } = this.row as any;
      if (value) {
        return value.split(',');
      }
    }
    return [];
  }
}
