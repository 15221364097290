
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import BaseTag from './BaseTag';
import { ITagDTO } from 'dto/ITagDTO';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { Getter, Action, namespace } from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import _ from 'lodash';

@Component({
  name: 'GroupTag',
})
export default class GroupTag extends BaseTag {
  @Getter('user') user: IUser;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  global: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  systemAdministrator: boolean;

  created() {
    this.radioGlobalPersonal = null;
  }

  async createNewTag(text: string, global: boolean): Promise<number> {
    return null;
  }

  getListType(): ListsType {
    return this.$enums.ListsType.ListGroup;
  }

  tagInputValue: string = null;

  tags: number[] = [];

  get inputVisible(): boolean {
    return this.popoverOpen;
  }

  set inputVisible(value: boolean) {
    this.popoverOpen = value;
  }

  get filteredGroups() {
    return this.selectedTags.filter((f) => {
      if (f.value === 0) {
        return this.global;
      }
      if (f.value === this.user.adminGroupId) {
        return this.systemAdministrator;
      }
      return true;
    });
  }

  handleGroupSearch(queryString, callback) {
    this.tagSearch(queryString, (c) => {
      callback(
        c.filter((f) => {
          if (f.value === 0) {
            return this.global;
          }
          if (f.value === this.user.adminGroupId) {
            return this.systemAdministrator;
          }
          return true;
        }),
      );
    });
  }

  isClosable(t) {
    return !this.readOnly && t.value !== this.user.adminGroupId;
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }

  get newItems(): Array<any> {
    const list = this.list(this.$enums.ListsType.ListGroup);
    if (list) {
      return list.map((g) => ({
        ...g,
        disabled: g.value === this.user.adminGroupId,
      }));
    }
    return [];
  }

  loading = false;

  groupEditorVisible = false;
}
