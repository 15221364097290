
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';
import TStorageCategoryPathLabel from './StorageCategoryPathLabel.vue';

// tslint:disable-next-line:variable-name
const StorageAction = namespace('storage').Action;
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageBrowserTable',
  components: {
    TStorageCategoryPathLabel,
  },
})
export default class StorageBrowserTable extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  border: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  stripe: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  highlightCurrentRow: boolean;

  @Prop({ type: Array, required: true })
  data: string;

  @Prop({ type: Boolean, required: false, default: true })
  lastModifiedById: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  lastModifiedDate: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  size: boolean;

  @Prop({ type: Boolean, required: false, default: true })
  commands: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  showFilePath: boolean;

  handleClick(row) {
    this.$emit('input', row);
  }
}
