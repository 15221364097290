//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IReferrerCategoryDTO } from '../../../DTO/IReferrerCategoryDTO';

//element.Name=ReferrerCategoryController result.Name=ReferrerCategoryController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.ReferrerCategoryController);
/** WebApiController: ReferrerCategoryController */
export class ReferrerCategoryController extends DefaultDtoControllerProxy<IReferrerCategoryDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'ReferrersCategories', BusinessObjectType.ReferrerCategory);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IReferrerCategoryDTO>
	{
		return (this.axios.get('ReferrersCategories/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
