
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'IconLabel',
})
export default class IconLabel extends Vue {
  @Prop({
    type: String,
    required: false,
    default: null,
  })
  icon: string;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  content: string;

  @Prop({
    type: String,
    required: false,
    default: 'top',
  })
  placement: string;

  @Prop({
    type: [Object, String],
    required: false,
  })
  iconStyle: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  rtl: boolean;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  shake: string;
}
