
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'CatalogCategoryLookup',
  components: {
    TLookupSkin,
  },
})
export default class CatalogCategoryLookup extends BaseLookup {
  suggestionControllerName() {
    return 'CatalogCategory';
  }

  keyPrefix() {
    return 'Folder';
  }

  async handleCatalogCategoryTreePopoverHide() {
    const catalogCategoryTree = this.$refs['catalog-category-tree'];
    await (catalogCategoryTree as any).reset();
  }

  handleTreeInputChange(items) {
    if (!this.multipleSelection) {
      this.popoverOpen = false;
    }
    this.changeItems(items);
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
