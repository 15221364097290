
import Vue from 'vue';
import { Getter } from 'vuex-class';
import Component from 'vue-class-component';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';

@Component({
  name: 'Particles',
})
export default class Particles extends Vue {
  @Getter brand: IBrand;

  enableParticles = true;

  backgroundsMap: { [key: string]: string } = {
    'venice-1': 'Piazza San Marco - Venezia',
    'venice-2': 'Riva degli Schiavoni - Venezia',
    'taormina-2': 'Duomo di Taormina - Taormina - ME',
    'catania-1': 'Piazza del Duomo - Catania',
    'venaria-1': 'Galleria Grande - Venaria Reale - TO',
    'siena-1': 'Piazza del Campio - Palio - Siena',
    'milano-1': 'Piazza del Duomo - Milano',
    'baeza-1': 'Palacio de Jabalquinto - Baeza - Jaén',
    'baeza-2': 'Palacio de Jabalquinto - Baeza - Jaén',
    'jaen-1': 'Catedral de la Asunción - Jaén',
    'jaen-2': 'Catedral de la Asunción - Jaén',
    'jaen-3': 'La Cruz - Jaén',
    'jaen-4': 'Olivares - Jaén',
    'jaen-5': 'Catedral de la Asunción - Jaén',
    'jaen-6': 'Catedral de la Asunción - Jaén',
    'ubeda-1': 'Palacio de las Cadenas - Úbeda - Jaén',
    'ubeda-2': 'Palacio del Deán Ortega - Úbeda - Jaén',
    'ubeda-3': 'Sacra Capilla del Salvador - Úbeda - Jaén',
    'ubeda-4': 'Sacra Capilla del Salvador - Úbeda - Jaén',
  };

  background: string = null;

  title: string = null;

  changeBackgroundInterval: number = null;

  mounted() {
    this.setBackground();
    // this.changeBackgroundInterval = window.setInterval(() => this.setBackground(), 6000);
  }

  beforeDestroy() {
    if (this.changeBackgroundInterval) {
      window.clearInterval(this.changeBackgroundInterval);
    }
  }

  setBackground() {
    const backgrounds = this.brand.backgrounds ?? Object.keys(this.backgroundsMap);
    const index = parseInt((Math.random() * 1000).toString(), 10) % backgrounds.length;
    const bg = backgrounds[index];
    this.background = bg;
    this.title = this.backgroundsMap[bg];
  }

  enableDisableParticles(v) {
    if (!v) {
      const p = this.$refs['particles'] as any;
      if (p) {
        p.stop();
      }
    }
  }

  handleCookiePreference() {
    (this.$refs['cookie-dialog'] as any).show();
  }
}
