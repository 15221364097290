import Router, { RouteConfig } from 'vue-router';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { IMapViewService } from 'rt/Interfaces/Maps/IMapViewService';
import { IMenuNode } from 'rt/Interfaces/Misc/IMenuNode';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { v4 as uuidv4 } from 'uuid';
import { IProfile } from '@/plugins/typings/UIApiControllers/UI/MailingList/IProfile';
import { IGeo } from '@/plugins/typings/Calendar/IGeo';
import { IBrand } from '@/plugins/typings/UIApiControllers/Authentication/IBrand';
// import signalR from '@aspnet/signalr-client';
export class RootState {
  config: IConfig = null;

  build = '3.14.8-5';

  serverBuild = '[SERVER-DEV-VERSION]';

  currentUser: IUser = null;

  mailingProfile: IProfile = null;

  permits: boolean[] = null;

  tours: { [key: string]: string[] } = {};

  mapService: IMapViewService = null;

  menu: IMenuNode[] = null;

  routes: RouteConfig[] = null;

  baseUrl = '';

  initialized = false;

  ticks: number = new Date().getTime();

  passwordExpirationsNotified: string[] = [];

  notifications: any[] = [];

  oauthRefreshWindowReference: () => Window = null;

  notification = false;

  splashMessages: string[] = ['modules', 'profile', 'connecting', 'satellite', 'ramp', 'warmup', 'testing'];

  splashMessageIndex = 0;

  splashMessageInterval = 0;

  session: string;

  locked = false;

  performance = false;

  officeLocation: IGeo = null;

  homeLocation: IGeo = null;

  languageDebug = false;

  markdownCache: { [key: string]: string } = {};

  cookiePreferences: { [key: string]: boolean } = null;

  brand: IBrand = {
    id: 'crmincloud',
    name: 'CRM in Cloud',
    site: 'https://www.crmincloud.it',
    help: 'https://help.crmincloud.it',
    backgrounds: ['venice-1', 'venice-2', 'taormina-2', 'catania-1', 'venaria-1', 'siena-1', 'milano-1'],
  };

  constructor() {
    this.currentUser = null;
    this.menu = [];
    this.routes = [];
    this.session = localStorage.getItem('session_token');
    if (!this.session) {
      const session = uuidv4();
      localStorage.setItem('session_token', session);
      this.session = session;
    }
    const cookiePreferences = localStorage.getItem('cookie_preferences');
    if (cookiePreferences) {
      try {
        this.cookiePreferences = JSON.parse(cookiePreferences);
      } catch (e) {}
    }
  }
}
