
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseTree from './BaseTree';
import { CatalogTreeViewController } from 'rt/UIApiControllers/TreeView/CatalogTreeViewController';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CatalogCategoryTree',
})
export default class CatalogCategoryTree extends BaseTree {
  catalogTreeViewController = new CatalogTreeViewController(Vue.axios);

  getTreeViewController(): any {
    return this.catalogTreeViewController;
  }

  isLeaf(item): boolean {
    return item.children.length === 0;
  }
}
