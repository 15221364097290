
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    isLast(index) {
      return index === this.list.length - 1;
    },
    showName(item) {
      let nitem = item;
      if (nitem.meta && nitem.meta.label) {
        nitem = nitem.meta && nitem.meta.label;
      }
      if (nitem.name) {
        nitem = nitem.name;
      }
      return nitem.path;
    },
  },
};
