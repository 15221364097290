
import Vue from 'vue';
import Component from 'vue-class-component';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IImageDiscoverRequest } from 'rt/Base/Avatar/IImageDiscoverRequest';
import { ILogo } from 'rt/Interfaces/Misc/ILogo';
import { ImageDiscoverController } from 'rt/UIApiControllers/UI/Smart/ImageDiscoverController';
import difference from 'utils/difference';
import _ from 'lodash';
import jwtManager from 'utils/jwtManager';
import b64toBlob from 'b64-to-blob';

@Component({
  name: 'Base64AvatarUpload',
})
export default class Base64AvatarUpload extends Vue {
  imageDiscoverController = new ImageDiscoverController(Vue.axios);

  @Getter('baseUrl')
  baseUrl;

  @Prop({
    type: String,
    required: false,
  })
  value: string;

  @Prop({
    type: String,
    required: false,
  })
  uploadEndpoint: string;

  @Prop({
    type: Object,
    required: false,
  })
  discover: IImageDiscoverRequest;

  get photoUrl() {
    return this.value;
  }

  @Watch('uploadEndpoint')
  handleEndpointChange(to, from) {
    if (from == null && to != null && this.file != null) {
      this.submitFiles(this.file);
    }
  }

  @Prop({
    type: String,
    required: false,
    default: '140px',
  })
  maxWidth: string;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-image2',
  })
  icon: string;

  file: any;

  base64value: any = null;

  deleted = false;

  get isValidImage(): boolean {
    return this.value != null && !this.deleted;
  }

  submitFiles(file: Blob, fileName?: string) {
    if (this.uploadEndpoint) {
      const formData = new FormData();
      formData.append('file', file, fileName);

      this.$http
        .post(this.uploadEndpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.file = null;
          this.$emit('uploaded');
        })
        .catch(() => { });
    }
  }

  async handleAuthenticatedRequest(request: { file: File }) {
    const b64 = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(request.file);
      reader.onload = () => {
        this.file = request.file;
        this.submitFiles(this.file);
        resolve(reader.result as any);
      };
      reader.onerror = (error) => reject(error);
    });
    const idx = b64.indexOf(',');
    if (idx >= 0) {
      this.base64value = b64.substring(idx + 1);
    }
  }

  async removeAvatar() {
    this.base64value = null;
    if (this.uploadEndpoint) {
      await this.$http.delete(this.uploadEndpoint);
      this.deleted = true;
    }
  }

  avatarSizeLimit: number = 2 * 1024 * 1024;

  beforeAvatarUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size < this.avatarSizeLimit;

    if (!isJPG && !isPNG) {
      this.$message.error(this.$t('avatar.error.format') as string);
      return false;
    }
    if (!isLt2M) {
      this.$message.error(this.$t('avatar.error.limit') as string);
      return false;
    }
    return true;
  }

  async handleError(err: { message: string }, fr: { fileGuid: string; fileName: string }, file, fileList): Promise<any> {
    this.$error.handleWebApiException({ status: 400, data: JSON.parse(err.message) });
  }

  beforeDestroy() {
    if (this.discoverCancelTokenSource) {
      this.discoverCancelTokenSource.cancel('aborting pending request');
    }
  }

  autoDiscovering = false;

  carouselIndex = 0;

  autoDiscoverVisible = false;

  autoDiscoveredLogos: ILogo[] = null;

  discoverCancelTokenSource: CancelTokenSource;

  handleCarouselChange(index) {
    this.carouselIndex = index;
  }

  get autoDiscoverAvailable(): boolean {
    if (this.autoDiscovering) {
      return false;
    }
    if (this.base64value) {
      return false;
    }
    if (this.value) {
      return false;
    }
    if (!this.discover) {
      return false;
    }
    if (this.autoDiscoverVisible) {
      return false;
    }
    if ((this.discover.domain && this.discover.domain.length > 0) || (this.discover.emails && this.discover.emails.length > 0)) {
      return true;
    }
    return false;
  }

  async autoDiscoverImages() {
    if (this.discoverCancelTokenSource) {
      this.discoverCancelTokenSource.cancel('aborting pending request');
    }
    if (this.autoDiscoverAvailable) {
      this.discoverCancelTokenSource = axios.CancelToken.source();
      this.autoDiscovering = true;
      try {
        const logos = await this.imageDiscoverController.Post(this.discover, 300, this.discoverCancelTokenSource.token);
        this.autoDiscovering = false;
        this.autoDiscoveredLogos = logos;
        this.carouselIndex = 0;
        if (logos && logos.length) {
          this.autoDiscoverVisible = true;
        }
      } catch (e) {
        this.autoDiscovering = false;
        throw e;
      }
    }
  }

  handleDiscoverLogoSelect() {
    this.autoDiscoverVisible = false;
    const logo = this.autoDiscoveredLogos[this.carouselIndex];
    if (logo && logo.logo) {
      this.base64value = logo.logo as any as string;
      const blob = b64toBlob(this.base64value, 'image/png') as any;
      this.submitFiles(blob, 'autodiscover.png');
    }
  }
}
