
import Vue from 'vue';
import Component from 'vue-class-component';
import VueScrollTo from 'vue-scrollto';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { IWebMailAccountDTO } from 'dto/IWebMailAccountDTO';
import { IWebMailSmallMessage } from 'rt/Base/WebMail/IWebMailSmallMessage';
import TWebMailListSideItem from '../components/WebMailListSideItem.vue';
import MailboxMixin from './MailboxMixin';

// tslint:disable-next-line:variable-name
const WebMailAction = namespace('webmail').Action;
// tslint:disable-next-line:variable-name
const WebMailGetter = namespace('webmail').Getter;
// tslint:disable-next-line:variable-name
const WebMailMutation = namespace('webmail').Mutation;

@Component({
  name: 'LastMails',
  components: {
    TWebMailListSideItem,
  },
})
export default class LastMails extends MailboxMixin {
  loading = false;

  lastMails: IWebMailSmallMessage[] = null;

  async mounted() {
    this.loading = true;
    await this.load();
    this.lastMails = await this.webMailController.LastMessages();
    this.loading = false;
  }

  get hasWebMail(): boolean {
    return this.webMailAccounts && this.webMailAccounts.length > 0;
  }
}
