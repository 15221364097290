
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Duration',
})
export default class Duration extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  @Prop({
    type: Number,
    required: false,
    default: 15,
  })
  step: number;

  @Prop({
    type: Number,
    required: false,
    default: 15,
  })
  start: number;

  @Prop({
    type: Number,
    required: false,
    default: 600,
  })
  end: number;

  customTime = false;

  mounted() {
    this.customTime = !this.slots.includes(this.value);
  }

  format(s: number): string {
    const d = (this.$moment as any).duration(s, 'minutes');
    if (s > 0 && s % 60 === 0) {
      return d.format('h __');
    }
    return d.format('h __, m __');
  }

  get slots(): number[] {
    const slots = [];
    for (let i = this.start; i <= this.end; i += this.step) {
      slots.push(i);
    }
    return slots;
  }

  get hours(): number {
    if (!this.value) {
      return 0;
    }
    return Math.floor(this.value / 60);
  }

  get minutes(): number {
    if (!this.value) {
      return 0;
    }
    return this.value % 60;
  }

  handleMinutesChange(v: number) {
    let value = v;
    if (v < 0 || !v) {
      value = 0;
    }
    this.$emit('input', this.hours * 60 + value);
  }

  handleHoursChange(v: number) {
    let value = v;
    if (v < 0 || !v) {
      value = 0;
    }
    this.$emit('input', value * 60 + this.minutes);
  }

  changeDuration(total: number) {
    let ctotal = total;
    if (ctotal < 0) ctotal = 0;
    this.$emit('input', ctotal);
  }

  handleDurationChange(v: number) {
    if (v == null) {
      this.customTime = true;
      return;
    }
    this.$emit('input', v);
  }
}
