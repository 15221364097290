
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IRepeaterColumn, IRowDataInfo } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';

@Component({
  name: 'BooleanCell',
})
export default class BooleanCell extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  row: IRowDataInfo;

  get checked() {
    const { value } = this.row as any;
    return value === true || value === 'True' || value === 1 || value === 'true';
  }
}
