//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum RollingStockStatus {
	Active = 0,
	Suspended = 1,
	UnderMaintenance = 2,
	OutOfOrder = 3,
	Disposal = 4,
	Rental = 5
}
