
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FormItem',
})
export default class FormItem extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  label: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  prop: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  required: boolean;

  @Prop({
    type: String,
    required: false,
  })
  labelWidth: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  important: boolean;
}
