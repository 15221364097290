
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import HashIds from 'hashids';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'UnauthorizedMessage',
})
export default class UnauthorizedMessage extends Vue {}
