
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'SearchInput',
})
export default class SearchInput extends Vue {
  @Prop({ required: false, type: String })
  value: string;

  @Prop({ required: false, type: Boolean })
  readonly: boolean;

  @Prop({ required: false, type: Boolean })
  disabled: boolean;

  @Watch('value')
  handleValueChange(v) {
    this.tempSearchKey = v;
  }

  tempSearchKey: string = null;

  mounted() {
    this.tempSearchKey = this.value;
  }

  handleSearchKeySearch() {
    this.$emit('input', this.tempSearchKey);
    this.$emit('search');
  }
}
