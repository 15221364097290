
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import HashIds from 'hashids';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'FloatProfileUsers',
})
export default class FloatProfileUsers extends Vue {
  @Prop({
    type: [Number, Array],
    required: true,
  })
  value: number | number[];

  @Prop({
    type: Number,
    required: false,
    default: 40,
  })
  size: number;

  get accounts() {
    if (Array.isArray(this.value)) return _.uniq(this.value);
    return [this.value];
  }
}
