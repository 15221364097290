//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IAccountAlias } from './IAccountAlias';
import { IMessageInfoRequest } from './IMessageInfoRequest';
import { IMessageInfo } from './IMessageInfo';
import { IMessageResult } from './IMessageResult';
import { IMessageInfoCreateOrUpdateRequest } from './IMessageInfoCreateOrUpdateRequest';
import { IRecipientInfo } from './IRecipientInfo';
import { IMessageAttachmentsInfo } from './IMessageAttachmentsInfo';
import { IUploadMessageAttachRequest } from './IUploadMessageAttachRequest';
import { IMessageAttachRequest } from './IMessageAttachRequest';
import { IMessageListSizeRequest } from './IMessageListSizeRequest';
import { ICommonMessageRequest } from './ICommonMessageRequest';
import { IMessageListRequest } from './IMessageListRequest';

//element.Name=MessagesController result.Name=MessagesController element.FullName=Digita.Tustena.UIApiControllers.UI.Messages.MessagesController);
/** WebApiController: MessagesController */
export class MessagesController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in MessagesController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public GetChannelAlias(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string[]>
	{
		return (this.axios.get('Messages/GetChannelAlias', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetAccountAlias(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string[]>
	{
		return (this.axios.get('Messages/GetAccountAlias', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFullAccountAlias(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAccountAlias[]>
	{
		return (this.axios.get('Messages/GetFullAccountAlias', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DirectIdMessage(message: IMessageInfoRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageInfo>
	{
		return (this.axios.post('Messages/DirectIdMessage', message, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MarkAsReaded(message: IMessageInfoRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageInfo>
	{
		return (this.axios.post('Messages/MarkAsReaded', message, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public NewEmptyMessage(parentId: number, currentChannel: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageInfo>
	{
		return (this.axios.post('Messages/NewEmptyMessage?&parentId='+parentId+'&currentChannel='+currentChannel, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteMessage(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageResult>
	{
		return (this.axios.post('Messages/DeleteMessage?&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveMessage(request: IMessageInfoCreateOrUpdateRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageResult>
	{
		return (this.axios.post('Messages/SaveMessage', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetRecipientsInfo(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IRecipientInfo[]>
	{
		return (this.axios.get('Messages/GetRecipientsInfo?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetMessageAttachments(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageAttachmentsInfo[]>
	{
		return (this.axios.get('Messages/GetMessageAttachments?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AddMessageAttachment(request: IUploadMessageAttachRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageAttachmentsInfo>
	{
		return (this.axios.post('Messages/AddMessageAttachment', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RemoveMessageAttachment(request: IMessageAttachRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageAttachmentsInfo>
	{
		return (this.axios.post('Messages/RemoveMessageAttachment', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DownloadMessageFile(request: IMessageAttachRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Messages/DownloadMessageFile', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DownloadDirectMessageFile(guid: string, id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Messages/DownloadDirectMessageFile?'+((guid === null || guid === undefined) ? '' : ('&guid='+encodeURIComponent(guid)))+'&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LastMessagesLookup(request: IMessageListSizeRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageInfo[]>
	{
		return (this.axios.post('Messages/LastMessagesLookup', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MarkAllReaded(request: ICommonMessageRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Messages/MarkAllReaded', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnreadedMessagesCount(request: ICommonMessageRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.post('Messages/UnreadedMessagesCount', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetMessages(request: IMessageListRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMessageInfo[]>
	{
		return (this.axios.post('Messages/GetMessages', request, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetAllChannels(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Messages/GetAllChannels', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetCurrentChannel(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Messages/GetCurrentChannel', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SetCurrentChannel(channel: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<void>
	{
		return (this.axios.get('Messages/SetCurrentChannel?&channel='+channel, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public IsChannelSubscribed(channel: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Messages/IsChannelSubscribed?&channel='+channel, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SubscribeChannel(channel: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.get('Messages/SubscribeChannel?&channel='+channel, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public UnSubscribeChannel(channel: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.get('Messages/UnSubscribeChannel?&channel='+channel, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSnoozeExpirationDate(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Messages/GetSnoozeExpirationDate', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Messages/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Messages/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
