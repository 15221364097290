
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import {
  Action, Getter, Mutation, namespace,
} from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import HashIds from 'hashids';
import store from '@/store';
import UserProfile from './UserProfileSummary.vue';
import TLastMails from '../../webmail/views/LastMails.vue';
import TSpoolerStatus from '../../mailing/components/SpoolerStatus.vue';
import TWebdavQueueStatus from '../../profile/components/WebdavQueueStatus.vue';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import TLastMessages from '../../messages/views/LastMessages.vue';
import TLicence from '../../tustenaCompany/components/Licence.vue';
import TTourButton from '../../tour/components/TourButton.vue';
import { ISideBarSpoolerStatusResponse } from 'rt/UIApiControllers/UI/SideBar/ISideBarSpoolerStatusResponse';
import routeNames from '../../../router/routeNames';

// tslint:disable-next-line:variable-name
const WebMailAction = namespace('webmail').Action;
// tslint:disable-next-line:variable-name
const WebMailGetter = namespace('webmail').Getter;

// tslint:disable-next-line:variable-name
const MessagesAction = namespace('messages').Action;
// tslint:disable-next-line:variable-name
const MessagesGetter = namespace('messages').Getter;

@Component({
  name: 'BSidebar',
  components: {
    UserProfile,
    TLastMails,
    TLastMessages,
    TSpoolerStatus,
    TWebdavQueueStatus,
    TTourButton,
    TLicence,
  },
})
export default class BSidebar extends Vue {
  isCollapse = true;

  showPopoverMailbox = false;

  showPopoverLicence = false;

  showPopoverMessages = false;

  showUserProfilePopOver = false;

  logoutPopoverVisible = false;

  @WebMailGetter('unseen') unseenMails: number;

  @WebMailAction('loadUnseen') loadUnseenMails: () => Promise<void>;

  @MessagesGetter('unseen') unseenMessages: number;

  @MessagesAction('loadUnseen') loadUnseenMessages: () => Promise<void>;

  @Getter('user') user: IUser;

  @Getter('ticks') ticks;

  @Getter('baseUrl') baseUrl;

  @Action('logout') logout: () => Promise<void>;

  private userSize = 40;

  @Watch('user')
  handleUserAuth(to, from) {
    if (to && (from == null || to.userId !== from.userId)) {
      if (this.$modules.isModuleLoaded('webmail')) {
        this.loadUnseenMails();
      }
      if (this.$modules.isModuleLoaded('messages')) {
        this.loadUnseenMessages();
      }
    }
  }

  mounted() {
    if (this.user) {
      if (this.$modules.isModuleLoaded('webmail')) {
        this.loadUnseenMails();
      }
      if (this.$modules.isModuleLoaded('messages')) {
        this.loadUnseenMessages();
        this.$root.$on('reloadUnseenMessages', () => {
          this.loadUnseenMessages();
        });
      }
    }
  }

  get initials(): string {
    return this.user.initials;
  }

  get imageUrl() {
    const userHash = new HashIds('').encode(this.user.userId, this.user.customerId);
    const imgSize = this.$viewport.isRetina ? this.userSize * 2 : this.userSize;
    return `${this.baseUrl}/Avatar/User/${userHash}/${imgSize}?${this.ticks}`;
  }

  get isMailingRoute() {
    return this.isRoute('spooler');
  }

  get isAnagraphicRoute() {
    return this.isRoute('anagraphic');
  }

  isRoute(prop: string) {
    if (this.$route.matched) {
      for (const route of this.$route.matched) {
        if (route.meta[prop]) {
          return true;
        }
      }
    }
    return false;
  }

  toggleCollpaseMenu() {
    this.isCollapse = !this.isCollapse;
  }

  speeching = false;

  redirectToWebMail() {
    this.$router.push({
      name: this.$routeNames.webmail,
    });
  }
}
