
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'WhenVisibile',
})
export default class WhenVisibile extends Vue {
  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  keepAlive: boolean;

  visible = false;

  async loadingVisibilityChanged(visible: boolean): Promise<void> {
    if (this.keepAlive && !visible) {
      return;
    }
    this.visible = visible;
  }
}
