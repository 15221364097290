
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';

// tslint:disable-next-line:variable-name
const PhoneBarGetter = namespace('phone-bar').Getter;
@Component({
  name: 'TFloatSaveButton',
})
export default class TFloatSaveButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  dirty: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-floppy-disk',
  })
  icon: string;

  @Prop({
    type: String,
    required: false,
    default: 'bar',
  })
  position: string;

  @PhoneBarGetter calling: boolean;

  save() {
    this.$emit('save');
  }
}
