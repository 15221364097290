
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import HashIds from 'hashids';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ProfileImage',
})
export default class ProfileImage extends Vue {
  @Getter ticks: number;

  @Getter user;

  @Getter('baseUrl') baseUrl;

  @Prop({
    type: Number,
    default: 40,
  })
  size: number;

  @Prop({
    type: Number,
    required: true,
  })
  userId: number;

  @Prop({
    type: Number,
    required: false,
    default: null,
  })
  customerId: number;

  get imageUrl() {
    const userHash = new HashIds('').encode(this.userId, this.customerId ?? this.user.customerId);
    const imgSize = this.$viewport.isRetina ? this.size * 2 : this.size;
    return `${this.baseUrl}/Avatar/User/${userHash}/${imgSize}?${this.ticks}`;
  }
}
