import { render, staticRenderFns } from "./BoResolveLabel.vue?vue&type=template&id=409e0859&"
import script from "./BoResolveLabel.vue?vue&type=script&lang=ts&"
export * from "./BoResolveLabel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports