
import { IBookingDTO } from '@/plugins/typings/DTO/IBookingDTO';
import { BookingController } from '@/plugins/typings/UIApiControllers/UI/Calendar/BookingController';
import { IAxiosRequestConfigRetry } from '@/utils/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';
import { ResolveController } from '@/plugins/typings/UIApiControllers/UI/Smart/ResolveController';
import { IBookingSlotDTO } from '@/plugins/typings/DTO/IBookingSlotDTO';

@Component({
  name: 'CalendarBooking',
})
export default class CalendarBooking extends Vue {
  controller: BookingController = new BookingController(this.$http);

  booking: IBookingDTO = null;

  selected: number = null;

  error = false;

  async mounted() {
    const token = this.$route.params.token;
    this.$http.interceptors.request.use((r: IAxiosRequestConfigRetry) => {
      r.headers.Authorization = `Booking ${token}`;
      r.headers['NullValueHandling'] = 'Include';
      return r;
    });
    try {
      const booking = await this.controller.Get2();
      if (booking) {
        if (this.$route.query.preference) {
          const date = this.$moment(this.$route.query.preference);
          const prefSlot = booking.slots.find((s) => this.$moment(s.start).isSame(date, 'day'));
          if (prefSlot != null) {
            this.selected = prefSlot.id;
          }
        }
      }
      this.booking = booking;
    } catch {
      this.error = true;
    }
  }

  get confirmed(): IBookingSlotDTO {
    if (this.booking === null) {
      return null;
    }
    return this.booking.slots.find((s) => s.confirmation);
  }

  get groupedSlots() {
    if (this.booking === null) {
      return null;
    }
    return _.groupBy(this.booking.slots, (s) => this.$moment(s.start).startOf('day').toISOString());
  }

  get duration() {
    const s = this.booking.minutes;
    const d = (this.$moment as any).duration(s, 'minutes');
    if (s > 0 && s % 60 === 0) {
      return d.format('h __');
    }
    return d.format('h __, m __');
  }

  async confirmAppointment() {
    if (this.selected > 0) {
      const slot = this.booking.slots.find((s) => s.id === this.selected);
      if (slot) {
        const from = this.$moment(slot.start);
        const to = from.clone().add(this.booking.minutes, 'minutes');
        const result = await this.$confirm(
          this.$t('booking.confirm', {
            date: from.format('dddd LL'),
            from: from.format('LT'),
            to: to.format('LT'),
          }) as string,
          this.$t('booking.header') as string,
          {
            distinguishCancelAndClose: true,
            type: 'success',
          },
        );
        if (result === 'confirm') {
          slot.confirmation = true;
          try {
            await this.controller.Confirm(this.selected);
          } catch {
            this.error = true;
          }
        }
      }
    }
  }
}
