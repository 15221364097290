
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'Widgets',
  mixins: [simpleMixin],
})
export default class Widgets extends Vue {
  @Prop({ type: Array, required: true })
  schema: any;

  @Prop({ type: Object, required: true })
  data: any;

  @Prop({ type: Object, required: false })
  context: any;
}
