
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import vueUtils from 'utils/vueUtils';

@Component({
  name: 'TDeleteDropdownItem',
})
export default class TDeleteDropdownItem extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  loading;

  @Prop({ type: String, default: 'el-icon-icon-bin', required: false })
  icon;

  @Prop({ type: Boolean, default: false, required: false })
  divided: boolean;

  deleteAction() {
    const slot = vueUtils.renderSlot(this, 'message');
    this.$confirm(slot ? slot.innerHTML : (this.$t('commands.deleteConfirm') as string), {
      type: 'warning',
      dangerouslyUseHTMLString: true,
      cancelButtonText: this.$t('commands.cancel') as string,
    })
      .then(() => {
        this.$emit('delete');
      })
      .catch(() => {});
  }
}
