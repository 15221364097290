
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TTooltipButton',
})
export default class BackButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: String,
    required: false,
    default: () => '',
  })
  type: string;

  @Prop({
    type: String,
    default: () => '',
  })
  icon: string;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  content: string;

  @Prop({
    type: String,
    required: false,
    default: 'top',
  })
  placement: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  disabled: boolean;

  mouseEventTooltipVisible = false;

  tapTooltipVisible = false;

  toggleTapEvent() {
    this.tapTooltipVisible = !this.tapTooltipVisible;
    window.setTimeout(() => {
      this.tapTooltipVisible = false;
    }, 5000);
  }

  get tooltipVisible(): boolean {
    if (!this.content) {
      return false;
    }
    if (this.$viewport.isTouch) {
      return this.tapTooltipVisible;
    }
    return this.mouseEventTooltipVisible;
  }

  set tooltipVisible(v: boolean) {
    if (this.content) {
      this.mouseEventTooltipVisible = v;
    }
  }

  click() {
    this.$emit('click');
  }
}
