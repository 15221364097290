
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import BusinessObjectCommandsMixin from 'modules/mixin/views/BusinessObjectCommandsMixin';
import { IOpportunityDTO } from 'dto/IOpportunityDTO';
import { ListsType } from 'rt/Core/ListsType';
import inViewport from 'in-viewport';

interface IBreadcrumbPhase {
  value: number;
  text: string;
  type: string;
}

@Component({
  name: 'BreadcrumbPhases',
})
export default class BreadcrumbPhases extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  phases: Array<IBreadcrumbPhase>;

  @Prop({
    required: true,
    type: Number,
  })
  value: number;

  skip = 0;

  @Watch('phase')
  handlePhaseWatchChange() {
    this.skip = 0;
  }

  get currentPhases(): Array<IBreadcrumbPhase> {
    if (!this.phases) return null;
    const categoryFilter = this.phases;
    if (this.skip) {
      return categoryFilter.slice(this.skip);
    }
    return categoryFilter;
  }

  updated() {
    const refName = `selectedTargetPhase_${this.value}`;
    const phaseBreadcum = this.$refs[refName] as Element[];
    if (phaseBreadcum && phaseBreadcum.length) {
      const { breadcrumb } = this.$refs;
      const isInViewport = inViewport(phaseBreadcum[0], {
        container: breadcrumb,
      });
      if (!isInViewport && this.skip < 30) {
        this.$nextTick(() => {
          this.skip += 1;
        });
      }
    }
  }

  get currentPhasesIds(): number[] {
    if (!this.currentPhases) {
      return null;
    }
    return this.currentPhases.map((p) => p.value);
  }

  async handlePhaseChange(phase: IBreadcrumbPhase) {
    this.$emit('input', phase);
  }

  getBreadcrumbClass(phase: IBreadcrumbPhase, index: number) {
    const phaseIds = this.currentPhasesIds;
    const currentIndex = phaseIds.findIndex((p) => p === this.value);
    if (currentIndex === index) {
      if (phase.type) {
        return phase.type;
      }
      return 'primary';
    }
    if (currentIndex > -1) {
      if (index < currentIndex) {
        return 'warning';
      }
    }
    return null;
  }
}
