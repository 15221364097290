
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ILiquidStruct } from 'rt/Base/Utility/ILiquidStruct';
import TLiquidMasterField from './LiquidMasterField.vue';
import { isArray } from '@amcharts/amcharts4/core';
import _ from 'lodash';

@Component({
  name: 'LiquidBrowser',
  components: {
    TLiquidMasterField,
  },
})
export default class LiquidBrowser extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  value: ILiquidStruct[][];

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  drag: boolean;

  actives: string[] = ['main-0'];

  search: string = null;

  results: {
    [key: number]: number;
  } = {};

  handleFieldResult(index: number, v: number) {
    Vue.set(this.results, index, v);
  }
}
