
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'textFieldHeader',
})
export default class TextFieldHeader extends HeaderMixin {
  get isValidValueFilter(): boolean {
    return this.value != null;
  }
}
