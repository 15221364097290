//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CalendarCardType {
	Absence = 0,
	Activity = 1,
	Appointment = 2,
	Asset = 3,
	CalendarEvent = 4,
	Event = 5,
	JobOrder = 6,
	JobOrderTask = 7
}
