//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AccountCloneOptions {
	CloneGroups = 0,
	CloneWorkTime = 1,
	CloneTustenaConfig = 2,
	CloneEmailExternalAccount = 3,
	CloneAgenda = 4,
	CloneHomePages = 5,
	ClonePermits = 6,
	CloneInsertGroups = 7,
	CloneLayoutStudio = 8,
	CloneCustomRepeaterColumns = 9,
	CloneTustenaLayout = 10,
	CloneParameters = 11,
	CloneOnPlugins = 12
}
