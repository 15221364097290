
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ILiquidStruct } from 'rt/Base/Utility/ILiquidStruct';
import TLiquidField from './LiquidField.vue';
import _ from 'lodash';

@Component({
  name: 'LiquidMasterField',
  components: {
    TLiquidField,
  },
})
export default class LiquidMasterField extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value: ILiquidStruct;

  @Prop({
    type: String,
    required: true,
  })
  parent: string;

  @Prop({
    type: String,
    required: false,
  })
  search: string;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  drag: boolean;

  get filteredFields() {
    if (this.search && this.search.length > 0 && this.value.value) {
      const fields = this.value.value;
      const pattern = new RegExp(this.search, 'i');
      const filteredFields = fields.filter((f) => {
        if (f.value === null) {
          return pattern.test(f.resourceName);
        }
        return true;
      });
      this.$emit('fields-result', filteredFields.filter((f) => f.value === null).length);
      return filteredFields;
    }
    this.$emit('fields-result', null);
    return this.value.value;
  }

  handleMergeClone(field) {
    if (this.parent) {
      this.$emit('drag', `{{ ${_.camelCase(this.parent)}.${field.name} }}`);
    } else {
      this.$emit('drag', `{{ ${_.camelCase(field.name)} }}`);
    }
  }

  results: {
    [key: number]: number;
  } = {};

  handleFieldResult(index: number, v: number) {
    Vue.set(this.results, index, v);
  }
}
