//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IContactDTO } from '../../../DTO/IContactDTO';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IOpportunityDTO } from '../../../DTO/IOpportunityDTO';
import { IStorageDTO } from '../../../DTO/IStorageDTO';
import { ICompanyDTO } from '../../../DTO/ICompanyDTO';
import { IFlowDTO } from '../../../DTO/IFlowDTO';
import { IListDTO } from '../../../DTO/IListDTO';
import { IMailingDeliveryDTO } from '../../../DTO/IMailingDeliveryDTO';
import { IFilePreview } from './IFilePreview';

//element.Name=CompanyController result.Name=CompanyController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.CompanyController);
/** WebApiController: CompanyController */
export class CompanyController extends DefaultDtoControllerProxy<ICompanyDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Companies', BusinessObjectType.Company);
	}
	public ResolveCustomerToken(token: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/ResolveCustomerToken?'+((token === null || token === undefined) ? '' : ('&token='+encodeURIComponent(token))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Contacts(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IContactDTO[]>
	{
		return (this.axios.get('Companies/Contacts?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public JobOrderTasks(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Companies/JobOrderTasks?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ContactsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/ContactsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ContactsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/ContactsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Activities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('Companies/Activities?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/ActivitiesIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/ActivitiesCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Opportunities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityDTO[]>
	{
		return (this.axios.get('Companies/Opportunities?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OpportunitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/OpportunitiesIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public OpportunitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/OpportunitiesCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Documents(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO[]>
	{
		return (this.axios.get('Companies/Documents?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/DocumentsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/DocumentsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Location(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Companies/Location?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SubLocations(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICompanyDTO[]>
	{
		return (this.axios.get('Companies/SubLocations?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SubLocationsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/SubLocationsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SubLocationsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/SubLocationsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Flows(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowDTO[]>
	{
		return (this.axios.get('Companies/Flows?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/FlowsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/FlowsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Lists(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IListDTO[]>
	{
		return (this.axios.get('Companies/Lists?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ListsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/ListsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ListsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/ListsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Mailings(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IMailingDeliveryDTO[]>
	{
		return (this.axios.get('Companies/Mailings?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MailingsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Companies/MailingsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MailingsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/MailingsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LoadPaymentMethods(companyId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Companies/LoadPaymentMethods?&companyId='+companyId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SavePaymentMethods(companyId: number, paymentMethods: any[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Companies/SavePaymentMethods?&companyId='+companyId, paymentMethods, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Companies/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Companies/Download?&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Companies/HtmlPreview?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Companies/Previews?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Companies/Preview?&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintHtml(dto: ICompanyDTO, template: string, addHeaderAndFooter: boolean = false, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Companies/PrintHtml?'+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+'&addHeaderAndFooter='+addHeaderAndFooter+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Companies/GetDownloadUrl?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Companies/Archive?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Companies/PrintParameters?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ICompanyDTO>
	{
		return (this.axios.get('Companies/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
