
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ListsType } from 'rt/Core/ListsType';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';
import TAccountPop from './AccountPop.vue';

@Component({
  name: 'AccountLookup',
  components: {
    TLookupSkin,
    TAccountPop,
  },
})
export default class AccountLookup extends BaseLookup {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlyWithCalendarRights: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  onlySubAccounts: boolean;

  buildSuggestionParams(): any {
    return {
      impersonate: this.onlyWithCalendarRights,
      onlySubAccounts: this.onlySubAccounts,
    };
  }

  suggestionControllerName() {
    return 'Account';
  }

  keyPrefix() {
    return 'User';
  }

  async handleAccountTreePopoverHide() {
    const accountTree = this.$refs['account-tree'];
    if (accountTree != null) {
      await (accountTree as any).reset();
    }
  }

  handleTreeInputChange(items) {
    if (!this.multipleSelection) {
      this.popoverOpen = false;
    }
    this.changeItems(items);
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }
}
