
import Vue from 'vue';
import ElementUI from 'element-ui';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'Performance',
})
export default class Performance extends Vue {
  performances: any[] = null;

  interval: number = null;

  excludes: number[] = [];

  created() {
    this.updatePerformances();
    this.interval = window.setInterval(() => this.updatePerformances(), 1000);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  exclude(id: number) {
    this.excludes.push(id);
  }

  updatePerformances() {
    this.performances = window.performance
      .getEntriesByType('resource')
      .map((e, index) => {
        (e as any).id = index;
        return e;
      })
      .filter((e) => !this.excludes.includes((e as any).id))
      .filter((e) => (e as any).serverTiming.length)
      .reverse()
      .slice(0, 10);
  }
}
