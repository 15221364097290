
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';
import TOpportunityFastLayoutStudio from '../views/OpportunityFastLayoutStudio.vue';
import eventHubManger from 'modules/mixin/views/EventHub';
import { Prop } from 'vue-property-decorator';
import { IOpportunityDTO } from 'dto/IOpportunityDTO';

// tslint:disable-next-line:variable-name
const OpportunityGetter = namespace('opportunity').Getter;
@Component({
  name: 'TFastOpportunityLayoutStudioDialog',
  components: {
    TOpportunityFastLayoutStudio,
  },
})
export default class TNewOpportunityButton extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  defaults: Partial<IOpportunityDTO>;

  @Prop({
    default: false,
    type: Boolean,
  })
  visible: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  redirect: boolean;

  @OpportunityGetter('changes') opportunityChanges: () => any;

  goToCompleteSheet() {
    this.$emit('update:visible', false);
    const c = this.opportunityChanges();
    this.$router.push({
      name: this.$routeNames.opportunity,
      params: {
        id: this.$hashids.encode(0),
        defaults: JSON.stringify(c),
      },
    });
  }

  saveOpportunity() {
    return eventHubManger.getHub('opportunity.fast').$emit('save');
  }

  handleCreated(id: number) {
    this.$emit('update:visible', false);
    this.$emit('created', id);
  }

  close() {
    this.$emit('update:visible', false);
  }
}
