
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'ActivityLookup',
  components: {
    TLookupSkin,
  },
})
export default class ActivityLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'Activity';
  }

  keyPrefix() {
    return 'Activity';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }

  linkClick(activityId, row) {
    const item = {
      key: `${this.keyPrefix()}-${activityId}`,
      title: row.SUBJECT.value,
    };

    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }
}
