
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, State } from 'vuex-class';
import UserProfile from './UserProfileSummary.vue';

@Component({
  name: 'Login',
  components: {
    UserProfile,
  },
})
export default class Sidebar extends Vue {
  @Getter('user') user: IUser;

  @Getter('menu') menu;

  @Getter brand;

  showUserProfilePopOver = false;

  menuOpen = false;

  get currentRouteName(): string {
    return this.$route.name;
  }

  get menuMainItems() {
    return this.menu.filter((m) => (m.subItems || []).length);
  }

  closeAllExcept(index: string) {
    const sm = this.$refs['menu'] as any;
    for (const subMenu of this.menuMainItems) {
      if (subMenu.id.toString() !== index) {
        sm.close(subMenu.id.toString());
      }
    }
  }
}
