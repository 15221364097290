
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch, Inject } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IPhoneNormalizedDTO } from 'dto/IPhoneNormalizedDTO';
import { ListsType } from 'rt/Core/ListsType';
import eventHubManger from 'modules/mixin/views/EventHub';
import { PhoneNormalizeType } from 'rt/Core/PhoneNormalizeType';

@Component({
  name: 'PhoneNormalizedInput',
})
export default class PhoneNormalizedInput extends Vue {
  @Inject() formSchema;

  @Getter('lists/list')
  list: (type: ListsType) => Array<IInt64ListResult>;

  @Watch('value')
  handleValueChange(val) {
    this.model = this.value.map((v) => ({
      ...v,
    }));
    if (this.model.length === 0) {
      this.handlePlusClick();
    }
  }

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: true,
  })
  value: IPhoneNormalizedDTO[];

  limit: number = 10;

  listTypes: Array<IInt64ListResult> = [];

  model: IPhoneNormalizedDTO[] = null;

  created() {
    this.model = this.value.map((v) => ({
      ...v,
    }));
  }

  async mounted() {
    await this.$store.dispatch('lists/load', this.$enums.ListsType.PhoneType);
    this.listTypes = [...this.list(this.$enums.ListsType.PhoneType)];
    if (this.model.length === 0) {
      this.handlePlusClick();
    }
  }

  get renderingModel() {
    if (this.model && this.model.length > this.limit) {
      return this.model.slice(0, this.limit);
    }
    return this.model;
  }

  handleNumberInput(item: IPhoneNormalizedDTO, newValue: string) {
    item.number = newValue;
    item.displayNumber = newValue;
    this.$emit('input', [...this.model]);
  }

  handleNoteInput(item: IPhoneNormalizedDTO, newValue: string) {
    item.note = newValue;
    this.$emit('input', [...this.model]);
  }

  handleRemovePhone(item: IPhoneNormalizedDTO) {
    const index = this.model.indexOf(item);
    this.model.splice(index, 1);
    this.$emit('input', [...this.model]);
  }

  handlePlusClick() {
    this.model.push({
      macroType: this.listTypes[0].value,
      subType: 0,
      number: '',
      displayNumber: '',
      note: '',
    });
  }

  handleTypeChange(item: IPhoneNormalizedDTO, type: number) {
    if (type < 0) {
      item.macroType = -type;
      item.subType = 0;
    } else {
      item.macroType = 0;
      item.subType = type;
    }
    this.$emit('input', [...this.model]);
  }

  resolveTypeLabel(item: IPhoneNormalizedDTO) {
    let value = item.subType;
    if (value === 0) value = -item.macroType;
    if (!this.listTypes || !this.listTypes.length) return '';
    const t = this.listTypes.find((l) => l.value === value);
    if (t) return t.text;
    return this.listTypes[0].text;
  }

  handlePhoneClick(item: IPhoneNormalizedDTO) {
    return eventHubManger.getHub('phone-bar').$emit('dial', {
      number: item.displayNumber,
      id: this.data.id,
      type: this.formSchema.businessObjectType,
    });
  }

  resolveIcon(item: IPhoneNormalizedDTO) {
    if (!isNaN(item.macroType)) {
      switch (item.macroType) {
        case PhoneNormalizeType.Phone:
        case PhoneNormalizeType.Phone2:
        case PhoneNormalizeType.Assistant:
          return 'el-icon-icon-phone2';
        case PhoneNormalizeType.MobilePhone:
        case PhoneNormalizeType.MobilePhone2:
          return 'el-icon-icon-mobile';
        case PhoneNormalizeType.Fax:
          return null;
      }
    }
    return 'el-icon-icon-phone2';
  }
}
