
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, State } from 'vuex-class';

@Component({
  name: 'Login',
})
export default class Sidebar extends Vue {
  @Getter('menu') menu;

  @Getter brand;

  isCollapse = false;

  constructor() {
    super();
    this.isCollapse = this.$viewport.mdAndDown;
  }

  get currentRouteName(): string {
    return this.$route.name;
  }

  toggleCollapseMenu() {
    if (this.$viewport.mdAndDown) {
      this.isCollapse = true;
      return;
    }
    this.isCollapse = !this.isCollapse;
  }

  get menuMainItems() {
    return this.menu.filter((m) => (m.subItems || []).length);
  }

  get isMenuCollapsed() {
    if (this.$route.matched) {
      for (const route of this.$route.matched) {
        if (route.meta.compact) {
          return true;
        }
      }
    }
    return this.isCollapse;
  }

  closeAllExcept(index: string) {
    const sm = this.$refs['menu'] as any;
    for (const subMenu of this.menuMainItems) {
      if (subMenu.id.toString() !== index) {
        sm.close(subMenu.id.toString());
      }
    }
  }
}
