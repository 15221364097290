
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ElementUI from 'element-ui';
import { IOpportunityDTO } from 'dto/IOpportunityDTO';
import LayoutStudioMixinView from 'modules/mixin/views/LayoutStudioMixin.vue';
import OpportunityMixin from './OpportunityMixin';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';

@Component({
  name: 'OpportunityLayoutStudioView',
  mixins: [OpportunityMixin],
})
export default class OpportunityLayoutStudioView extends LayoutStudioMixinView<IOpportunityDTO> {
  @Action('lists/load') load: (type: ListsType) => Promise<any>;

  @Getter('lists/list') list: (type: ListsType) => Array<IInt64ListResult>;

  protected get module(): string {
    return 'opportunity';
  }

  protected get routeName(): string {
    return this.$routeNames.opportunity;
  }

  protected get backRouteName(): string {
    return this.$routeNames.opportunities;
  }

  @Watch('model.phase')
  async changePhase(newValue: number, oldValue: number) {
    await this.load(this.$enums.ListsType.ListPhase);
    const phases = this.list(this.$enums.ListsType.ListPhase);
    const cphase = phases.filter((p) => p.value === newValue)[0];
    if (cphase) {
      if (+cphase.infoData['Status'].value) {
        const weight = +cphase.infoData['Weight'].value;
        this.update({
          ...this.model,
          probability: weight >= 0 ? weight : this.model.probability,
          status: +cphase.infoData['Status'].value,
        });
      }
    }
  }
}
