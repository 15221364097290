
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'SummaryLabel',
  props: {
    iconAlign: {
      type: String,
      required: false,
      default: 'middle',
    },
  },
})
export default class SummaryLabel extends Vue {}
