
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { VatIdController } from 'rt/UIApiControllers/UI/Smart/VatIdController';
import FlowTemplatesGridView from '../../../../modules/flowTemplate/views/FlowTemplatesGrid.vue';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';

@Component({
  name: 'TaxIdentificationNumberInput',
  mixins: [simpleMixin],
})
export default class TaxIdentificationNumberInput extends Vue {
  @Inject() formSchema;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: ICompanyDTO | IContactDTO | ILeadDTO;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  loading = false;

  change(value) {
    this.$emit('input', value);
  }
}
