
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import simpleMixin from './mixin';
import { ILayoutStudioSlot } from 'rt/Interfaces/UI/ILayoutStudioSlot';
import { ILayoutStudioSlotCircumstance } from 'rt/Interfaces/UI/ILayoutStudioSlotCircumstance';
import _ from 'lodash';

interface ILayoutStudioSlotWithPropertyName extends ILayoutStudioSlot {
  propertyName?: string;
}

@Component({
  name: 'Widget',
  mixins: [simpleMixin],
})
export default class Widget extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: ILayoutStudioSlotWithPropertyName;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Object,
    required: false,
  })
  context: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  get conditionSuccess() {
    if (!this.schema.conditions) return true;
    let isAllSuccess = true;
    for (const conditionValue of this.schema.conditions) {
      if (conditionValue.visible != null) {
        const propertyName = conditionValue.id;
        if (Object.prototype.hasOwnProperty.call(this.data, propertyName)) {
          const valid = this.isValidCondition(this.data[propertyName], conditionValue);
          if (conditionValue.visible) {
            isAllSuccess = isAllSuccess && valid;
          } else {
            isAllSuccess = isAllSuccess && !valid;
          }
        } else {
          isAllSuccess = isAllSuccess && false;
        }
      }
    }
    return isAllSuccess;
  }

  private isValidCondition(value: any, conditionValue: ILayoutStudioSlotCircumstance): boolean {
    if (conditionValue.always) {
      return true;
    }
    switch (conditionValue.operator) {
      case this.$enums.ReportConditions.Equal:
        return value === conditionValue.value;
      case this.$enums.ReportConditions.NotEqual:
        return value !== conditionValue.value;
      case this.$enums.ReportConditions.InList:
        return conditionValue.value.indexOf(value) > -1;
      case this.$enums.ReportConditions.NotInList:
        return conditionValue.value.indexOf(value) === -1;
      case this.$enums.ReportConditions.GreaterThan:
        return value > conditionValue.value;
      case this.$enums.ReportConditions.LessThan:
        return value < conditionValue.value;
      case this.$enums.ReportConditions.IsNotEmpty:
        if (value === null || value === undefined) {
          return false;
        }
        if (_.isArray(value)) {
          return value.length > 0;
        }
        return value !== '';
      case this.$enums.ReportConditions.IsEmpty:
        if (value === null || value === undefined) {
          return true;
        }
        if (_.isArray(value)) {
          return value.length === 0;
        }
        return value === '';
    }
    return false;
  }

  get isValidPropertyName() {
    if (this.schema.propertyName) {
      return Object.prototype.hasOwnProperty.call(this.data, this.schema.propertyName);
    }
    return false;
  }

  get value() {
    if (this.schema.propertyName) {
      if (!Object.prototype.hasOwnProperty.call(this.data, this.schema.propertyName)) {
        console.warn(`Cannot find property ${this.schema.propertyName} inside schema`, this.schema);
        return '';
      }
      return this.data[this.schema.propertyName];
    }
    return '';
  }

  async change(property, value) {
    let currentModel = {
      ...this.data,
      [property]: value,
    };
    if (this.schema && this.schema.mutations && this.schema.mutations.length) {
      for (const mutation of this.schema.mutations) {
        const propertyName = mutation.id;
        if (Object.prototype.hasOwnProperty.call(currentModel, propertyName)) {
          const valid = this.isValidCondition(currentModel[propertyName], mutation);
          if (valid) {
            switch (mutation.type) {
              case 'mutate':
                if (mutation.mutation) {
                  currentModel = {
                    ...currentModel,
                    ...mutation.mutation,
                  };
                }
                break;
              case 'callback':
                const m = mutation as any as { actionName: string; endPoint: string; pushCompleteModel: boolean; modelFields: string[] };
                const source = m.pushCompleteModel ? currentModel : this.partialModel(currentModel, propertyName, m.modelFields);
                const url = `${m.endPoint}/${m.actionName}`;
                const nm = await Vue.axios
                  .post(url, {
                    payload: source,
                    context: this.context,
                  })
                  .then((r) => r.data);
                if (nm) {
                  currentModel = {
                    ...currentModel,
                    ...nm,
                  };
                }
                break;
            }
          }
        }
      }
    }
    this.$emit('input', currentModel);
  }

  private partialModel(model: any, propertyName: string, modelFields: string[]): any {
    if (!model) {
      return model;
    }
    const nm = {
      id: model.id,
      [propertyName]: model[propertyName],
    };
    if (modelFields) {
      for (const mf of modelFields) {
        nm[mf] = model[mf];
      }
    }
    return nm;
  }

  get readOnlySchema() {
    const { schema } = this;
    if (!schema.conditions) return schema;
    if (schema.readOnly) return schema;
    let isReadOnly = false;
    for (const conditionValue of this.schema.conditions) {
      if (conditionValue.readOnly != null) {
        const propertyName = conditionValue.id;
        if (Object.prototype.hasOwnProperty.call(this.data, propertyName)) {
          const valid = this.isValidCondition(this.data[propertyName], conditionValue);
          if (conditionValue.readOnly) {
            isReadOnly = isReadOnly || valid;
          } else {
            isReadOnly = isReadOnly || !valid;
          }
        }
      }
    }
    return {
      ...schema,
      readOnly: isReadOnly,
    };
  }
}
