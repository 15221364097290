
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IPhoneNormalizedDTO } from 'dto/IPhoneNormalizedDTO';
import { Prop } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { PhoneNormalizeType } from 'rt/Core/PhoneNormalizeType';
import eventHubManger from 'modules/mixin/views/EventHub';

@Component({
  name: 'PhoneLabel',
})
export default class PhoneLabel extends Vue {
  @Getter('lists/resolve') resolver: (type: ListsType, key: number) => string;

  @Prop({
    type: Object,
    required: true,
  })
  value: IPhoneNormalizedDTO;

  @Prop({
    type: Number,
    required: true,
  })
  id: number;

  @Prop({
    type: Number,
    required: true,
  })
  type: BusinessObjectType;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  dial: boolean;

  get visible() {
    if (!this.value) {
      return false;
    }
    if (!isNaN(this.type)) {
      if (this.value.macroType === PhoneNormalizeType.Fax) {
        return false;
      }
    }
    return true;
  }

  get icon() {
    if (!isNaN(this.value.macroType)) {
      switch (this.value.macroType) {
        case PhoneNormalizeType.Phone:
        case PhoneNormalizeType.Phone2:
        case PhoneNormalizeType.Assistant:
          return 'el-icon-icon-phone2';
        case PhoneNormalizeType.MobilePhone:
        case PhoneNormalizeType.MobilePhone2:
          return 'el-icon-icon-mobile';
        case PhoneNormalizeType.Fax:
          return null;
      }
    }
    return 'el-icon-icon-phone2';
  }

  get kind(): string {
    let value = this.value.subType;
    if (value === 0) value = this.value.macroType;
    return this.resolver(ListsType.PhoneType, value);
  }

  handlePhoneClick() {
    return eventHubManger.getHub('phone-bar').$emit('dial', {
      number: this.value.displayNumber,
      id: this.id,
      type: this.type,
    });
  }
}
