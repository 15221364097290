
import Vue from 'vue';
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { Getter, Action, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { StorageController } from 'rt/UIApiControllers/BusinessObject/DTO/StorageController';
import _ from 'lodash';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

// tslint:disable-next-line:variable-name
const StorageAction = namespace('storage').Action;
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageDetail',
})
export default class StorageDetail extends Vue {
  @Getter user: IUser;

  @Prop({
    type: Object,
    required: true,
  })
  value: IStorageDTO;

  @Prop({ type: Boolean, required: false, default: false })
  enableSelect: boolean;

  @Watch('value') async handleValueChange() {
    await this.loadReferences();
  }

  @StorageGetter('controller') controller: StorageController;

  async mounted() {
    await this.loadReferences();
  }

  references: { crossId: number; crossType: number }[] = null;

  async loadReferences() {
    this.references = null;
    this.references = await this.controller.References(this.value.id);
  }

  async handleRemoveLink(ref: { crossId: number; crossType: number }) {
    this.$confirm(this.$t('storage.references.unlink') as string, this.$t('commands.confirm') as string, {
      distinguishCancelAndClose: false,
      type: 'warning',
    })
      .then(async () => {
        await this.controller.DeleteReference(this.value.id, ref.crossId, ref.crossType);
        this.references = [...this.references].filter((r) => r.crossId !== ref.crossId && r.crossType !== ref.crossType);
      })
      .catch();
  }
}
