
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'Loading',
})
export default class Loading extends Vue {
  @Getter user: IUser;

  @Getter('initialized') initialized: boolean;

  @Getter splashMessage: string;

  @Getter brand: string;

  @Watch('initialized')
  handleInitializedChange(newVal: boolean) {
    if (newVal) {
      this.goToLandingPage();
    }
  }

  mounted() {
    if (this.initialized) {
      this.goToLandingPage();
    }
  }

  get companyPhoto(): string {
    if (this.user) {
      return this.user.companyPhoto;
    }
    return null;
  }

  goToLandingPage() {
    if (this.$route.query.reenton) {
      const query = { ...this.$route.query };
      delete query.reenton;
      this.$router.replace({
        query,
        path: this.$route.query.reenton as string,
      });
    }
  }
}
