
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import businessObjectTypeMapping from 'utils/businessObjectTypeMapping';
import { Getter, Action, namespace } from 'vuex-class';

@Component({
  name: 'LookupSkin',
})
export default class LookupTag extends Vue {
  @Getter('routes') routes;

  @Prop({
    type: Object,
    required: true,
  })
  value: any;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  closable: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'medium',
  })
  size: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  route: boolean;

  @Prop({
    type: String,
    required: false,
  })
  hoverIcon: string;

  hover = false;

  get id(): number {
    return +this.value.key.split('-')[1];
  }

  get prefix(): string {
    return this.value.key.split('-')[0];
  }

  get type() {
    const t = this.getIdentifier;
    if (!isNaN(t)) {
      return businessObjectTypeMapping.toTagType(t);
    }
    return null;
  }

  get icon() {
    const t = this.getIdentifier;
    if (!isNaN(t)) {
      return businessObjectTypeMapping.toIcon(t);
    }
    return null;
  }

  get getIdentifier(): number {
    if (this.value && this.value.key) {
      return +this.$enums.BusinessObjectType[this.prefix];
    }
    return null;
  }
}
