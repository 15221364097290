//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { ISimilarityRequest } from './ISimilarityRequest';
import { ISimilarityResponse } from './ISimilarityResponse';

//element.Name=SimilarityController result.Name=SimilarityController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.SimilarityController);
/** WebApiController: SimilarityController */
export class SimilarityController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in SimilarityController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Search(req: ISimilarityRequest, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Similarity/Search', req, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Searches(reqs: ISimilarityRequest[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISimilarityResponse[]>
	{
		return (this.axios.post('Similarity/Searches', reqs, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Similarity/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Similarity/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
