
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { IWebDavQueueStats } from 'rt/UIApiControllers/BusinessObject/DTO/IWebDavQueueStats';

// tslint:disable-next-line:variable-name
const ProfileGetter = namespace('profile').Getter;
// tslint:disable-next-line:variable-name
const ProfileAction = namespace('profile').Action;

@Component({
  name: 'WebdavQueueStatus',
  components: {},
})
export default class WebdavQueueStatus extends Vue {
  @ProfileAction loadQueueStats: () => Promise<void>;

  @ProfileAction importAsCompany: (id: string) => Promise<boolean>;

  @ProfileAction importAsContact: (id: string) => Promise<boolean>;

  @ProfileAction importAsLead: (id: string) => Promise<boolean>;

  @ProfileGetter queueStats: IWebDavQueueStats;

  async mounted() {
    await this.loadQueueStats();
  }

  get hasItemsInQueue() {
    return this.queueStats != null && this.queueStats.pendingItems > 0;
  }

  async handleRowCommandClick(cmd, uniqueId) {
    await this[cmd](uniqueId);
  }
}
