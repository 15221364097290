//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum DefaultDocumentCategories {
	None = 0,
	Fax = 1,
	Email = 2,
	Image = 3,
	Quote = 4,
	Order = 5,
	Invoice = 6,
	DDT = 7,
	Public = 8,
	PublicForUpload = 9,
	Ticket = 10,
	ActivityOutMail = 11,
	Contract = 12,
	Activity = 13,
	CustomErpDocument = 14,
	ExpenseReport = 15,
	Logs = 16,
	Privacy = 50
}
