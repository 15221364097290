
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { Watch, Prop, Inject } from 'vue-property-decorator';
import simpleMixin from './mixin';
import LookupMixin from './lookupMixin';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 'CrossAnagraphicInput',
  mixins: [simpleMixin],
})
export default class CrossAnagraphicInput extends Vue {
  resolveController = new ResolveController(Vue.axios);

  @Inject('elForm') elForm: ElementUI.Form;

  get isReadOnly() {
    return this.schema.readOnly || ((this.elForm as ElementUI.Form) || {}).disabled;
  }

  @Prop({
    type: Object,
    required: false,
    default: () => ({
      text: 'schema.text',
      propertyName: 'schema.propertyName',
      multiple: false,
      readOnly: false,
      enableCompany: true,
      enableContact: true,
      enableLead: true,
    }),
  })
  schema: {
    propertyName: string;
    crossTypePropertyName: string;
    crossIdPropertyName: string;
    multiple: boolean;
    readOnly: boolean;
    enableCompany: boolean;
    enableContact: boolean;
    enableLead: boolean;
  };

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  multiple: boolean;

  get crossIdPropertyName(): string {
    return (this.schema || { crossIdPropertyName: null }).crossIdPropertyName || 'id';
  }

  get crossTypePropertyName(): string {
    return (this.schema || { crossTypePropertyName: null }).crossTypePropertyName || 'type';
  }

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  selectedData: any = [];

  simpleEmit: (value: any) => void;

  async resolve(id: number, type: BusinessObjectType): Promise<string> {
    return (await this.resolveController.Get(id, type)).name;
  }

  get value() {
    return {
      id: this.data[this.crossIdPropertyName],
      type: this.data[this.crossTypePropertyName],
    };
  }

  change(value) {
    if (this.elForm && this.schema && this.schema.crossIdPropertyName) {
      this.elForm.clearValidate(this.schema.crossIdPropertyName);
    }
    if (value.length > 0) {
      this.simpleEmit({
        ...this.data,
        [this.crossIdPropertyName]: this.getIdFromString(value[0].key),
        [this.crossTypePropertyName]: BusinessObjectType[this.getIdentifierFromString(value[0].key)],
      });
    } else {
      this.simpleEmit({
        ...this.data,
        [this.crossIdPropertyName]: 0,
        [this.crossTypePropertyName]: 0,
      });
    }
  }

  @Watch('value')
  async handleValueChange(newVal: { id: number; type: BusinessObjectType }, oldVal: { id: number; type: BusinessObjectType }) {
    if (newVal.id !== oldVal.id || newVal.type !== oldVal.type) {
      const identifier = BusinessObjectType[newVal.type];
      if (newVal.id) {
        const sd = [
          {
            key: `${identifier}-${newVal.id}`,
            title: await this.resolve(newVal.id, newVal.type),
          },
        ];
        this.selectedData = sd;
      } else {
        this.selectedData = [];
      }
    }
  }

  getIdFromString(idString: string): number {
    const result = idString.split('-')[1];
    return parseInt(result, 10);
  }

  getIdentifierFromString(idString: string): string {
    return idString.split('-')[0];
  }

  async mounted() {
    if (this.value && this.value.id > 0) {
      this.selectedData = [
        {
          key: `${BusinessObjectType[this.value.type]}-${this.value.id}`,
          title: await this.resolve(this.value.id, this.value.type),
        },
      ];
    } else {
      this.selectedData = [];
    }
  }

  removeItem() {
    this.change([]);
  }
}
