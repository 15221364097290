//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IActivityDTO } from '../../../DTO/IActivityDTO';
import { IStorageDTO } from '../../../DTO/IStorageDTO';
import { IOpportunityReferrerDTO } from '../../../DTO/IOpportunityReferrerDTO';
import { IOpportunityPartnerDTO } from '../../../DTO/IOpportunityPartnerDTO';
import { IOpportunityCompetitorDTO } from '../../../DTO/IOpportunityCompetitorDTO';
import { IOpportunityProductDTO } from '../../../DTO/IOpportunityProductDTO';
import { IOpportunityDTO } from '../../../DTO/IOpportunityDTO';
import { IQuoteDTO } from '../../../DTO/IQuoteDTO';
import { FilterDate } from '../../../Core/FilterDate';
import { IKanbanPhaseGroup } from './IKanbanPhaseGroup';
import { IFilePreview } from './IFilePreview';

//element.Name=OpportunityController result.Name=OpportunityController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.OpportunityController);
/** WebApiController: OpportunityController */
export class OpportunityController extends DefaultDtoControllerProxy<IOpportunityDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Opportunities', BusinessObjectType.Opportunity);
	}
	public Activities(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IActivityDTO[]>
	{
		return (this.axios.get('Opportunities/Activities?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Opportunities/ActivitiesIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ActivitiesCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/ActivitiesCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Documents(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO[]>
	{
		return (this.axios.get('Opportunities/Documents?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('Opportunities/DocumentsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsCount(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/DocumentsCount?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Referrers(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityReferrerDTO[]>
	{
		return (this.axios.get('Opportunities/Referrers?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveReferrer(id: number, referrer: IOpportunityReferrerDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/SaveReferrer?&id='+id, referrer, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EditReferrer(id: number, referrer: IOpportunityReferrerDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/EditReferrer?&id='+id, referrer, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteReferrer(id: number, referrerId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('Opportunities/DeleteReferrer?&id='+id+'&referrerId='+referrerId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SavePartner(id: number, partner: IOpportunityPartnerDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/SavePartner?&id='+id, partner, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EditPartner(id: number, partner: IOpportunityPartnerDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/EditPartner?&id='+id, partner, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeletePartner(id: number, partnerId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('Opportunities/DeletePartner?&id='+id+'&partnerId='+partnerId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveCompetitor(id: number, competitor: IOpportunityCompetitorDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/SaveCompetitor?&id='+id, competitor, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public EditCompetitor(id: number, competitor: IOpportunityCompetitorDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/EditCompetitor?&id='+id, competitor, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteCompetitor(id: number, competitorId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('Opportunities/DeleteCompetitor?&id='+id+'&competitorId='+competitorId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Products(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityProductDTO[]>
	{
		return (this.axios.get('Opportunities/Products?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Product(id: number, productId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityProductDTO[]>
	{
		return (this.axios.get('Opportunities/Product?&id='+id+'&productId='+productId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public SaveProduct(id: number, product: IOpportunityProductDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Opportunities/SaveProduct?&id='+id, product, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DeleteProduct(id: number, productId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.delete('Opportunities/DeleteProduct?&id='+id+'&productId='+productId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RecentOpportunities(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityDTO[]>
	{
		return (this.axios.get('Opportunities/RecentOpportunities', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Sum(filter?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/Sum?'+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Average(filter?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/Average?'+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public WeightedSum(filter?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/WeightedSum?'+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public WeightedAverage(filter?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/WeightedAverage?'+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CreateQuote(dto: IOpportunityDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IQuoteDTO>
	{
		return (this.axios.post('Opportunities/CreateQuote', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Kanban(date: FilterDate, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<{ [key:number]: IKanbanPhaseGroup }>
	{
		return (this.axios.get('Opportunities/Kanban?&date='+date+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Templates(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any[]>
	{
		return (this.axios.get('Opportunities/Templates', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Download(id: number, filename: string, template?: string, request?: any, inline: boolean = false, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Opportunities/Download?&id='+id+((filename === null || filename === undefined) ? '' : ('&filename='+encodeURIComponent(filename)))+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&')))+'&inline='+inline, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public HtmlPreview(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.post('Opportunities/HtmlPreview?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Previews(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFilePreview[]>
	{
		return (this.axios.post('Opportunities/Previews?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Preview(id: number, page: number = 1, zoom: number = 0, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Opportunities/Preview?&id='+id+'&page='+page+'&zoom='+zoom+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintHtml(dto: IOpportunityDTO, template: string, addHeaderAndFooter: boolean = false, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Opportunities/PrintHtml?'+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+'&addHeaderAndFooter='+addHeaderAndFooter+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDownloadUrl(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<string>
	{
		return (this.axios.get('Opportunities/GetDownloadUrl?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Archive(id: number, template?: string, request?: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('Opportunities/Archive?&id='+id+((template === null || template === undefined) ? '' : ('&template='+encodeURIComponent(template)))+((request === null || request === undefined) ? '' : ('&'+Object.keys(request).filter(k => request[k]).map(k => k+'='+encodeURIComponent(request[k])).join('&'))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PrintParameters(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Opportunities/PrintParameters?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IOpportunityDTO>
	{
		return (this.axios.get('Opportunities/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
