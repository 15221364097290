
import Vue from 'vue';
import Component from 'vue-class-component';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { IGridState, GridState } from 'modules/grids/store/state';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { MessageBoxInputData } from 'element-ui/types/message-box.d';
import { Prop, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import SchemaSelector from './schemas/SchemaSelector.vue';
import SchemaManager from './schemas/SchemaManager.vue';
import TextFieldHeader from './headers/TextFieldHeader.vue';
import DateFieldHeader from './headers/DateFieldHeader.vue';
import AccountFieldHeader from './headers/AccountFieldHeader.vue';
import SelectFieldHeader from './headers/SelectFieldHeader.vue';
import CompanyFieldHeader from './headers/CompanyFieldHeader.vue';
import LeadFieldHeader from './headers/LeadFieldHeader.vue';
import ContactFieldHeader from './headers/ContactFieldHeader.vue';
import BooleanFieldHeader from './headers/BooleanFieldHeader.vue';
import TDefaultCell from './cells/DefaultCell.vue';
import TTagsCell from './cells/TagsCell.vue';
import TDateTimeCell from './cells/DateTimeCell.vue';
import TBooleanCell from './cells/BooleanCell.vue';
import TMoneyCell from './cells/MoneyCell.vue';
import TNumericCell from './cells/NumericCell.vue';
import { Table } from 'element-ui';
import {
  IRepeaterColumn,
  IRepeaterIcon,
  IRowDataInfo,
  IRowDataInfoValue,
  IRepeaterSavedSearch,
  IRepeaterRequestStructure,
  IRepeaterResponseStructure,
  IRepeaterDeleteSelectedGroupInfoRequestStructure,
  IRepeaterUpdateSelectedGroupInfoRequestStructure,
  IRepeaterMultipleDataCommand,
  IRepeaterResponseDataStructure,
  IContextMenuOption,
} from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { DataSetExportType } from 'rt/Base/Report/DataSetExportType';
import { BaseRepeaterController } from 'rt/UIApiControllers/RepeaterControllers/BaseRepeaterController';
import { AdvancedSearchController } from 'rt/UIApiControllers/AdvancedSearch/AdvancedSearchController';
import { IBusinessObjectUpdateMessage } from 'rt/Base/Handlers/Comet/IBusinessObjectUpdateMessage';
import _ from 'lodash';
import difference from 'utils/difference';
import eventHubManger from 'modules/mixin/views/EventHub';
import { IRepeaterDownloadRequestStructure } from '@/plugins/typings/UIApiControllers/RepeaterControllers/RepeaterStructure/IRepeaterDownloadRequestStructure';

// tslint:disable-next-line:variable-name
const GridsGetter = namespace('grids').Getter;
// tslint:disable-next-line:variable-name
const GridsAction = namespace('grids').Action;
// tslint:disable-next-line:variable-name
const GridsMutation = namespace('grids').Mutation;

@Component({
  name: 'CrmGrid',
  components: {
    TDefaultCell,
    TDateTimeCell,
    TBooleanCell,
    TMoneyCell,
    TTagsCell,
    TNumericCell,
    SchemaManager,
    SchemaSelector,
  },
})
export default class CrmGrid extends Vue {
  @Getter baseUrl: string;

  @Getter user;

  @GridsGetter
  getState: (repeater: string) => IGridState;

  @GridsGetter emptyState: IGridState;

  @GridsAction
  setState: (s: { repeater: string; state: IGridState }) => void;

  @GridsGetter
  getStructure: (repeater: string) => IRepeaterResponseStructure;

  @GridsAction
  setStructure: (s: { repeater: string; structure: IRepeaterResponseStructure }) => void;

  @GridsGetter
  signalRUpdateMessage: any;

  @Watch('signalRUpdateMessage')
  async handleSignalRUpdateMessage(v: IBusinessObjectUpdateMessage) {
    if (this.businessObject !== null && this.businessObject === v.businessObject) {
      if (!this.active) {
        this.bgchanged = true;
        return;
      }
      const row = this.items.find((r) => r[this.selectionKey].value === v.id);
      if (row != null) {
        const request = this.repeaterRequest();
        const key = row[this.selectionKey].value;
        const newRow = (
          await this.baseRepeaterController.UpdateSelectedData({
            ...request,
            selectedIds: [key],
          })
        )[0];

        // findIndex a second time is to prevent concurrecies
        const idx = this.items.findIndex((r) => r[this.selectionKey].value === v.id);
        if (idx > -1) {
          if (newRow != null) {
            this.items = [...this.items.slice(0, idx), newRow, ...this.items.slice(idx + 1)];
          } else {
            this.items = [...this.items.slice(0, idx), ...this.items.slice(idx + 1)];
          }
        }
      } else {
        if (v.action === 0) {
          this.bgchanged = true;
        }
      }
    }
  }

  /*
    @Watch('customParameterData')
  handleCustomParameterDataChange(np, op) {
    if (_.isEqual(np, op)) return;
    this.updateData();
  }
    */
  get currentState(): IGridState {
    const state = this.saveInStore ? { ...this.getState(this.repeater) } : { ...this.emptyState };
    if (this.saveInQuery) {
      const query = this.$route.query;
      if (query.as && !isNaN(+query.as)) {
        state.advancedSearchId = +query.as;
      }
      if (query.ss && !isNaN(+query.ss)) {
        state.savedSearchId = +query.ss;
      }
      if (query.f && typeof query.f === 'string') {
        state.filters = JSON.parse(query.f as string);
      }
      if (query.ps && query.cp && !isNaN(+query.ps) && !isNaN(+query.cp)) {
        state.pagination = {
          pageSize: +query.ps,
          currentPage: +query.cp,
          total: 0,
        };
      }
      if (query.od) {
        state.orders = [
          {
            direction: 'descending',
            columnName: query.od,
          },
        ];
      }
      if (query.oa) {
        state.orders = [
          {
            direction: 'ascending',
            columnName: query.oa,
          },
        ];
      }
      if (this.customParameterDataTrackKeys) {
        let i = 0;
        for (const key of this.customParameterDataTrackKeys) {
          i += 1;
          if (Object.prototype.hasOwnProperty.call(query, `q${i}`) || Object.prototype.hasOwnProperty.call(query, `q${i}o`)) {
            if (!state.trackedCustomParameterData) {
              state.trackedCustomParameterData = {};
            }
            if (Object.prototype.hasOwnProperty.call(query, `q${i}`)) {
              state.trackedCustomParameterData[key] = query[`q${i}`];
            } else {
              state.trackedCustomParameterData[key] = JSON.parse(query[`q${i}o`] as string);
            }
          }
        }
      }
    }
    return state;
  }

  set currentState(s: IGridState) {
    if (this.saveInStore) {
      this.setState({ repeater: this.repeater, state: s });
    }
    if (this.saveInQuery) {
      const query: any = {
        ...this.$route.query,
      };
      delete query.f;
      if (s.filters && Object.keys(s.filters).length) {
        query.f = JSON.stringify(s.filters);
      }
      if (s.pagination) {
        query.ps = s.pagination.pageSize;
        query.cp = s.pagination.currentPage;
        // query.t = s.pagination.total;
      }
      delete query.as;
      if (s.advancedSearchId) {
        query.as = s.advancedSearchId;
      }
      delete query.ss;
      if (s.savedSearchId) {
        query.ss = s.savedSearchId;
      }
      delete query.od;
      delete query.oa;
      if (s.orders && s.orders.length) {
        const order = s.orders[0];
        if (order.direction === 'ascending') {
          query.oa = order.columnName;
        } else {
          query.od = order.columnName;
        }
      }
      if (s.trackedCustomParameterData) {
        if (this.customParameterDataTrackKeys) {
          let i = 0;
          for (const key of this.customParameterDataTrackKeys) {
            i += 1;
            delete query[`q${i}`];
            delete query[`q${i}o`];
            if (Object.prototype.hasOwnProperty.call(s.trackedCustomParameterData, key)) {
              if (s.trackedCustomParameterData[key]) {
                const data = s.trackedCustomParameterData[key];
                if (typeof data === 'object') {
                  query[`q${i}o`] = JSON.stringify(data);
                } else {
                  query[`q${i}`] = data;
                }
              }
            }
          }
        }
      }
      this.$router.replace({
        query,
      });
    }
  }

  buildTrackedCustomParameterData(s: any): any {
    if (!this.customParameterDataTrackKeys) return null;
    const r: any = {};
    for (const key of this.customParameterDataTrackKeys) {
      if (Object.prototype.hasOwnProperty.call(s, key)) {
        r[key] = s[key];
      } else {
        r[key] = null;
      }
    }
    return r;
  }

  advancedSearchController: AdvancedSearchController = new AdvancedSearchController(Vue.axios);

  @Prop({ required: true, type: String })
  repeater: string;

  @Prop({ default: null, type: [Object, Number] })
  customParameterData: any;

  @Prop({ default: null, type: Array })
  customParameterDataTrackKeys: string[];

  @Prop({ default: true, type: Boolean })
  showCommands: boolean;

  @Prop({ default: true, type: Boolean })
  store: boolean;

  @Prop({ required: false, type: Function })
  onUpdateData: (cpd?: any) => Promise<any>;

  defaultSort = null;

  unAuthorizedMessage: string = null;

  currentSchema: string = null;

  showKey = false;

  schemaManagerVisible = false;

  exportDialogVisible = false;

  exportAllColumns = false;

  DataSetExportType = DataSetExportType;

  exportFormat: DataSetExportType = DataSetExportType.ExcelXLSX;

  resetFactorySettingsDialogVisible = false;

  deleteDialogVisible = false;

  deleting = false;

  advancedSearchVisible = false;

  schemas: any[] = [];

  items: Array<IRowDataInfo> = [];

  columns: Array<IRepeaterColumn> = [];

  summaries: IRowDataInfo = null;

  selected: Array<any> = [];

  selectedAll = false;

  selectingAll = false;

  loading = true;

  input = '';

  @Prop({ default: true, type: Boolean })
  saveInStore: boolean;

  @Prop({ default: false, type: Boolean })
  saveInQuery: boolean;

  pagination: {
    pageSize: number;
    total: number;
    currentPage: number;
  } = {
      pageSize: 10,
      total: 0,
      currentPage: 1,
    };

  sortColumns: Array<{
    columnName: string;
    direction: 'ascending' | 'descending';
  }> = [];

  filter: any = {};

  allSavedSearches: Array<IRepeaterSavedSearch> = [];

  pagerSizes: number[] = [20, 40, 60, 80, 100];

  allowChangePagingSize = false;

  allowAdvancedSearch = false;

  allowExportData = false;

  advancedSearchReportSchema: string = null;

  advancedSearchId = 0;

  savedSearchId = 0;

  allowFilter = false;

  allowCustomizeColumn = false;

  allowPaging = false;

  allowSelect = false;

  businessObject: BusinessObjectType = null;

  @Prop({ default: true, type: Boolean })
  fixedColumnAllowed: boolean;

  allowSelectAll = false;

  allowDelete = false;

  noDataText: string = null;

  selectionKey: string = null;

  whatSearch: string = null;

  initialized = false;

  isMultipleUpdateDataCommands = false;

  multipleUpdateDataCommands: IRepeaterMultipleDataCommand[] = null;

  footerCommands: IContextMenuOption[] = null;

  @Prop({ default: () => [], type: Array })
  commandFilter: string[];

  @Prop({ default: null, type: Object })
  route: Location;

  @Prop({ default: false, type: Boolean })
  link: boolean;

  @Prop({ default: true, type: Boolean })
  loadDefaults: boolean;

  hasRowCount = true;

  advancedSearchConditions: any[] = [];

  cancellationTokenSource: CancelTokenSource;

  baseRepeaterController: BaseRepeaterController;

  active = true;

  bgchanged = false;

  get showSummary(): boolean {
    return this.summaries != null;
  }

  get footerCommandsFiltered(): IContextMenuOption[] {
    if (this.footerCommands) {
      return this.footerCommands.filter((f) => {
        if (f.webApiCommand) {
          return !this.commandFilter.includes(f.webApiCommand);
        }
        if (f.footerCommand) {
          return !this.commandFilter.includes(f.footerCommand);
        }
        return false;
      }); // .filter(f => this.commandFilter.includes(f.footerCommand));
    }
    return null;
  }

  created() {
    this.baseRepeaterController = new BaseRepeaterController(this.$http, this.repeater);
  }

  createRoute(id: number) {
    return {
      ...this.route,
      params: {
        ...this.route.params,
        id: this.$hashids.encode(id),
      },
    };
  }

  handleIconClick(icon: IRepeaterIcon, row: IRowDataInfoValue, key: any) {
    if (icon.voip) {
      if (this.businessObject != null && key) {
        if (row.value) {
          eventHubManger.getHub('phone-bar').$emit('dial', {
            number: row.value,
            id: key,
            type: this.businessObject,
          });
        }
      }
    }
  }

  async mounted() {
    this.cancellationTokenSource = axios.CancelToken.source();
    let cpd = this.customParameterData;
    if (this.currentState.trackedCustomParameterData) {
      cpd = {
        ...this.customParameterData,
        ...this.currentState.trackedCustomParameterData,
      };
      this.$emit('update:customParameterData', cpd);
    }

    this.filter = this.currentState.filters;
    this.sortColumns = this.currentState.orders;
    if (this.sortColumns.length > 0) {
      this.defaultSort = {
        prop: this.sortColumns[0].columnName,
        order: this.sortColumns[0].direction,
      };
    } else {
      this.defaultSort = {};
    }
    const pagination = this.currentState.pagination;
    if (Object.keys(pagination).length > 0) {
      this.pagination = { ...pagination };
    }
    if (this.currentState.advancedSearchId) {
      this.advancedSearchId = this.currentState.advancedSearchId;
    }
    if (this.currentState.savedSearchId) {
      this.savedSearchId = this.currentState.savedSearchId;
    }

    await this.getDataAndStructure(cpd);
    this.loading = false;

    this.$watch(
      'customParameterData',
      (np, op) => {
        if (_.isEqual(np, op)) return;
        this.advancedSearchId = 0;
        this.savedSearchId = 0;
        this.currentState = {
          ...this.currentState,
          trackedCustomParameterData: this.buildTrackedCustomParameterData(np),
          advancedSearchId: this.advancedSearchId,
          savedSearchId: this.savedSearchId,
        };
        this.updateData();
      },
      { deep: true },
    );
    if (this.saveInQuery) {
      this.$watch('$route', (np, op) => {
        const changes = difference(np.query, op.query);
        if (changes) {
          if (this.customParameterDataTrackKeys) {
            let changedParameterData = {};
            let i = 0;
            for (const key of this.customParameterDataTrackKeys) {
              i += 1;
              if (Object.prototype.hasOwnProperty.call(changes, `q${i}`)) {
                changedParameterData = {
                  ...changedParameterData,
                  [key]: changes[`q${i}`],
                };
              } else {
                if (Object.prototype.hasOwnProperty.call(changes, `q${i}o`)) {
                  changedParameterData = {
                    ...changedParameterData,
                    [key]: JSON.parse(changes[`q${i}o`]),
                  };
                }
              }
            }
            if (Object.keys(changedParameterData).length) {
              this.$emit('update:customParameterData', {
                ...this.customParameterData,
                ...changedParameterData,
              });
            }
          }
          if (Object.prototype.hasOwnProperty.call(changes, 'as')) {
            this.advancedSearchId = changes.as;
            this.updateData();
          }
        }
      });
    }
    // TODO: watch query string
    this.initialized = true;
  }

  activated() {
    this.active = true;
    if (this.initialized) {
      if (this.bgchanged) {
        this.updateData(null, true);
      }
    }
  }

  deactivated() {
    this.active = false;
  }

  linkClick(key: any, row: IRowDataInfoValue, column: IRepeaterColumn): void {
    this.$emit('linkClick', key, row, column);
  }

  formatter(row, column, value: IRowDataInfoValue) {
    return value.value;
  }

  async schemaChange(value) {
    this.currentSchema = value;
    this.loading = true;
    try {
      const request = this.repeaterRequest();
      const response = await this.baseRepeaterController.UpdateDataAndStructure(request, this.cancellationTokenSource.token);
      this.updateStructureFromResponse(response);
      if (this.store) {
        this.setStructure({
          repeater: this.repeater,
          structure: response,
        });
      }
      this.updateModelFromResponse(response);
    } finally {
      this.loading = false;
    }
  }

  async filterChange(filters) {
    Object.keys(filters).forEach((key) => {
      this.filter[key] = filters[key];
    });

    Object.keys(this.filter).forEach((key) => {
      if (this.filter[key] === null || this.filter[key].length === 0) {
        delete this.filter[key];
      }
    });
    this.currentState = {
      ...this.currentState,
      filters: { ...this.filter },
    };

    await this.updateData();
  }

  async sortChange(data: { prop: string; order: 'ascending' | 'descending' }) {
    if (data && data.prop && data.order) {
      this.sortColumns = [
        {
          columnName: data.prop,
          direction: data.order,
        },
      ];
    } else this.sortColumns = [];
    this.currentState = {
      ...this.currentState,
      orders: [...this.sortColumns],
    };
    await this.updateData();
  }

  async selectAll() {
    await this.handleSelectAll(true);
  }

  async handleSelectAll(select: boolean) {
    try {
      if (select) {
        this.selectingAll = true;
        const request = this.repeaterRequest();
        const response = await this.baseRepeaterController.SelectAll(request, this.cancellationTokenSource.token);
        this.selected = [...response];
        this.selectedAll = true;
      } else {
        this.selected = [];
        this.selectedAll = false;
      }
      this.$emit('selection-change', [...this.selected]);
    } finally {
      this.selectingAll = false;
    }
  }

  get isFiltered() {
    if (this.advancedSearchId > 0) return true;
    if (this.savedSearchId > 0) return true;
    if (this.filter && Object.keys(this.filter).length) return true;
    if (this.currentState.trackedCustomParameterData) {
      const tpd = this.buildTrackedCustomParameterData(this.customParameterData);
      if (
        Object.keys(tpd)
          .map((key) => tpd[key])
          .filter((c) => c)
          .map((v) => (typeof v === 'object' ? JSON.stringify(v) : v.toString()))
          .filter((c) => c.length).length
      ) {
        return true;
      }
    }

    return false;
  }

  get validColumns() {
    if (this.columns.length && this.items.length) {
      const fi = this.items[0];
      return this.columns.filter((c) => Object.prototype.hasOwnProperty.call(fi, c.columnName)).map((c) => ({ ...c, property: c.columnName }));
    }
    return this.columns.map((c) => ({ ...c, property: c.columnName }));
  }

  async handleSaveSearch() {
    try {
      const res = (await this.$prompt('', this.$t('grid.search.save') as string)) as MessageBoxInputData;
      if (res.action === 'confirm' && res.value) {
        const sres = await this.baseRepeaterController.SaveAdvancedSearch({
          savedSearchId: this.savedSearchId,
          cacheId: this.advancedSearchId,
          name: res.value,
        });
        if (sres) {
          this.savedSearchId = sres;
          this.advancedSearchId = 0;
          this.whatSearch = res.value;
          this.allSavedSearches.push({
            id: sres,
            name: res.value,
            isShared: false,
            isMyDefault: false,
            isFromSharing: false,
          });
        }
      }
    } catch (e) { }
  }

  async handleDeleteSearch() {
    if (this.savedSearchId > 0) {
      const res = await this.baseRepeaterController.DeleteSavedSearch(this.savedSearchId);
      if (res) {
        this.allSavedSearches = this.allSavedSearches.filter((s) => s.id !== this.savedSearchId);
        this.handleCleanSearch();
      }
    }
  }

  handleCleanSearch() {
    this.currentState = new GridState();
    this.filter = {};
    this.advancedSearchId = 0;
    this.advancedSearchConditions = [];
    this.savedSearchId = 0;
    if (this.currentState.trackedCustomParameterData) {
      const nupd = {
        ...this.customParameterData,
        ...this.buildTrackedCustomParameterData({}),
      };
      if (!_.isEqual(this.customParameterData, nupd)) {
        this.$emit('update:customParameterData', nupd);
      } else {
        this.updateData();
      }
    } else {
      this.updateData();
    }
    this.$emit('clean');
  }

  handleSelectionChange(val) {
    this.selected = val.map((v) => v[this.selectionKey].value);
    this.$emit('selection-change', [...this.selected]);
  }

  handleSelectChange(val, id) {
    if (val) {
      this.selected.push(id);
    } else {
      const idx = this.selected.findIndex((o) => o === id);
      if (idx < 0) {
        return;
      }
      this.selected.splice(idx, 1);
    }
    this.selectedAll = false;
    this.$emit('selection-change', [...this.selected]);
  }

  async changePage() {
    this.currentState = {
      ...this.currentState,
      pagination: { ...this.pagination },
    };
    await this.updateData();
  }

  async changePageSize(size: number) {
    await this.$http.post(`${this.repeater}/SavePagingSize`, size, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    await this.updateData();
  }

  async updateData(cpd?: any, silent = false) {
    if (!silent) {
      this.loading = true;
    }
    try {
      if (this.isMultipleUpdateDataCommands) {
        this.items = [];
        this.summaries = null;
        let rows = [];
        const promises = new Array<Promise<IRepeaterResponseDataStructure>>();
        for (const md of this.multipleUpdateDataCommands) {
          if (md.active) {
            const request = this.repeaterRequest(cpd);
            request.multipleCommand = md.name;
            promises.push(
              this.baseRepeaterController
                .UpdateData(request, this.cancellationTokenSource.token, {
                  timeout: 20000,
                })
                .then((r) => {
                  rows = [...rows, ...r.rows];
                  return r;
                }),
            );
          }
        }

        const mres = await Promise.all(promises);

        if (this.sortColumns && this.sortColumns.length) {
          const sc = this.sortColumns[0];
          const cn = sc.columnName.toUpperCase();
          this.items = rows.sort((left, right) => {
            if (!Object.prototype.hasOwnProperty.call(left, cn) || !Object.prototype.hasOwnProperty.call(right, cn)) return 0;
            if (left[cn].value === right[cn].value) return 0;
            if (sc.direction === 'ascending') {
              if (left[cn].value < right[cn].value) return -1;
              return 1;
            }
            if (left[cn].value < right[cn].value) return 1;
            return -1;
          });
        } else this.items = rows;

        this.pagination.pageSize = mres[0].pageSize;
        this.pagination.total = mres[0].totalRows;
        this.whatSearch = mres[0].whatSearch;
      } else {
        if (this.onUpdateData) {
          await this.onUpdateData(cpd);
        } else {
          const response = await this.baseRepeaterController.UpdateData(this.repeaterRequest(cpd), this.cancellationTokenSource.token, {
            timeout: 20000,
          });
          this.updateModelFromResponse(response);
        }
      }
      this.$emit('completed');
    } finally {
      this.loading = false;
    }
  }

  async getDataAndStructure(cpd: any) {
    try {
      let response = this.getStructure(this.repeater);
      if (response == null) {
        const request = this.repeaterRequest(cpd);
        const filters = Object.keys(this.filter).map((key) => ({
          columnName: key,
          value: this.filter[key][0].value,
          operator: this.filter[key][0].operator,
        }));

        if (filters.length > 0) {
          request.filters = filters;
        }

        response = await this.baseRepeaterController.GetDataAndStructure(request, this.cancellationTokenSource.token, {
          timeout: 20000,
        });
        this.updateStructureFromResponse(response);
        if (this.isMultipleUpdateDataCommands) await this.updateData();
        else this.updateModelFromResponse(response);
        if (this.store) {
          this.setStructure({
            repeater: this.repeater,
            structure: response,
          });
        }
      } else {
        this.updateStructureFromResponse(response);
        await this.updateData(cpd);
      }
    } catch (e) {
      if (e.status && e.status === 403 && e.data.message) {
        this.unAuthorizedMessage = e.data.message;
      }
      throw e;
    } finally {
    }
    this.$emit('completed');
  }

  updateStructureFromResponse(response: IRepeaterResponseStructure) {
    this.schemas = response.schemes;
    this.currentSchema = response.currentSchema;
    this.showKey = response.showKey;
    this.allSavedSearches = response.searchSaved;
    if (this.allSavedSearches.some((savedSearch) => savedSearch.id === response.savedSearchId)) {
      this.savedSearchId = response.savedSearchId;
    }
    this.businessObject = response.businessObject;
    // this.allowChangePagingSize = response.allowChangePageSize;
    this.allowChangePagingSize = false;
    this.allowSelect = response.allowSelect;
    this.allowSelectAll = response.allowSelectAll;
    this.allowDelete = response.allowDelete;
    this.noDataText = response.noDataResource;
    this.selectionKey = response.key;
    this.columns = response.columns;
    this.allowFilter = response.allowFilter;
    this.advancedSearchReportSchema = response.advancedSearchReportSchema;
    this.allowAdvancedSearch = response.allowAdvancedSearch;
    this.allowExportData = response.allowExportData;
    this.allowCustomizeColumn = response.allowCustomizeColumn;
    this.allowPaging = response.allowPaging;
    this.isMultipleUpdateDataCommands = response.isMultipleUpdateDataCommands;
    this.multipleUpdateDataCommands = response.multipleUpdateDataCommands;
    this.footerCommands = response.footerCommands;
    this.sortColumns = response.sortColumns.map((sc) => ({
      columnName: sc.columnName,
      direction: sc.direction === 0 ? 'ascending' : 'descending',
    }));
    // this.sortColumns = response.sortColumns;
  }

  updateModelFromResponse(response: IRepeaterResponseDataStructure) {
    this.items = response.rows;
    this.summaries = response.groupInfo;
    this.pagination.pageSize = response.pageSize;
    this.pagination.currentPage = response.page;
    if (response.totalRows === -1) {
      const endOfPaging = this.items.length < response.pageSize;
      if (endOfPaging) {
        this.pagination.total = response.pageSize * (response.page - 1) + this.items.length;
      } else {
        this.pagination.total = response.pageSize * (response.page + 1);
      }
      this.hasRowCount = false;
    } else {
      this.pagination.total = response.totalRows;
      this.hasRowCount = true;
    }

    this.bgchanged = false;
    this.whatSearch = response.whatSearch;
    // this.pagination.currentPage = response.page;
  }

  cellTemplate(column: IRepeaterColumn): string {
    switch (column.type) {
      case this.$enums.FieldTypes.Data:
      case this.$enums.FieldTypes.DataNoLTZ:
        return 't-date-time-cell';
      case this.$enums.FieldTypes.Boolean:
        return 't-boolean-cell';
      case this.$enums.FieldTypes.Money:
        return 't-money-cell';
      case this.$enums.FieldTypes.Numeric:
        return 't-numeric-cell';
      default:
        if (column.columnName === 'TAGS') {
          return 't-tags-cell';
        }
        return 't-default-cell';
    }
  }

  renderHeader(h, { column, $index, store }) {
    let headerComponent = TextFieldHeader;
    let index = $index;
    if (this.allowSelect && this.fixedColumnAllowed) {
      index -= 1;
    }
    if (this.user.isAdmin && this.showKey) {
      index -= 1;
    }
    const tustenaColumn = this.validColumns[index];
    if (!tustenaColumn) {
      return h();
    }
    switch (tustenaColumn?.type) {
      case this.$enums.FieldTypes.Text:
      case this.$enums.FieldTypes.Time:
        headerComponent = TextFieldHeader;
        break;
      case this.$enums.FieldTypes.Data:
      case this.$enums.FieldTypes.DataNoLTZ:
        headerComponent = DateFieldHeader;
        break;
      case this.$enums.FieldTypes.Account:
        headerComponent = AccountFieldHeader;
        break;
      case this.$enums.FieldTypes.Select:
      case this.$enums.FieldTypes.SelectResource:
      case this.$enums.FieldTypes.SelectExclusive:
      case this.$enums.FieldTypes.FreeSelect:
      case this.$enums.FieldTypes.FreeMultiSelect:
        headerComponent = SelectFieldHeader;
        break;
      case this.$enums.FieldTypes.Company:
        headerComponent = CompanyFieldHeader;
        break;
      case this.$enums.FieldTypes.Contact:
        headerComponent = ContactFieldHeader;
        break;
      case this.$enums.FieldTypes.Lead:
        headerComponent = LeadFieldHeader;
        break;
      case this.$enums.FieldTypes.Boolean:
        headerComponent = BooleanFieldHeader;
        break;
      // FreeSelect = 13,
      // FreeMultiSelect = 14,
      default:
        console.log(`Create header type for ${tustenaColumn.type}`);
    }
    return h(headerComponent, {
      props: {
        tableColumn: column,
        tableStore: store,
        sorting: this.sortColumns[0],
        column: tustenaColumn,
        allowFilter: tustenaColumn.allowFilter && this.allowFilter,
        filter: this.filter[tustenaColumn.columnName],
      },
      on: {
        'sort-change': this.sortChange,
      },
    });
  }

  async handleMoreCommand(command) {
    switch (command) {
      case 'schemaManager':
        this.schemaManagerVisible = true;
        return;
      case 'resetFactorySettings':
        this.resetFactorySettingsDialogVisible = true;
        return;
      case 'delete':
        if (this.selected.length > 0) {
          this.deleteDialogVisible = true;
        }
        return;
      case 'export':
        this.exportDialogVisible = true;
        return;
      default:
        const idx = +command;
        if (!isNaN(idx)) {
          const footerCommand = this.footerCommandsFiltered[idx];
          if (footerCommand) {
            await this.executeFooterCommand(footerCommand);
          }
        }
    }
  }

  async executeFooterCommand(footerCommand, selectedIds?: any[]) {
    if (footerCommand.footerCommand) {
      this.$emit(footerCommand.footerCommand, footerCommand);
    } else {
      try {
        this.loading = true;
        const baseRequest = this.repeaterRequest();
        const request = {
          ...baseRequest,
          selectedIds: selectedIds || this.selected,
        } as IRepeaterUpdateSelectedGroupInfoRequestStructure;
        const response = await Vue.axios.post(`${this.repeater}/${footerCommand.webApiCommand}`, request);
        if (response) {
          const result = response.data;
          if (result) {
            this.handleSelectionChange([]);
            this.updateData();
          }
          const redirect = response.request.getResponseHeader('FORCE_REDIRECT');
          if (redirect) {
            this.$router.push(redirect);
          }
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async exportData() {
    try {
      const baseRequest = this.repeaterRequest();
      const request = {
        ...baseRequest,
        selectedIds: this.selected,
        complete: this.exportAllColumns,
      } as IRepeaterDownloadRequestStructure;
      this.loading = true;
      this.exportDialogClose();
      const downloadUrl = await this.baseRepeaterController.DownloadData(request, this.exportFormat, this.cancellationTokenSource.token);
      if (downloadUrl != null) {
        this.$download(downloadUrl);
      }
    } finally {
      this.loading = false;
    }
  }

  async executeWebApiRequest(command: string) {
    const baseRequest = this.repeaterRequest();
    const request = {
      ...baseRequest,
      selectedIds: this.selected,
    } as IRepeaterUpdateSelectedGroupInfoRequestStructure;
    return (await Vue.axios.post(`${this.repeater}/${command}`, request)).data;
  }

  async handleDelete() {
    const baseRequest = this.repeaterRequest();
    const request = {
      ...baseRequest,
      deletedIds: this.selected,
    } as IRepeaterDeleteSelectedGroupInfoRequestStructure;
    this.deleting = true;
    const res = await this.baseRepeaterController.DeleteSelected(request, this.cancellationTokenSource.token);
    if (res) {
      this.handleSelectAll(false);
    }
    this.deleting = false;
    this.deleteDialogVisible = false;
    await this.updateData();
    this.input = '';
  }

  async handleResetFactorySettings(schema: boolean) {
    this.filter = {};
    this.sortColumns = [];
    this.pagination.currentPage = 0;
    this.currentState = new GridState();
    await this.baseRepeaterController.RestoreFactorySettings(schema, this.cancellationTokenSource.token);
    const request = this.repeaterRequest();
    request.loadDefaults = true;
    const response = await this.baseRepeaterController.UpdateDataAndStructure(request, this.cancellationTokenSource.token);

    this.resetFactorySettingsDialogVisible = false;
    this.updateStructureFromResponse(response);
    if (this.store) {
      this.setStructure({
        repeater: this.repeater,
        structure: response,
      });
    }
  }

  handleSchemaChanged(schema: string) {
    this.schemaChange(schema);
  }

  schemaManagerClose() {
    this.schemaManagerVisible = false;
  }

  exportDialogClose() {
    this.exportDialogVisible = false;
  }

  async headerDragend(newWidth, oldWidth, column, event) {
    await this.baseRepeaterController.SaveColumnWidth(column.columnKey, parseInt(newWidth, 10), this.cancellationTokenSource.token);
  }

  columnWidth(column) {
    if (column.autoWidth) {
      return null;
    }
    if (column.width) {
      return column.width;
    }
    switch (column.type) {
      case this.$enums.FieldTypes.Data:
      case this.$enums.FieldTypes.DataNoLTZ:
        if (column.dateFormat === 'L') {
          return 90;
        }
        return 140;
    }
    return 220;
  }

  get paginationLayout() {
    const layout = new Array<string>();
    // if (this.allowChangePagingSize) layout.push('sizes');
    layout.push('prev');
    if (this.hasRowCount) {
      layout.push('pager');
    }
    layout.push('next');
    return layout.join(', ');
  }

  getSummaries() {
    if (this.summaries !== null) {
      const summaries = [];

      if (this.allowSelect && this.fixedColumnAllowed) {
        summaries.push(null);
      }
      for (const col of this.columns) {
        if (Object.prototype.hasOwnProperty.call(this.summaries, col.columnName)) {
          const val = this.summaries[col.columnName].value;
          switch (col.type) {
            case this.$enums.FieldTypes.Money:
              summaries.push(this.$n(val, 'currency'));
              break;
            default:
              summaries.push(val);
              break;
          }
        } else {
          summaries.push(null);
        }
      }
      console.log(summaries);
      return summaries;
    }
    return null;
  }

  repeaterRequest(cpd?: any): IRepeaterRequestStructure {
    const filters = Object.keys(this.filter).map((key) => ({
      columnName: key,
      value: this.filter[key][0].value,
      operator: this.filter[key][0].operator,
    }));

    return {
      filters,
      showKey: this.showKey,
      page: this.pagination.currentPage,
      schemaName: this.currentSchema,
      sortColumns: this.sortColumns,
      customParameterData: JSON.stringify(cpd || this.customParameterData),
      advancedSearchId: this.advancedSearchId,
      savedSearchId: this.savedSearchId,
      groupByExpression: null,
      persistentPageGuid: null,
      multipleCommand: null,
      loadDefaults: this.loadDefaults,
    };
  }

  async savedSearchDefaultClick(item: IRepeaterSavedSearch) {
    if (item.isMyDefault) {
      this.allSavedSearches = await this.baseRepeaterController.RemovePinAsMyDefault(this.repeaterRequest(), item.id, this.cancellationTokenSource.token);
    } else {
      this.allSavedSearches = await this.baseRepeaterController.PinAsMyDefault(this.repeaterRequest(), item.id, this.cancellationTokenSource.token);
    }
  }

  async handleSearchCommand(command: string) {
    if (command === 'advancedSearch') {
      if (this.savedSearchId) {
        this.advancedSearchConditions = (await this.baseRepeaterController.LoadSavedSearchConditions(this.savedSearchId, this.cancellationTokenSource.token)) || [];
      } else {
        if (this.advancedSearchId) {
          this.advancedSearchConditions = (await this.baseRepeaterController.LoadAdvancedSearchConditions(this.advancedSearchId, this.cancellationTokenSource.token)) || [];
        }
      }
      this.advancedSearchVisible = true;
    } else {
      if (command.indexOf('executeSavedSearch-') === 0) {
        const idx = command.indexOf('-');
        const savedSearchId = +command.substring(idx + 1);
        if (savedSearchId) {
          this.savedSearchId = savedSearchId;
          this.advancedSearchId = 0;
          this.updateData();
          this.currentState = {
            ...this.currentState,
            advancedSearchId: this.advancedSearchId,
            savedSearchId: this.savedSearchId,
          };
        }
      }
    }
  }

  async executeAdvancedSearch() {
    this.savedSearchId = 0;
    this.advancedSearchId = await this.advancedSearchController.EnqueueConditions(this.advancedSearchReportSchema, this.advancedSearchConditions, this.advancedSearchId, this.cancellationTokenSource.token);
    this.advancedSearchVisible = false;
    this.updateData();
    this.currentState = {
      ...this.currentState,
      advancedSearchId: this.advancedSearchId,
      savedSearchId: this.savedSearchId,
    };
  }

  beforeDestroy() {
    this.cancellationTokenSource.cancel();
  }
}
