
import Vue from 'vue';
import ElementUI from 'element-ui';
import Component from 'vue-class-component';
import { Getter, Action, Mutation } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { AlarmController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { INotification } from '@/plugins/typings/Base/Handlers/Comet/INotification';

@Component({
  name: 'AlarmNotificationDialog',
})
export default class AlarmNotificationDialog extends Vue {
  alarmController = new AlarmController(Vue.axios);

  @Getter('notifications') notifications: INotification[];

  @Mutation('resetNotifications') resetNotifications;

  visible = false;

  @Watch('notifications') handleNotificationChange() {
    this.visible = this.notifications.length > 0;
    if (this.visible) {
      this.$beep(3);
    }
  }

  mounted() {
    this.visible = this.notifications.length > 0;
  }

  handleCloseNotificationDialog() {
    this.visible = false;
    this.resetNotifications();
  }

  async handlePostPonesNotifications(minutes) {
    await this.alarmController.PostPones(this.notifications.map((n) => ({ alarmId: n.alarmId, position: n.position })), minutes);
  }
}
