
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IRepeaterColumn, IRowDataInfoValue } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';

@Component({
  name: 'DefaultCell',
})
export default class DefaultCell extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  column: IRepeaterColumn;

  @Prop({
    type: Object,
    required: true,
  })
  row: IRowDataInfoValue;
}
