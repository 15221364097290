
import Vue from 'vue';
import {
 Action, Getter, Mutation, namespace,
} from 'vuex-class';
import axios, { AxiosResponse } from 'axios';
import { IAxiosRequestConfigRetry } from 'utils/types';
import Component from 'vue-class-component';
import { UnsubscribeController } from 'rt/UIApiControllers/UI/MailingList/UnsubscribeController';
import { IProfile } from 'rt/UIApiControllers/UI/MailingList/IProfile';
import { IMailingDeliveryDTO } from 'dto/IMailingDeliveryDTO';
import TListSubscribe from '../components/ListSubscribe.vue';

// tslint:disable-next-line:variable-name
const AnonymousMutation = namespace('anonymous').Mutation;

@Component({
  name: 'MailingUnsubscribe',
  components: {
    TListSubscribe,
  },
})
export default class MailingUnsubscribe extends Vue {
  unsubscribeController = new UnsubscribeController(Vue.axios);

  mailing: IProfile = null;

  updating = false;

  @AnonymousMutation setLogoUrl;

  async mounted() {
    const token = this.$route.params.id;
    this.$http.interceptors.request.use((r: IAxiosRequestConfigRetry) => {
      r.headers.Authorization = `Mailing ${token}`;
      r.headers['NullValueHandling'] = 'Include';
      return r;
    });
    try {
      this.mailing = await this.unsubscribeController.Get();
      this.setLogoUrl(this.mailing.photoUrl);
    } catch (e) {
      this.mailing = null;
      this.$router.push({
        name: this.$routeNames.mailingLandingExpired,
      });
    }
  }

  get removedLists() {
    if (!this.mailing) {
      return null;
    }
    const { lists } = this.mailing;
    const rights = this.mailing.rights.listRights;
    return rights.filter((l) => lists.some((r) => r.id === l.listId && l.removedRights));
  }

  handleReasonUpdate(id: number, reason: string) {
    const idx = this.mailing.rights.listRights.findIndex((l) => l.listId === id);
    if (idx >= 0) {
      const r = this.mailing.rights.listRights[idx];
      r.reason = reason;
    }
  }

  handleListSubscription(id: number, v: boolean) {
    const idx = this.mailing.rights.listRights.findIndex((l) => l.listId === id);
    if (v) {
      if (idx < 0) {
        this.mailing.rights.listRights.push({
          listId: 32,
          removedDate: new Date().toISOString(),
          removedRights: true,
          reason: '',
        });
      } else {
        const r = this.mailing.rights.listRights[idx];
        r.removedDate = null;
        r.removedRights = false;
        r.reason = null;
      }
    } else if (idx >= 0) {
        const r = this.mailing.rights.listRights[idx];
        r.removedDate = new Date().toISOString();
        r.removedRights = true;
      }
  }

  async updatePreferences() {
    try {
      this.updating = true;
      await this.unsubscribeController.UpdatePreferences(this.mailing.rights);
      this.$router.push({
        name: this.$routeNames.mailingLandingUnsubscribed,
      });
    } finally {
      this.updating = false;
    }
  }
}
