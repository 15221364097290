//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum GetNameFor {
	Company = 0,
	Contact = 1,
	Lead = 2,
	Account = 3,
	Catalog = 4,
	Opportunity = 5,
	Activity = 6,
	Zone = 7,
	CatalogCategory = 8,
	Campaign = 9,
	DDTPort = 10,
	DDTCause = 11,
	DDTAppearance = 12,
	Payment = 13,
	BankCode = 14,
	PriceList = 15,
	TaxCode = 16,
	MovementCauseCode = 17,
	CompanyFromContactId = 18,
	Database = 19,
	GroupName = 20,
	DataStorageFile = 21,
	Survey = 22,
	TicketArea = 23,
	TicketType = 24,
	Promotion = 25,
	List = 26,
	CompanyCategory = 27,
	ContactCategory = 28,
	Ticket = 29,
	TicketStatus = 30,
	TicketPriority = 31,
	OrderNumber = 32,
	InvoiceNumber = 33,
	QuoteNumber = 34,
	DDTNumber = 35,
	FileCategory = 36,
	WorkGroup = 37,
	Cati = 38,
	FileStatus = 39,
	CompanyType = 40,
	ContactType = 41,
	LeadStatus = 42,
	LeadRating = 43,
	LeadProductInterest = 44,
	Currency = 45,
	LeadSource = 46,
	ActivityType = 47,
	ActivityClassification = 48,
	ActivityState = 49,
	ActivityPriority = 50,
	ActivityToDo = 51,
	CatalogProductType = 52,
	UnitOfMeasure = 53,
	OpportunityStatus = 54,
	OpportunityPhase = 55,
	OpportunityCategory = 56,
	OpportunityLostReason = 57,
	QuoteStage = 58,
	ErpShippedBy = 59,
	ErpRowType = 60,
	PaymentName = 61,
	InvoiceType = 62,
	EMailMacroType = 63,
	EMailSubType = 64,
	DDTShippedBy = 65,
	LeadPriority = 66,
	RollingStock = 67,
	SalesTargetType = 68,
	SalesTargetReference = 69,
	TicketStatusType = 70,
	JobOrder = 71,
	JobOrderType = 72,
	JobOrderStatus = 73,
	JobOrderPriority = 74,
	JobOrderBillingType = 75,
	Contract = 76,
	ContractService = 77,
	Asset = 78,
	JobOrderTask = 79,
	WorkTeam = 80,
	JobOrderTaskStatus = 81,
	Calendar = 82,
	BankDescription = 83,
	JobOrderTaskSparePartType = 84,
	RollingStockType = 85,
	JobOrderTaskMode = 86,
	JobOrderBudgetType = 87,
	BusinessObjectType = 88,
	CallLogResult = 89,
	ApprovalConfiguration = 90,
	ApprovalStatus = 91,
	ApprovalRuleCalcType = 92,
	HelperClassType = 93,
	BusinessRole = 94,
	ErpRowTypeGiftMode = 95,
	TustenaCompany = 96,
	MessageChannel = 97,
	ActivitySubType = 98,
	Role = 99,
	CostType = 100,
	Month = 101,
	LanguageISO6391 = 102,
	DDT = 103,
	CustomErpDocumentNumber = 104,
	CustomErpDocumentType = 105,
	ContractTypeOfService = 106,
	PaymentType = 107,
	ExpenseVehicle = 108,
	ContractCategory = 109,
	ContractStatus = 110,
	PaymentMode = 111,
	ContractTypeOfPrice = 112,
	ContractKindOfPrice = 113,
	ContractTypeOfInstalment = 114,
	ContractServiceStatus = 115,
	ContractYearMode = 116,
	ExpenseReportStatus = 117,
	ExpenseVehicleType = 118,
	ExpenseItem = 119,
	ExpensePaymentMethod = 121,
	EventItemStatus = 122,
	EventLandings = 123,
	Event = 124,
	PhoneSubType = 125,
	PhoneMacroType = 126,
	Office = 127,
	PrivacyTypeManagement = 128,
	MailTemplate = 129,
	TaxInternalCode = 140,
	WorkPlan = 141,
	WorkPlanStatus = 142,
	FlowTrigger = 143,
	ReferrerCategory = 200,
	Appointment = 201,
	KnowledgeBaseCategory = 202,
	KnowledgeBase = 203,
	Report = 204
}
