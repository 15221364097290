
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
  Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import store from '@/store';
import eventHubManager from 'modules/mixin/views/EventHub';
import { PrivacyController } from 'rt/UIApiControllers/UI/CRM/PrivacyController';
import TVoispeedAdvertise from '../components/VoispeedAdvertise.vue';
import { IActivityDTO } from 'dto/IActivityDTO';
import { RouteConfig } from 'vue-router';
import moment from 'moment';

// tslint:disable-next-line:variable-name
const PhoneBarAction = namespace('phone-bar').Action;
// tslint:disable-next-line:variable-name
const PhoneBarGetter = namespace('phone-bar').Getter;
// tslint:disable-next-line:variable-name
const PhoneBarMutation = namespace('phone-bar').Mutation;
@Component({
  name: 'PhoneBar',
  components: {
    TVoispeedAdvertise,
  },
})
export default class PhoneBar extends Vue {
  privacyController = new PrivacyController(Vue.axios);

  @Getter('routes') routes: RouteConfig[];

  @Getter user: IUser;

  @PhoneBarMutation setImplicitConsent: (payload) => void;

  @PhoneBarMutation selectIncoming: (m) => void;

  @PhoneBarMutation selectAnagraphic: (m) => void;

  @PhoneBarAction dial: (payload) => Promise<any>;

  @PhoneBarAction drop: () => Promise<any>;

  @PhoneBarAction accept: () => Promise<any>;

  @PhoneBarGetter callKey: string;

  @PhoneBarGetter number: string;

  @PhoneBarGetter crossType: number;

  @PhoneBarGetter crossId: number;

  @PhoneBarGetter startDate: Date;

  @PhoneBarGetter implicitConsent: any;

  @PhoneBarGetter minimized: any;

  @PhoneBarMutation setMinimized: (minimized: boolean) => void;

  @PhoneBarGetter calling: boolean;

  @PhoneBarGetter matches: [{ type: number; id: number; name: string; selected: boolean }];

  @PhoneBarGetter direction: 'outbound' | 'inbound';

  // @PhoneBarMutation onRegisterActivity: () => void;
  @PhoneBarGetter incoming: {
    callId: string;
    name: string;
    number: string;
    matches: [{ type: number; id: number; name: string; selected: boolean }];
  };

  created() {
    eventHubManager.getHub('phone-bar').$on('dial', this.handleDialClick);
    eventHubManager.getHub('phone-bar').$on('registerActivity', (a) => this.handleRegisterActivity(a));
  }

  beforeDestroy() {
    eventHubManager.getHub('phone-bar').$off('dial');
    eventHubManager.getHub('phone-bar').$off('registerActivity');
    if (this.durationInterval) {
      window.clearInterval(this.durationInterval);
    }
  }

  duration: string = null;

  durationInterval: number = null;

  implicitDialogVisible = false;

  implicitModel: { motivationImplicitConsentId: number; notes: string } = { motivationImplicitConsentId: null, notes: null };

  implicitAwaiterResolver: () => void = null;

  implicitAwaiterRejecter: () => void = null;

  get maximized() {
    return !this.minimized;
  }

  get phoneBarVisible(): boolean {
    return this.calling || this.incoming != null;
  }

  @Watch('phoneBarVisible') handlePhoneBarVisible(v: boolean) {
    if (v) {
      this.durationInterval = window.setInterval(this.setDuration, 1000);
    } else {
      if (this.durationInterval) {
        this.duration = null;
        window.clearInterval(this.durationInterval);
      }
    }
  }

  toggleMinimized(v) {
    this.setMinimized(!this.minimized);
  }

  onBeforeCloseDialog(done) {
    done();
    this.implicitAwaiterRejecter();
  }

  handleCancelImplicit() {
    this.implicitDialogVisible = false;
    this.implicitAwaiterRejecter();
  }

  handleOkImplicit() {
    this.implicitDialogVisible = false;
    this.implicitAwaiterResolver();
  }

  setDuration() {
    const startDate = this.startDate;
    if (startDate) {
      const duration = moment.duration(this.$moment(new Date()).diff(startDate));
      this.duration = duration.format('h [hour] :mm [min] :ss [sec]');
    } else {
      this.duration = null;
    }
  }

  async handleDialClick(payload: { type: number; id: number; number: string }) {
    if (payload.number === this.number) {
      return;
    }
    if (this.calling) {
      const res = await this.$confirm(this.$t('phoneBar.conflict.description', { number: this.number }) as string, this.$t('phoneBar.conflict.title') as string, {
        // type: 'success',
        confirmButtonText: this.$t('phoneBar.conflict.close') as string,
        cancelButtonText: this.$t('commands.cancel') as string,
      })
        .then((value) => true)
        .catch(() => false);
      if (res) {
        await this.closeRegisterActivity();
      }
      return;
    }

    const res = await this.privacyController.ExistsPermitToContact({
      crossId: payload.id,
      crossType: payload.type,
    });
    if (!res.allow) {
      this.implicitModel = { motivationImplicitConsentId: null, notes: null };
      this.implicitDialogVisible = true;
      try {
        await new Promise<void>((resolve, reject) => {
          this.implicitAwaiterResolver = resolve;
          this.implicitAwaiterRejecter = reject;
        });
      } catch {
        return false;
      }
      this.setImplicitConsent(this.implicitModel);
    } else {
      this.setImplicitConsent(null);
    }
    this.dial({
      ...payload,
      matches: [{ type: payload.type, id: payload.id, selected: true }],
    });
  }

  async dropCall() {
    await this.drop();
  }

  async acceptCall() {
    await this.accept();
  }

  async closeRegisterActivity() {
    await this.handleRegisterActivity(this.createDefaultCallActivity());
    await this.drop();
  }

  async handleRegisterActivity(activity: Partial<IActivityDTO>) {
    // this.onRegisterActivity();
    this.$root.$emit('activity', {
      activity: activity ?? this.createDefaultCallActivity(),
      filter: [this.$enums.ActivityType.PhoneCall],
      title: this.$t('phoneBar.activity.title', { number: this.number, duration: this.duration }) as string,
    });
  }

  createDefaultCallActivity() {
    const endDate = this.$moment(new Date());
    const duration = moment.duration(endDate.diff(this.startDate));
    return {
      companyId: this.crossType === this.$enums.BusinessObjectType.Company ? this.crossId : 0,
      contactId: this.crossType === this.$enums.BusinessObjectType.Contact ? this.crossId : 0,
      leadId: this.crossType === this.$enums.BusinessObjectType.Lead ? this.crossId : 0,
      activityDate: this.startDate.toISOString(),
      activityEndDate: endDate.toISOString(),
      duration: Math.round(duration.as('minutes')),
      implicitConsent: this.implicitConsent,
      subject: this.$t(`phoneBar.activity.subjects.${this.direction}`, { number: this.number }) as string,
      inOut: this.direction === 'inbound',
    };
  }

  async selectIncomingAnagraphicAndRedirect(m) {
    this.selectIncoming(m);
    this.$router.push(this.$routeHelper.findRouteFromBusinessObjectType(this.routes, m.type, m.id));
  }

  async selectAnagraphicAndRedirect(m) {
    this.selectAnagraphic(m);
    this.$router.push(this.$routeHelper.findRouteFromBusinessObjectType(this.routes, m.type, m.id));
  }
}
