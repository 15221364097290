
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import {
 Getter, Action, Mutation, namespace,
} from 'vuex-class';
import { ICompanyDTO } from 'dto/ICompanyDTO';
import { IContactDTO } from 'dto/IContactDTO';
import { ILeadDTO } from 'dto/ILeadDTO';
import { SimilarityController } from 'rt/UIApiControllers/UI/Smart/SimilarityController';
import { ISimilarityRequest } from 'rt/UIApiControllers/UI/Smart/ISimilarityRequest';
import { ISimilarityResponse } from 'rt/UIApiControllers/UI/Smart/ISimilarityResponse';
import routeHelper from 'utils/routeHelper';
import { RouteConfig } from 'vue-router';
import _ from 'lodash';

@Component({
  name: 'SimilarityItem',
})
export default class SimilarityItem extends Vue {
  @Getter('routes') routes: RouteConfig[];

  @Prop({
    required: true,
    type: Object,
  })
  value: ISimilarityResponse;

  get route() {
    return routeHelper.findRouteFromBusinessObjectType(this.routes, this.value.crossType, this.value.crossId);
  }
}
