//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SendEmailToType {
	EmailAddress = 0,
	ElementOfTheTicket = 1,
	TicketOwner = 2,
	SlaOwner = 3
}
