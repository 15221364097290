//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivityType {
	PhoneCall = 1,
	Letter = 2,
	Fax = 3,
	Memo = 4,
	Email = 5,
	Visit = 6,
	Generic = 7,
	CaseSolution = 8,
	Sms = 20,
	Technical = 32
}
