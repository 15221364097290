
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Inject, Prop } from 'vue-property-decorator';
import ElementUI from 'element-ui';
import FormMixin from './mixin';
import { ContactController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import _ from 'lodash';

@Component({
  name: 'TernaryAnagraphicInput',
})
export default class TernaryAnagraphicInput extends FormMixin {
  contactController = new ContactController(Vue.axios);

  resolveController = new ResolveController(Vue.axios);

  @Inject('elForm') elForm: ElementUI.Form;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  selectedData: any = [];

  async resolve(id: number, type: BusinessObjectType): Promise<string> {
    return (await this.resolveController.Get(id, type)).name;
  }

  get value(): any[] {
    const data = [];
    if (this.data[this.schema.company.propertyName]) {
      data.push({
        id: this.data[this.schema.company.propertyName],
        type: BusinessObjectType.Company,
      });
    }
    if (this.data[this.schema.contact.propertyName]) {
      data.push({
        id: this.data[this.schema.contact.propertyName],
        type: BusinessObjectType.Contact,
      });
    }
    if (this.data[this.schema.lead.propertyName]) {
      data.push({
        id: this.data[this.schema.lead.propertyName],
        type: BusinessObjectType.Lead,
      });
    }
    return data;
  }

  change(value) {
    if (this.elForm && this.schema) {
      this.elForm.clearValidate(this.schema.validationPropertyName);
    }
    if (value.length > 0) {
      this.simpleEmit({
        ...this.data,
        [this.schema.company.propertyName]: this.getIdFromString(value, BusinessObjectType.Company),
        [this.schema.contact.propertyName]: this.getIdFromString(value, BusinessObjectType.Contact),
        [this.schema.lead.propertyName]: this.getIdFromString(value, BusinessObjectType.Lead),
      });
    } else {
      this.simpleEmit({
        ...this.data,
        [this.schema.company.propertyName]: 0,
        [this.schema.contact.propertyName]: 0,
        [this.schema.lead.propertyName]: 0,
      });
    }
  }

  @Watch('value')
  async handleValueChange(newVal: { id: number; type: BusinessObjectType }[], oldVal: { id: number; type: BusinessObjectType }[]) {
    if (_.isEqual(newVal, oldVal)) {
      return;
    }
    const selectedData = [];
    for (const nv of newVal) {
      const identifier = BusinessObjectType[nv.type];
      if (nv.id) {
        const sd = {
          key: `${identifier}-${nv.id}`,
          title: await this.resolve(nv.id, nv.type),
        };
        selectedData.push(sd);
      }
    }
    this.selectedData = selectedData;
  }

  getIdFromString(items: { key: string }[], type: BusinessObjectType): number {
    const item = items
      .slice()
      .reverse()
      .find((i) => BusinessObjectType[this.getIdentifierFromString(i.key)] === type);
    if (item) {
      const result = item.key.split('-')[1];
      return parseInt(result, 10);
    }
    return 0;
  }

  getIdentifierFromString(idString: string): string {
    return idString.split('-')[0];
  }

  async mounted() {
    if (this.value && this.value.length) {
      const sd = [];
      for (const m of this.value) {
        sd.push({
          key: `${BusinessObjectType[m.type]}-${m.id}`,
          title: await this.resolve(m.id, m.type),
        });
      }
      this.$nextTick(() => {
        this.selectedData = sd;
      });
    } else {
      this.selectedData = [];
    }

    this.$watch(`data.${this.schema.company.propertyName}`, (newVal, oldVal) => {
      if (newVal > 0 && newVal !== oldVal) {
        this.simpleEmit({
          ...this.data,
          [this.schema.lead.propertyName]: 0,
        });
      }
    });

    this.$watch(`data.${this.schema.contact.propertyName}`, async (newVal, oldVal): Promise<void> => {
      if (newVal > 0 && newVal !== oldVal) {
        this.simpleEmit({
          ...this.data,
          [this.schema.lead.propertyName]: 0,
        });
        if (this.data.companyId === 0) {
          const cnt = await this.contactController.Get(newVal);
          if (cnt && cnt.companyId > 0) {
            this.simpleEmit({
              ...this.data,
              [this.schema.company.propertyName]: cnt.companyId,
            });
          }
        }
      }
    });

    this.$watch(`data.${this.schema.lead.propertyName}`, (newVal, oldVal) => {
      if (newVal > 0 && newVal !== oldVal) {
        this.simpleEmit({
          ...this.data,
          [this.schema.company.propertyName]: 0,
          [this.schema.contact.propertyName]: 0,
        });
      }
    });
  }

  removeItem() {
    this.change([]);
  }
}
