
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseTree from './BaseTree';
import { ZoneTreeViewController } from 'rt/UIApiControllers/TreeView/ZoneTreeViewController';

@Component({
  name: 'ZoneTree',
})
export default class ZoneTree extends BaseTree {
  zoneTreeViewController = new ZoneTreeViewController(Vue.axios);

  getTreeViewController(): any {
    return this.zoneTreeViewController;
  }

  isLeaf(item): boolean {
    return item.children.length === 0;
  }
}
