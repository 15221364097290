
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'Input',
  mixins: [simpleMixin],
})
export default class Input extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  handleChange(v: string) {
    this.$emit('change', {
      ...this.data,
      [this.schema.propertyName]: v,
    });
  }
}
