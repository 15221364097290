
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'DetailPage',
})
export default class DetailPage extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  aside: boolean;

  @Prop({
    type: Object,
    required: false,
  })
  model: any;

  @Prop({
    type: Boolean,
    default: true,
  })
  header: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  tab: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  content: boolean;

  sideVisible = false;

  @Prop({
    type: Number,
    default: 992,
  })
  limit: number;

  @Prop({
    type: Boolean,
    default: true,
  })
  loading: boolean;

  created() {
    this.$root.$on('openHeaderMenu', (v) => {
      this.sideVisible = v;
    });
  }

  beforeDestroy() {
    this.$root.$off('openHeaderMenu');
  }

  get isSideVisible() {
    if (this.$viewport.width >= this.limit) return true;
    return this.sideVisible;
  }

  get isFloating() {
    return this.$viewport.width < this.limit;
  }
}
