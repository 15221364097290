//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CRMTables {
	None = 0,
	Base_Companies = 1,
	Base_Contacts = 2,
	CRM_Leads = 3,
	CRM_WorkActivity = 4,
	Ticket_Main = 5,
	Warehouse = 6,
	CatalogProducts = 7,
	NoTableError = 8,
	DDT = 9,
	Invoices = 10,
	Orders = 11,
	Quotes = 12,
	RollingStock = 13,
	CatalogMovements = 14,
	CustomTable = 15,
	Campaign = 16,
	ServiceRequest = 17,
	TustenaDB = 18,
	Opportunity = 19,
	CRM_Addresses = 20,
	CatalogProductPrice = 21,
	FileManager = 22,
	PriceList = 23,
	ContractService = 24,
	Project = 25,
	Promotion = 26,
	DebtRecovery = 27,
	JobOrders = 28,
	JobOrder_Tasks = 29,
	Relations = 30,
	InvoicePayment = 31,
	AddedFields = 32,
	CatalogProductsLink = 33,
	GeoCoding = 34,
	OutInvoiceExpire = 35,
	OutInvoiceRows = 36,
	BookMark = 37,
	AddressNormalize = 38,
	InvoiceInstallment = 39,
	RollingStock_Invoice = 40,
	FileCrossTables = 41,
	Ticket_Template = 42,
	Ticket_Attach = 43,
	ErpCrossRows = 44,
	ContactInvoiceRows = 45,
	ContractJobOrderTaskPlanned = 46,
	ContractSections = 47,
	ContractTypeOfService = 48,
	UnitOfMeasure = 49,
	ActivityCross = 50,
	DebtRecoveryInvoices = 51,
	EMailNormalize = 52,
	AddedFields_Tree = 54,
	Sharing = 55,
	Flow_Running = 56,
	JobOrder_Resource = 57,
	Asset_Lock = 58,
	JobOrder_Task_Resource = 59,
	ContractTemplate = 60,
	CRM_Reminder = 62,
	TaxValues = 63,
	InvoiceBank = 64,
	ServiceReplacement = 65,
	MovementsCode = 66,
	ContractInvoiceRows = 67,
	PaymentList = 68,
	Ticket_SupportLog = 69,
	Survey = 70,
	Base_Calendar = 71,
	OutPayment = 72,
	KBQuestion = 73,
	Account = 74,
	CampaignOperatorsCrossElements = 75,
	Portal = 76,
	CatalogTypeOfService = 77,
	ContractPlannedRequest = 78,
	TargetList = 79,
	WorkRange = 80,
	SLA_Template = 81,
	ContractRenewals = 82,
	ContractRid = 83,
	PaymentMovements = 84,
	WebMail = 85,
	CampaignCrossDb = 86,
	CallLog = 87,
	RollingStock_Address = 88,
	RollingStock_Movements = 89,
	WorkTeams = 91,
	TeamMembers = 92,
	Groups = 94,
	CatalogCategories = 95,
	Flow_Template = 96,
	CatalogoAlternativeUnit = 97,
	DDTPorto = 99,
	DDTAppearance = 100,
	DDTCause = 101,
	CatalogPriceListDescription = 102,
	Tustena_Companies = 103,
	CurrencyTable = 104,
	FilesCategories = 105,
	JobOrder_Type = 106,
	JobOrder_Status = 107,
	JobOrder_Priority = 108,
	JobOrder_Task_Status = 109,
	Assets = 110,
	Asset_Category = 111,
	JobOrder_Task_SpareParts = 112,
	Zones = 113,
	SalesPerformanceConsole = 114,
	WorkGroup = 115,
	JobOrder_Budget = 116,
	ActivityCrossBands = 117,
	JobOrder_ConsumptionConfig = 118,
	Alarms = 119,
	Alarms_Notification = 120,
	JobOrder_Billing = 121,
	OpportunityCrossContact = 122,
	File_Dossier = 123,
	PredefinedTitles = 124,
	Calendars = 125,
	JobOrder_Template = 126,
	KbCrossProduct = 127,
	Kb_RelatedTopics = 128,
	Kb_AttachedDocs = 129,
	Kb_ExternalLinks = 130,
	KbCrossTitles = 131,
	Kb_Comments = 132,
	FileManagerStatus = 133,
	Cati = 134,
	CatiQuestions = 135,
	CatiAnswers = 136,
	CatiQuota = 137,
	CatiQuotaUpdate = 138,
	Cati_Permits = 139,
	Sla_CrossElement = 140,
	Sla_TemplateEvents = 141,
	Sla_Action = 142,
	Sla_Action_Conditions = 143,
	CampaignUsers = 144,
	CampaignStatus = 145,
	ContractServiceConsumptionConfig = 146,
	CatalogCallPlanned = 147,
	DebtLog = 148,
	Recurrence = 149,
	MailEvents = 150,
	File_Dossier_Slot = 151,
	Sharing_Template = 152,
	ActivityCost = 153,
	QuoteRows_Extension_Part = 154,
	ERPRow_Grouping = 155,
	ERPRow_Grouping_Extension_Part = 156,
	Approval_Configuration = 157,
	Approval_Pending = 158,
	OpportunityProducts = 159,
	Localizing = 160,
	Opportunity_Phase = 161,
	CRM_BusinessRoles = 162,
	CRM_BusinessRole_Cross = 163,
	Marketing_Campaigns = 164,
	Marketing_Campaign_Products = 165,
	ErpRowsAddedFields = 166,
	QuoteRows = 167,
	OrderRows = 168,
	InvoiceRows = 169,
	DDTRows = 170,
	ActivityPrice = 171,
	Ticket_Grabber_Log = 172,
	Ticket_Grabber = 173,
	CatalogVariant = 174,
	CatalogVariant_Grouping = 175,
	CatalogVariant_Grouping_Config = 176,
	CatalogVariant_Product = 177,
	CatalogVariant_Option = 178,
	Base_Notes = 179,
	Messages = 181,
	Message_Channels = 182,
	Message_Attach = 183,
	CheckListValues = 184,
	ML_Mail = 185,
	TustenaReports = 186,
	Activity_SubType = 187,
	Skills = 188,
	SkillCategories = 189,
	SkillValueTypes = 190,
	Account_Skills = 191,
	Roles = 192,
	ExpenseReport = 193,
	ExpenseReportList = 194,
	CustomerInstallationsBase = 195,
	CustomerInstallations = 196,
	CustomerConnections = 197,
	CustomerHardwares = 198,
	ML_Categories = 199,
	CustomErpDocuments = 200,
	CustomErpDocumentType = 201,
	CustomErpDocumentRows = 202,
	Dynamo_Repeater = 203,
	Dynamo_Repeater_Column = 204,
	Dynamo_Page = 205,
	Dynamo_Input = 206,
	Dynamo_Select = 207,
	Dynamo_Chart = 208,
	Dynamo_Content = 209,
	Exchange_Company_Conf = 210,
	Exchange_Account_Conf = 211,
	GoogleApiSettings = 212,
	AddedFields_Items_Localizing = 213,
	CrossExternalReference = 214,
	ContractCrossRolling = 215,
	Relations_Type = 216,
	ContractTemplateTypeOfService = 217,
	ContractTemplateCallPlanned = 218,
	ExpenseItems = 219,
	ExpensePaymentMethods = 220,
	ExpenseVehicles = 221,
	TustenaDB_Estruct_Localizing = 222,
	UsersListsFields_Localizing = 223,
	TustenaDBEstruct = 224,
	TustenaDBData = 225,
	Event_Landings = 226,
	Events = 227,
	Event_Item_Status = 228,
	Event_Items = 229,
	AbsenceTypes = 230,
	Ticket_Area = 231,
	Ticket_AreaWorkgroup = 232,
	Ticket_Type = 233,
	Ticket_Status = 234,
	Ticket_Priority = 235,
	Opportunity_Category = 236,
	Opportunity_LostReason = 237,
	EMailType = 238,
	Crm_Status = 239,
	Crm_Rating = 240,
	Crm_Interest = 241,
	Crm_Source = 242,
	Crm_Priority = 243,
	Crm_Industry = 244,
	Crm_CompanyType = 245,
	Base_Contacts_OtherCompanies = 246,
	PhoneNormalize = 247,
	PhoneType = 248,
	Offices = 249,
	Qb_Categories = 250,
	RoboSay = 251,
	QualityIndicator = 252,
	Base_Events = 253,
	TemplateSms = 254,
	MultiTierAccount = 255,
	ML_RemovedFrom = 256,
	PrivacyTypeManagement = 257,
	PrivacyManagement = 258,
	PrivacyMotivationsImplicitConsent = 259,
	PrivacyActivityMotivationImplicitConsent = 260,
	Hooks = 261,
	Flow_Trigger = 262,
	Print_Permissions = 263,
	Tags_Refs = 264,
	Tags = 265,
	Signatures = 266,
	MLSendLog = 267,
	Crm_ContactCategories = 268,
	Crm_ReferrerCategories = 269,
	ML_Domains = 270,
	MLSentMail = 271,
	Hooks_Pushes = 272,
	WebApi_Keys = 273,
	WebApi_Keys_Domains = 274,
	PaymentMode = 275,
	Base_Companies_SalesTerms = 276,
	Base_Companies_SalesTerms_Partner = 277,
	QuoteNumbers = 279,
	CrossCategory = 280,
	CompanyMenu = 281,
	Calendar_Bookings = 282,
	FileSharing = 283,
	WorkPlans = 284,
	WorkPlan_Members = 285,
	WorkPlan_Status = 286,
	WorkPlan_Status_Activities = 287,
	RollingCounterType = 400,
	RollingStock_Category = 401,
	JobOrderTypeOfService = 402,
	ActivityCrossRolling = 403,
	CrossSpareParts = 404,
	CrossSpareParts_List = 405,
	CrossJobOrderTaskPlanned = 406,
	RollingCounterCross = 407,
	PaymentReason = 408,
	DefaultCodeValue = 409,
	CollectionCodes = 410,
	FileSharingConfiguration = 411,
	AgyoPrivacyConfiguration = 412,
	ContractCategories = 413,
	QuoteShipment = 414,
	Crm_CompetitorProducts = 415,
	UnifiedMessageAuth = 416,
	SmsNames = 417,
	FaxNames = 418,
	ContractStatus = 419,
	RollingCounter = 421,
	PortalLink = 422,
	KbCategory = 423,
	PaymentListCross = 424,
	ContractJobOrderTaskPlanned_Activity = 425,
	FavoriteEntities = 450,
	Recent = 451,
	String_Templates = 452,
	Rolling_Stock_In_Contract = 453,
	Rolling_Stock_To_Service_In_Contract = 454,
	CatalogProducts_SpareParts = 455,
	CatalogProducts_SpareParts_Activities = 456,
	Joborder_Tasks_Config = 457,
	Joborder_Tasks_Config_Users = 458,
	Portal_Tokens = 2000,
	Portal_Theme = 2001,
	Portal_NewsHomePage = 2002
}
