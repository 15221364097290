
import Vue from 'vue';
import {
 Action, Getter, Mutation, namespace,
} from 'vuex-class';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IListDTO } from 'dto/IListDTO';
import { IListMailingListRightsDTO } from 'dto/IListMailingListRightsDTO';

@Component({
  name: 'ListSubscribe',
})
export default class ListSubscribe extends Vue {
  @Prop({ type: Boolean, default: false })
  abuse: boolean;

  @Prop({ required: true, type: Object })
  list: IListDTO;

  @Prop({ required: true, type: Boolean })
  value: boolean;

  @Prop({ required: false, type: String })
  reason: string;

  wasSubscribed = false;

  async mounted() {
    this.wasSubscribed = this.value;
  }
}
