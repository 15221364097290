
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'OpportunityLookup',
  components: {
    TLookupSkin,
  },
})
export default class OpportunityLookup extends BaseLookup {
  dialogVisible = false;

  suggestionControllerName() {
    return 'Opportunity';
  }

  keyPrefix() {
    return 'Opportunity';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  get enableCreate(): boolean {
    return this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Opportunity);
  }

  handleSelect(val) {
    if (val.key === null) {
      this.$root.$emit('opportunity', {
        name: val.value,
        defaults: {
          title: val.value,
          companyName: val.value,
          ...this.getAnagraphicReference(),
        },
        callback: async (id: number) => {
          if (id > 0) {
            const title = await this.resolve(id, this.$enums.BusinessObjectType.Opportunity);
            this.addItem({
              title,
              key: `${this.keyPrefix()}-${id}`,
            });
          }
        },
      });
    } else {
      this.addItem({
        key: val.key,
        title: val.value,
      });
    }
  }

  getAnagraphicReference() {
    if (this.suggestionParams) {
      if (this.suggestionParams.crossId > 0) {
        return {
          crossId: this.suggestionParams.crossId,
          crossType: this.suggestionParams.crossType,
        };
      }
      if (this.suggestionParams.companyId > 0) {
        return {
          crossId: this.suggestionParams.companyId,
          crossType: this.$enums.BusinessObjectType.Company,
        };
      }
      if (this.suggestionParams.contactId > 0) {
        return {
          crossId: this.suggestionParams.contactId,
          crossType: this.$enums.BusinessObjectType.Contact,
        };
      }
      if (this.suggestionParams.leadId > 0) {
        return {
          crossId: this.suggestionParams.leadId,
          crossType: this.$enums.BusinessObjectType.Lead,
        };
      }
    }
    return {};
  }

  linkClick(opportunityId, row) {
    const item = {
      key: `${this.keyPrefix()}-${opportunityId}`,
      title: row.TITLE.value,
    };

    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }

  tagTypeOnStatus(type) {
    switch (type) {
      case this.$enums.OpportunityStatus.CloseWin:
        return 'success';
      case this.$enums.OpportunityStatus.CloseLost:
        return 'danger';
      case this.$enums.OpportunityStatus.Suspended:
      case this.$enums.OpportunityStatus.NotPursued:
        return 'warning';
      case this.$enums.OpportunityStatus.Open:
        return 'primary';
    }
    return 'info';
  }
}
