
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';

@Component({
  name: 'BoResolveLabel',
})
export default class BoResolveLabel extends Vue {
  resolveController = new ResolveController(Vue.axios);

  @Watch('value')
  async handleValueChange() {
    await this.updateText();
  }

  @Watch('type')
  async handleTypeChange() {
    await this.updateText();
  }

  @Prop({
    type: Number,
    required: true,
  })
  value: number | string;

  @Prop({
    type: Number,
    required: true,
  })
  type: BusinessObjectType;

  @Prop({
    type: String,
    required: false,
  })
  fallback: string;

  text: string = null;

  async mounted() {
    await this.updateText();
  }

  async updateText() {
    if (this.value) {
      const resolverFunction = this.resolveController[BusinessObjectType[this.type]];
      if (resolverFunction == null) {
        throw `BusinessObject ${BusinessObjectType[this.type]} does not have a name resolver`;
      }
      const res = await resolverFunction.call(this.resolveController, this.value);
      if (res && res.name) {
        this.text = res.name || this.fallback;
      } else {
        this.text = this.fallback;
      }
    } else {
      this.text = null;
    }
  }
}
