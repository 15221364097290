
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ICometMassiveUpdateMessage } from 'rt/SignalR/Messages/ICometMassiveUpdateMessage';

@Component({
  name: 'Markdown',
})
export default class MassiveUpdatePendingReport extends Vue {
  @Prop({ required: false, type: Array })
  value: ICometMassiveUpdateMessage[];

  eta(mup: ICometMassiveUpdateMessage) {
    const processed = mup.updated + mup.failed + mup.aborted;
    if (processed > 10) {
      const single = Math.abs(this.$moment(mup.startDate).diff(this.$moment(), 'seconds')) / processed;
      const remaining = mup.total - processed;
      const expectedEnd = this.$moment().add(remaining * single, 'seconds');
      return (this.$moment as any).duration(expectedEnd.diff(this.$moment())).humanize(true);
    }
    return null;
  }
}
