
import Vue from 'vue';
import _ from 'lodash';
import Component from 'vue-class-component';
import {
 Action, Getter, Mutation, namespace,
} from 'vuex-class';
import { IAxiosRequestConfigRetry } from '@/utils/types';
import { StorageController } from '@/plugins/typings/UIApiControllers/BusinessObject/DTO';
import { IStorageShare } from '@/plugins/typings/UIApiControllers/BusinessObject/DTO/IStorageShare';

// tslint:disable-next-line:variable-name
const AnonymousMutation = namespace('anonymous').Mutation;
@Component({
  name: 'StorageSharing',
})
export default class StorageSharing extends Vue {
  controller: StorageController = new StorageController(this.$http);

  share: IStorageShare = null;

  error = false;

  @AnonymousMutation setLogoUrl;

  async mounted() {
    const token = this.$route.params.token;
    const id = this.$hashids.decode(this.$route.params.id)[0] as number;
    this.$http.interceptors.request.use((r: IAxiosRequestConfigRetry) => {
      r.headers.Authorization = `Sharing ${token}`;
      r.headers['NullValueHandling'] = 'Include';
      return r;
    });
    try {
      this.share = await this.controller.Sharing(id);
      this.setLogoUrl(this.share.photoUrl);
    } catch {
      this.error = true;
    }
  }
}
