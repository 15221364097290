
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'SaveButton',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'el-icon-icon-floppy-disk',
    },
  },
})
export default class SaveButton extends Vue {
  loading: boolean;

  icon: string;

  save() {
    this.$emit('save');
  }
}
