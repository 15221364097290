//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IStorageCategoryDTO } from '../../../DTO/IStorageCategoryDTO';
import { IStorageDTO } from '../../../DTO/IStorageDTO';

//element.Name=StorageCategoryController result.Name=StorageCategoryController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.StorageCategoryController);
/** WebApiController: StorageCategoryController */
export class StorageCategoryController extends DefaultDtoControllerProxy<IStorageCategoryDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'StorageCategories', BusinessObjectType.StorageCategory);
	}
	public All(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageCategoryDTO[]>
	{
		return (this.axios.get('StorageCategories/All', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Documents(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageDTO[]>
	{
		return (this.axios.get('StorageCategories/Documents?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public DocumentsIds(id: number, filter?: string, orderBy?: string, top: number = 20, skip: number = 0, select?: string[], cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number[]>
	{
		return (this.axios.get('StorageCategories/DocumentsIds?&id='+id+((filter === null || filter === undefined) ? '' : ('&$filter='+encodeURIComponent(filter)))+((orderBy === null || orderBy === undefined) ? '' : ('&$orderBy='+encodeURIComponent(orderBy)))+'&$top='+top+'&$skip='+skip+((select === null || select === undefined) ? '' : ('&$select='+select.join(','))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetDefaultFolderCategory(cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<number>
	{
		return (this.axios.get('StorageCategories/GetDefaultFolderCategory', { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IStorageCategoryDTO>
	{
		return (this.axios.get('StorageCategories/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
