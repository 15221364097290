
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'HyperlinkInput',
  mixins: [simpleMixin],
})
export default class Input extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value: string;

  get link() {
    const v = this.value;
    if (v && v.length) {
      if (v.match(/^(http|ftp|cdn)[s]*:\/\/[\w]+/i)) {
        return v;
      }
      return `//${v}`;
    }
    return null;
  }
}
