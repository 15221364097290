
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'LeadFieldHeader',
})
export default class LeadFieldHeader extends HeaderMixin {
  get isValidValueFilter() {
    return this.value != null && this.value > 0;
  }
}
