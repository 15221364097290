//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import { ISuggestion } from '../../../Base/Search/Prompters/ISuggestion';

//element.Name=ResolveController result.Name=ResolveController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.ResolveController);
/** WebApiController: ResolveController */
export class ResolveController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in ResolveController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Get(id: number, type: BusinessObjectType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve?&id='+id+'&type='+type, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Company(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Company?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Contact(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Contact?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Lead(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Lead?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Account(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Account?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Activity(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Activity?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Ticket(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Ticket?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Opportunity(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Opportunity?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public JobOrder(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/JobOrder?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public JobOrderTask(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/JobOrderTask?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Catalog(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Catalog?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Campaign(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Campaign?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Contract(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Contract?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public PriceListDescription(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/PriceListDescription?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Bank(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Bank?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Zone(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Zone?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Storage(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Storage?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StorageCategories(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/StorageCategories?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Asset(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Asset?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Event(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Event?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RollingStock(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/RollingStock?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public List(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/List?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public MailTemplate(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/MailTemplate?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public CatalogCategory(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/CatalogCategory?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ContactCategory(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/ContactCategory?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Promotion(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Promotion?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ReferrerCategory(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/ReferrerCategory?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public TustenaDB(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/TustenaDB?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Order(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Order?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Quote(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Quote?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public WorkPlan(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/WorkPlan?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public KnowledgeBaseCategory(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/KnowledgeBaseCategory?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public KnowledgeBase(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/KnowledgeBase?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Report(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/Report?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public FlowTrigger(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<ISuggestion>
	{
		return (this.axios.get('Resolve/FlowTrigger?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Resolve/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Resolve/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
