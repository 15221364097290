
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'ContinuousScrolling',
})
export default class ContinuousScrolling extends Vue {
  @Prop({
    required: true,
    type: Array,
    // tslint:disable-next-line:object-literal-shorthand space-before-function-paren
    default() {
      return [];
    },
  })
  items: any[];

  @Prop({
    required: true,
    type: Function,
  })
  loader: (page: number) => Promise<any[]>;

  @Prop({
    required: false,
    type: Boolean,
    default: true,
  })
  silent: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  })
  loaded: boolean;

  @Prop({
    required: false,
    type: Number,
    default: null,
  })
  preLoaded: number;

  @Prop({
    required: false,
    type: String,
    default: null,
  })
  elementLoadingText: string;

  initialized = false;

  async mounted() {
    if (this.preLoaded) {
      this.page = 2;
      this.busy = false;
      if (this.items.length < this.preLoaded) {
        this.endReached = true;
      }
      this.initialized = true;
      this.$emit('update:loaded', true);
    } else if (!this.items.length) {
      await this.reset();
    } else if (this.silent) {
      await this.silentUpdate([]);
    }
  }

  busy = false;

  endReached = false;

  page = 1;

  loadingVisible = true;

  async reset(): Promise<void> {
    if (this.preLoaded) {
      this.page = 2;
      this.busy = false;
      this.endReached = this.items.length < this.preLoaded;
      this.$emit('update:loaded', true);
    } else {
      this.page = 1;
      this.$emit('update:items', []);
      this.$emit('update:loaded', false);
      this.busy = false;
      this.endReached = false;
      return await this.loadItems([]);
    }
  }

  setPage(page: number) {
    this.page = page;
    this.$emit('update:loaded', false);
    this.busy = false;
    this.endReached = false;
  }

  private async loadingVisibilityChanged(visible: boolean): Promise<void> {
    this.loadingVisible = visible;
    if (visible && !this.busy && !this.endReached && this.initialized) {
      await this.loadItems();
    }
  }

  private async loadItems(items?): Promise<void> {
    this.busy = true;
    await this.silentUpdate(items || this.items);
  }

  private async silentUpdate(items) {
    const newItems = await this.loader(this.page);
    if (newItems) {
      if (newItems.length) {
        this.$emit('update:items', [...items, ...newItems]);
        this.page += 1;
        this.busy = false;
      } else this.endReached = true;
    } else {
      this.page += 1;
      this.busy = false;
    }
    this.autoInitialize();
  }

  autoInitialize() {
    if (!this.initialized && this.items && this.items.length) {
      this.$nextTick(async () => {
        if (this.loadingVisible) {
          if (!this.endReached && !this.busy) {
            await this.loadItems();
            this.initialized = true;
          }
        } else {
          this.initialized = true;
        }
      });
    } else {
      this.initialized = true;
    }
    this.$emit('update:loaded', true);
  }

  beforeDestroy() {}
}
