//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { IVatIdCheckResult } from './IVatIdCheckResult';

//element.Name=VatIdController result.Name=VatIdController element.FullName=Digita.Tustena.UIApiControllers.UI.Smart.VatIdController);
/** WebApiController: VatIdController */
export class VatIdController
{
	constructor (axios: AxiosInstance)
	{
		
		if (axios === null || axios === undefined) {
		    throw new Error('axios argument is required in VatIdController constructor class, and cannot be empty or undefined');
		}
		this.axios = axios;
		
	}
	/** Name of axios $http interface */
	private axios: AxiosInstance;
	public Check(vatCode: string, nation?: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IVatIdCheckResult>
	{
		return (this.axios.get('VatId/Check?'+((vatCode === null || vatCode === undefined) ? '' : ('&vatCode='+encodeURIComponent(vatCode)))+((nation === null || nation === undefined) ? '' : ('&nation='+encodeURIComponent(nation))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Echo(payload: any, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('VatId/Echo', payload, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetSwagger(honorAcceptLanguageHeader: boolean = false, bestPracticeOnly: boolean = true, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('VatId/GetSwagger?&honorAcceptLanguageHeader='+honorAcceptLanguageHeader+'&bestPracticeOnly='+bestPracticeOnly, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
