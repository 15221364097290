
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { IStorageCategoryDTO } from 'dto/IStorageCategoryDTO';
import { Prop } from 'vue-property-decorator';
import { buildCategoriesPaths } from './storage';
// tslint:disable-next-line:variable-name
const StorageGetter = namespace('storage').Getter;

@Component({
  name: 'StorageCategoryPathLabel',
})
export default class StorageBrowserTable extends Vue {
  @Prop({ type: Number, required: false, default: 0 })
  value: number;

  @StorageGetter categories: IStorageCategoryDTO[];

  get categoryPaths(): IStorageCategoryDTO[] {
    return buildCategoriesPaths(this.categories, this.value);
  }
}
