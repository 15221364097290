
import Vue from 'vue';
import Component from 'vue-class-component';
import jwtManager from 'utils/jwtManager';
import { Getter } from 'vuex-class';
import { Watch, Prop } from 'vue-property-decorator';
import scriptjs from 'scriptjs';
import _ from 'lodash';

@Component({
  name: 'DynamicComponent',
})
export default class DynamicComponent extends Vue {
  @Prop({ required: true })
  module: string;

  @Prop({ required: true })
  value: string;

  @Prop({ required: false })
  props: any;

  @Getter('baseUrl') baseUrl;

  dynamicComponentName: string = null;

  @Getter config: IConfig;

  mounted() {
    this.handleValueChange(this.value);
  }

  @Watch('value')
  async handleValueChange(m) {
    if (m) {
      const tempDynamicComponentName = `${_.camelCase(this.module)}-${_.camelCase(m)}-dynamic-component`;
      if ((Vue as any).options.components[tempDynamicComponentName]) {
        this.dynamicComponentName = tempDynamicComponentName;
        return;
      }

      const accessTokenBuilder = async () => {
        if (this.config.cookie) {
          return '';
        }
        return `&access_token=${await jwtManager.getValidToken()}`;
      };
      const token = await accessTokenBuilder();
      scriptjs(`${this.baseUrl}/api/latest/${this.module}/Script?key=${m}${token}`, async () => {
        const data = (window as any).exports[m];
        if (data) {
          const template = (await this.$http.get(`${this.module}/Template?key=${m}${token}`)).data;
          const locale = (await this.$http.get(`${this.module}/Locale?key=${m}${token}`)).data;
          if (locale) {
            for (const lang in locale) {
              if (Object.prototype.hasOwnProperty.call(locale, lang)) {
                const localeData = locale[lang];
                Vue.i18n.mergeLocaleMessage(lang, localeData);
              }
            }
          }
          Vue.component(tempDynamicComponentName, {
            ...data,
            template,
          });
          this.dynamicComponentName = tempDynamicComponentName;
        }
      });
    }
  }
}
