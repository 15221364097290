
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import simpleMixin from './mixin';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'DateInput',
  mixins: [simpleMixin],
})
export default class DateInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: String,
    required: false,
  })
  value: Date;

  @Getter('user') user: IUser;

  get dateValue(): Date {
    if (this.value) {
      const m = this.$moment(this.value);
      if (m.isAfter(this.$moment('1753-01-02'))) {
        return m.toDate();
      }
    }
    return null;
  }

  changeDateTime(value: Date) {
    const m = this.$moment(value);
    if (m.isAfter(this.$moment('1753-01-02'))) {
      if (this.schema.local) {
        this.$emit('input', m.format('YYYY-MM-DDTHH:mm:ss.SSS'));
      } else {
        this.$emit('input', m.toISOString());
      }
      return;
    }
    this.$emit('input', null);
  }
}
