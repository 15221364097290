
import Vue from 'vue';
import Component from 'vue-class-component';
import { IStorageDTO } from 'dto/IStorageDTO';
import { Getter, Action, namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'FilePreviews',
})
export default class FilePreview extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  value: any[];

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  notFound: boolean;

  preview: any[] = null;

  currentPage = 1;

  imageLoaded = false;

  imagePreviewError = false;

  async mounted() {
    if (this.value.length === 0) {
      this.imagePreviewError = true;
    }
    this.handleCurrentChange(this.currentPage);
  }

  handleCurrentChange(page: number) {
    if (this.value != null && page > 0 && page <= this.value.length) {
      this.imageLoaded = false;
      this.imagePreviewError = false;
      this.preview = this.value[page - 1];
      this.currentPage = page;
    }
  }

  handleErrorUrl() {
    this.imagePreviewError = true;
  }

  swipeLeftHandler() {
    this.handleCurrentChange(this.currentPage + 1);
  }

  swipeRightHandler() {
    this.handleCurrentChange(this.currentPage - 1);
  }
}
