
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'HyperlinkIntegration',
})
export default class HyperlinkIntegration extends Vue {
  @Prop({
    type: String,
  })
  value: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  button: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  content: boolean;

  @Prop({
    type: Array,
    default: () => [
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?zoom\.(us|com)\//i,
        title: 'integrations.zoom',
      },
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?webex\.com\//i,
        title: 'integrations.webex',
      },
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?calendly\.com\//i,
        title: 'integrations.calendly',
      },
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?lifesizecloud\.com\//i,
        title: 'integrations.lifesizecloud',
      },
      {
        match: /https?:\/\/(:?[\w\.\-]+\.)?gotomeeting\.com\//i,
        title: 'integrations.gotomeeting',
      },
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?gotomeet\.me\//i,
        title: 'integrations.gotomeeting',
      },
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?clickmeeting\.com\//i,
        title: 'integrations.clickmeeting',
      },
      {
        match: /https:\/\/teams\.microsoft\.com\/l\/meetup-join\//i,
        title: 'integrations.teams',
      },
      {
        match: /https:\/\/go\.skymeeting\.net\/invite\//i,
        title: 'integrations.skymeeting',
      },
      {
        match: /https:\/\/www\.linkedin\.com\/events\//i,
        title: 'integrations.linkedin',
      },
      {
        match: /https:\/\/outlook\.office365\.com\/spaces\/view\//i,
        title: 'integrations.office365',
      },
      {
        match: /https:\/\/goo\.gl\/maps\//i,
        title: 'integrations.maps',
      },
      {
        match: /https:\/\/www\.google\.([\w]+)\/maps/i,
        title: 'integrations.maps',
      },
      {
        match: /https:\/\/maps\.apple\.com\//i,
        title: 'integrations.maps',
      },
      {
        match: /https:\/\/(meet|hangouts)\.google\.com\//i,
        title: 'integrations.googlemeet',
      },
      {
        match: /https:\/\/(join)\.skype\.com\//i,
        title: 'integrations.skype',
      },
      {
        match: /https:\/\/(:?[\w\.\-]+\.)?safelinks\.protection\.outlook\.com\//i,
        nesting: 'url',
      },
    ],
  })
  rules: any[];

  @Watch('value') handleValueChange() {
    this.createProperties();
  }

  url: URL = null;

  mounted() {
    this.createProperties();
  }

  createProperties() {
    this.url = null;
    if (this.value) {
      try {
        const url = new URL(this.value);
        this.url = url;
      } catch (_) {}
      if (!this.url && this.content) {
        const m = this.regexUrl.exec(this.value);
        if (m) {
          try {
            const url = new URL(m.groups.url);
            this.url = url;
          } catch (_) {}
        }
      }
    }
    this.props = this.createMatch(this.url);
  }

  props: any = null;

  regexUrl = /(?<pre>.+)?(?<url>https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))(?<post>.+)?/i;

  createMatch(url: URL): any {
    if (!url) {
      return null;
    }
    for (const props of this.rules) {
      const rule = props.match;
      const rx = RegExp(rule);
      if (rx.test(url.toString())) {
        if (props.nesting) {
          const subUrl = url.searchParams.get(props.nesting);
          if (subUrl) {
            try {
              return this.createMatch(new URL(subUrl));
            } catch (_) {}
          }
        }
        return props;
      }
    }
    return {
      title: 'integrations.generic',
    };
  }
}
