
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ProbabilityInput',
  mixins: [simpleMixin],
})
export default class ProbabilityInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  change(value) {
    this.$emit('input', +value);
  }
}
