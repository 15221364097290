
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import simpleMixin from './mixin';
import scriptjs from 'scriptjs';
import { IGeoDTO } from 'dto/IGeoDTO';
import { ILocationDTO } from 'dto/ILocationDTO';
import { MapsController } from 'rt/UIApiControllers/UI/Maps/MapsController';
import { IMapViewService } from 'rt/Interfaces/Maps/IMapViewService';

@Component({
  name: 'Geo',
  mixins: [simpleMixin],
})
export default class Geo extends Vue {
  @Getter('baseUrl')
  baseUrl: string;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  location: ILocationDTO = {
    lat: 0,
    lng: 0,
  };

  @Prop({
    type: Number,
    default: 400,
  })
  ysize: number;

  @Prop({
    type: Number,
    default: 400,
  })
  xsize: number;

  @Prop({
    type: Object,
    required: false,
  })
  value: IGeoDTO;

  @Getter('mapService')
  mapService: IMapViewService;

  @Watch('value', { deep: true }) handleValueChange(value: IGeoDTO) {
    if (value != null && value.locationObject) {
      this.location = { ...value.locationObject };
    } else {
      this.location = null;
    }
  }

  async mounted() {
    if (this.value != null && this.value.locationObject) {
      this.location = { ...this.value.locationObject };
    }
  }

  changeLatitude(value: number) {
    this.location.lat = value;
    this.$emit('input', {
      ...this.value,
      locationObject: this.location,
    });
  }

  changeLongitude(value: number) {
    this.location.lng = value;
    this.$emit('input', {
      ...this.value,
      locationObject: this.location,
    });
  }
}
