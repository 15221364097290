//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum MailSpoolType {
	XMail = 0,
	Remote = 1,
	Direct = 2,
	MsSmtp = 3
}
