//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IFlowLogDTO } from '../../../DTO/IFlowLogDTO';
import { IFlowLinkDTO } from '../../../DTO/IFlowLinkDTO';
import { IFlowDTO } from '../../../DTO/IFlowDTO';

//element.Name=FlowController result.Name=FlowController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.FlowController);
/** WebApiController: FlowController */
export class FlowController extends DefaultDtoControllerProxy<IFlowDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Flows', BusinessObjectType.Flow);
	}
	public Success(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Flows/Success?&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Fail(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Flows/Fail?&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Abort(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Flows/Abort?&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Log(id: number, sid: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowLogDTO>
	{
		return (this.axios.get('Flows/Log?&id='+id+'&sid='+sid, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public LastFlow(type: BusinessObjectType, id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowLinkDTO>
	{
		return (this.axios.post('Flows/LastFlow?&type='+type+'&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StartAll(trigger: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Flows/StartAll?'+((trigger === null || trigger === undefined) ? '' : ('&trigger='+encodeURIComponent(trigger))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public Start(trigger: string, crossId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Flows/Start?'+((trigger === null || trigger === undefined) ? '' : ('&trigger='+encodeURIComponent(trigger)))+'&crossId='+crossId, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StartSingle(trigger: string, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.get('Flows/StartSingle?'+((trigger === null || trigger === undefined) ? '' : ('&trigger='+encodeURIComponent(trigger))), { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public StartManualTrigger(triggerId: number, flowTemplateId: number, crossId: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Flows/StartManualTrigger?&triggerId='+triggerId+'&flowTemplateId='+flowTemplateId+'&crossId='+crossId, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IFlowDTO>
	{
		return (this.axios.get('Flows/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
