
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';
import TListFastLayoutStudio from '../views/ListFastLayoutStudio.vue';
import eventHubManger from 'modules/mixin/views/EventHub';
import { Prop } from 'vue-property-decorator';
import { IListDTO } from 'dto/IListDTO';
import { IListPreset } from 'rt/Interfaces/TargetList/Presets/IListPreset';

// tslint:disable-next-line:variable-name
const ListGetter = namespace('list').Getter;
// tslint:disable-next-line:variable-name
const ListAction = namespace('list').Action;
@Component({
  name: 'TFastListLayoutStudioDialog',
  components: {
    TListFastLayoutStudio,
  },
})
export default class TFastListLayoutStudioDialog extends Vue {
  @Prop({
    type: Object,
    required: false,
  })
  defaults: Partial<IListDTO>;

  @Prop({
    default: false,
    type: Boolean,
  })
  visible: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  redirect: boolean;

  @ListGetter('changes') listChanges: () => any;

  @ListGetter presets: IListPreset[];

  @ListAction loadPresets: () => Promise<any>;

  @ListAction setPreset: (payload: { id: number; preset: string }) => Promise<any>;

  preset: string = null;

  importingPresets = false;

  mounted() {
    this.preset = null;
    this.loadPresets();
  }

  goToCompleteSheet() {
    this.$emit('update:visible', false);
    const c = this.listChanges();
    this.$router.push({
      name: this.$routeNames.list,
      params: {
        id: this.$hashids.encode(0),
        defaults: JSON.stringify(c),
      },
    });
  }

  saveList() {
    return eventHubManger.getHub('list.fast').$emit('save');
  }

  async handleCreated(id: number) {
    if (this.preset) {
      this.importingPresets = true;
      await this.setPreset({ id, preset: this.preset });
      this.importingPresets = false;
    }
    this.$emit('update:visible', false);
    this.$emit('created', id);
  }

  close() {
    this.$emit('update:visible', false);
  }
}
