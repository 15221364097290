
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IListResult } from 'rt/UIApiControllers/Controls/IListResult';
import { Prop } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'ListSelect',
})
export default class ListSelect extends Vue {
  @Getter user: IUser;

  @Getter('lists/list')
  list: (type: ListsType) => Array<IInt64ListResult | IListResult>;

  @Prop({
    type: Object,
    required: true,
  })
  schema: {
    listType: ListsType;
    text: string;
    captionValue: any;
    captionText: string;
    multiple: boolean;
    editable: { type: number; propertyName: string; delete: boolean; orderPropertyName: number };
  };

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: [String, Number, Array],
    required: false,
    default: null,
  })
  value: any;

  @Prop({
    type: Function,
    required: false,
  })
  filter: any;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  simple: boolean;

  mounted() {
    if (this.value === null) {
      console.log('IS NULL', this.schema);
    }
  }

  change(value) {
    this.$emit('input', value);
  }
}
