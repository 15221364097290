
import Vue from 'vue';
import Component from 'vue-class-component';
import { IWebMailSmallMessage } from 'rt/Base/WebMail/IWebMailSmallMessage';

@Component({
  name: 'WebMailListSideItem',
  props: {
    mail: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
})
export default class WebMailListSideItem extends Vue {
  mail: IWebMailSmallMessage;

  selected: boolean;

  get unSeenStyle(): any {
    if (this.mail.seen) return {};
    return { fontWeight: 'bold' };
  }

  get route() {
    return {
      name: this.$routeNames.webmailView,
      params: {
        mailboxId: this.$hashids.encode(this.mail.mailBoxId),
        uid: this.$hashids.encode(this.mail.uid),
      },
      query: { ...this.$route.query, folder: this.mail.folder },
    };
  }

  get positiveSentiment() {
    return this.mail.positiveScore > this.mail.negativeScore && this.mail.positiveScore > this.mail.neutralScore;
  }

  get negativeSentiment() {
    return this.mail.negativeScore > this.mail.positiveScore && this.mail.negativeScore > this.mail.neutralScore;
  }
}
