//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum FileCrossTypes {
	Company = 0,
	Contact = 1,
	Activity = 2,
	Opportunity = 3,
	Promotion = 4,
	CatalogProduct = 5,
	CompanyCategory = 6,
	ContactLeadCategory = 7,
	Lead = 8,
	None = 9,
	JobOrders = 10,
	Contract = 11,
	JobOrderTask = 12,
	FileDossier = 13,
	Dossier = 14,
	RollingStock = 15,
	Event = 16,
	DebtRecovery = 17,
	Ticket = 18,
	WorkPlan = 19
}
