
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'schemaSelector',
  props: ['repeater', 'schemas', 'currentSchema'],
})
export default class SchemaSelector extends Vue {
  repeater: string;

  schemas: any[];

  currentSchema: string;

  async defaultClick(schema) {
    let endpoint;
    if (schema.isMyDefault) {
      endpoint = 'RemovePinSchemaAsMyDefault';
    } else {
      endpoint = 'PinSchemaAsMyDefault';
    }

    const response = await this.$http.post(`${this.repeater}/${endpoint}`, JSON.stringify(schema.name), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.currentSchemaName = schema.name;
  }

  get currentSchemaName() {
    return this.currentSchema;
  }

  set currentSchemaName(value) {
    this.$emit('schema-change', value);
  }
}
