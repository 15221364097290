
import Vue from 'vue';
import { Getter, namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ElementUI from 'element-ui';
import { IWidgetPage } from 'rt/Interfaces/UI/IWidgetPage';
import { ILayoutStudioSlot } from 'rt/Interfaces/UI/ILayoutStudioSlot';
import layoutStudioValidationRules from 'utils/layoutStudioValidationRules';
import _ from 'lodash';

interface ILayoutStudioWidget extends IWidgetPage {
  schemaEndPoint: string;
  schema: IWidgetPage;
}

@Component({
  name: 'LayoutStudioInput',
})
export default class LayoutStudioInput extends Vue {
  @Prop({ required: true, type: Object })
  schema: ILayoutStudioWidget;

  @Prop({ required: true, type: Object })
  value: any;

  widgetSchema: IWidgetPage = null;

  async mounted() {
    if (this.schema?.schema) {
      this.widgetSchema = this.schema?.schema;
    } else if (this.schema?.schemaEndPoint) {
      this.widgetSchema = await this.$http.get(this.schema.schemaEndPoint).then((r) => r.data);
    }
  }
}
