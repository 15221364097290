
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'MessagesInput',
})
export default class MessagesInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  context: any;

  get message() {
    const m = this.data['__message'];
    if (m) {
      return m;
    }
    return null;
  }

  handleTempMessage(m) {
    this.$emit('input', { ...this.data, __message: m });
  }
}
