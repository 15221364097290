//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChartIds {
	CustomerDivision = 1,
	PersonalActivityStatus = 3,
	LeadPerState = 4,
	QuotePerState = 5,
	LeadFromOrigin = 6,
	BestSellingProducts = 7,
	SalesAmounts = 10,
	CompanyActivities = 11,
	DocumentAmounts = 12,
	ProductOrderTrends = 14,
	ProductQuoteTrends = 15,
	EmailSendStatus = 16,
	EmailSendTrackAnchor = 17,
	EmailSendErrorDetail = 18,
	EmailSendOpen = 19,
	GroupOpportunityStatus = 31,
	GroupOpportunityPhase = 32,
	GroupOpportunityLostReason = 33,
	GroupOpportunityPhaseWeight = 44,
	OpportunityPromotionForecast = 45,
	BudgetAnalysisPrice = 46,
	BudgetAnalysisCost = 47,
	EventElementState = 52,
	EventQuoteState = 53,
	JobOrderTasksPerState = 54,
	JobOrderTasksPerType = 55,
	ContractsPerCategory = 56,
	ContractsPerState = 57,
	ContratcsPerModel = 58,
	JobOrderTasksPerZone = 59,
	JobOrderTasksPerResource = 60,
	MonthlyJobOrderTasks = 61,
	TopFiveActivitiesPerMachine = 62,
	TopFiveJobOrderTasksPerClient = 63,
	TopFiveJobOrderTasksPerMachine = 64,
	TotalOpenJobOrderTasks = 65,
	TotalActiveMachinesAnagraphics = 66,
	AverageDurationJobOrderTasks = 67
}
