
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import { Prop } from 'vue-property-decorator';
import TLookupSkin from './LookupSkin.vue';

@Component({
  name: 'MailTemplateLookup',
  components: {
    TLookupSkin,
  },
})
export default class MailTemplateLookup extends BaseLookup {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  template: boolean;

  dialogVisible = false;

  buildSuggestionParams(): any {
    return {
      template: this.template,
    };
  }

  suggestionControllerName() {
    return 'MailTemplate';
  }

  keyPrefix() {
    return 'MailTemplate';
  }

  openDialog() {
    this.dialogVisible = true;
  }

  handleSelect(val) {
    this.addItem({
      key: val.key,
      title: val.value,
    });
  }

  linkClick(mailTemplateId, row) {
    const item = {
      key: `${this.keyPrefix()}-${mailTemplateId}`,
      title: row.SUBJECT.value,
    };

    this.addItem(item);
    if (!this.multipleSelection) {
      this.dialogVisible = false;
    }
  }
}
