
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListsType } from 'rt/Core/ListsType';
import BaseTag from './BaseTag';
import { TagController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { ITagDTO } from 'dto/ITagDTO';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { Getter, Action, namespace } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'TTags',
})
export default class TTags extends BaseTag {
  tagController = new TagController(Vue.axios);

  created() {
    this.radioGlobalPersonal = null;
  }

  async createNewTag(text: string, global: boolean): Promise<number> {
    const newInstance = {} as Partial<ITagDTO>;
    newInstance.name = text;
    return await this.tagController.CreateOrUpdate(newInstance as ITagDTO);
  }

  getListType(): ListsType {
    return this.$enums.ListsType.Tags;
  }

  tagInputValue: string = null;

  tags: number[] = [];

  get inputVisible(): boolean {
    return this.popoverOpen;
  }

  set inputVisible(value: boolean) {
    this.popoverOpen = value;
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).$refs.input.focus();
    });
  }
}
