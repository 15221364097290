
import Vue from 'vue';
import Component from 'vue-class-component';
import i18nUtils from 'utils/i18nUtils';
import { Prop, Watch } from 'vue-property-decorator';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { Getter } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'schemaManager',
})
export default class SchemaManager extends Vue {
  @Prop({ type: String, required: true })
  repeater: string;

  @Prop({ type: String, required: true })
  initSchema: any;

  @Getter user: IUser;

  open = true;

  loading = false;

  saving = false;

  columns = [];

  schemas = [];

  currentSchema = null;

  async mounted() {
    await this.handleLoadingData();
  }

  get showKey(): boolean {
    if (this.currentSchema) {
      return this.currentSchema.showKey;
    }
    return null;
  }

  handleShowKey(v: boolean) {
    if (this.currentSchema) {
      this.currentSchema.showKey = v;
    }
  }

  async handleLoadingData() {
    this.dialogVisible = true;
    if (this.dialogVisible) {
      try {
        this.loading = true;
        await this.loadData();
      } catch (e) {
        this.$message.error(e.message);
      } finally {
        this.loading = false;
      }
    }
  }

  async loadData() {
    const response = await this.$http.get(`${this.repeater}/Schemes`);
    const data = response.data;
    this.columns = data.columns;
    this.schemas = data.schemes;
    this.currentSchema = this.schemas.find((s) => s.name === this.initSchema);
    if (!this.currentSchema) {
      this.currentSchema = this.schemas[0];
    }
  }

  addNewSchema(name: string) {
    const newSchema = {
      ...this.currentSchema,
      name,
      showKey: false,
    };
    this.schemas.push(newSchema);
    this.currentSchema = newSchema;
  }

  deleteSchema() {
    if (this.schemas.length > 1) {
      this.schemas = this.schemas.filter((schema) => schema.name !== this.currentSchema.name);
      this.currentSchema = this.schemas[0];
    }
  }

  clearState(done) {
    this.columns = [];
    this.schemas = [];
    this.currentSchema = null;
    done();
  }

  async handleCommands(command: string) {
    switch (command) {
      case 'clone':
        try {
          const res = (await this.$prompt('', this.$t('grid.schemaManager.new.title') as string)) as any;
          if (res && res.value) {
            this.addNewSchema(res.value);
          }
        } catch { }
        break;
      default:
        if (command) {
          this.currentSchemaName = command;
        }
        break;
    }
  }

  async saveSchemas() {
    this.saving = true;
    try {
      const schemas = this.schemas;
      const response = await this.$http.post(`${this.repeater}/SaveSchemes`, schemas);
      this.$emit('schema-changed', this.currentSchema.name);
      this.closeDialog();
    } finally {
      this.saving = false;
    }
  }

  closeDialog() {
    this.columns = [];
    this.schemas = [];
    this.currentSchema = null;
    this.dialogVisible = false;
  }

  get dialogVisible() {
    return this.open;
  }

  set dialogVisible(val) {
    this.open = val;
    if (!val) {
      this.$emit('close');
    }
  }

  get transferData() {
    return this.columns.map((column) => ({
      key: column.name,
      label: column.text,
    }));
  }

  tempColumnFilter: string = null;

  columnFilter: string = null;

  get unselectedData() {
    const filtered = this.columns
      .filter((column) => !this.currentSchema.columns.includes(column.name))
      .filter((column) => {
        if (this.columnFilter) {
          return new RegExp(this.columnFilter, 'i').test(column.text);
        }
        return true;
      });
    return _.orderBy(
      filtered.map((column) => column),
      (c) => c.text,
    );
  }

  get selectedData() {
    const columns = [];
    for (const column of this.currentSchema.columns) {
      const c = this.columns.find((col) => col.name === column);
      if (c) {
        columns.push(c);
      }
    }
    return columns;
  }

  set selectedData(values) {
    this.currentSchema.columns = values.map((c) => c.name).slice(0, 10);
  }

  get currentSchemaName() {
    if (this.currentSchema) {
      return this.currentSchema.name;
    }
    return null;
  }

  set currentSchemaName(value) {
    const schema = this.schemas.find((schema) => schema.name === value);
    if (schema) {
      this.currentSchema = schema;
    }
  }

  async handleDeleteCommand() {
    if (this.currentSchema) {
      const index = this.schemas.findIndex((schema) => schema.name === this.currentSchema.name);
      this.schemas = [...this.schemas.slice(0, index), ...this.schemas.slice(index + 1)];
      this.currentSchema = this.schemas[0];
    }
  }

  removeColumnAtIndex(index) {
    const columns = this.currentSchema.columns;
    this.currentSchema.columns = [...columns.slice(0, index), ...columns.slice(index + 1)];
  }
}
