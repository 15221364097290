
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import tours from '../data/tours';

@Component({
  name: 'TourButton',
})
export default class TourButton extends Vue {
  @Getter tour: (routeName: string) => string[];

  @Action loadTour: (routeName: string) => Promise<void>;

  @Watch('$route.name') handleRouteChange() {
    this.detectAutoStartTour();
  }

  get existTourInPage(): boolean {
    if (!this.$route.name) {
      return false;
    }
    return Object.prototype.hasOwnProperty.call(tours, this.$route.name);
  }

  mounted() {
    this.detectAutoStartTour();
  }

  async detectAutoStartTour() {
    if (this.existTourInPage) {
      await this.loadTour(this.$route.name);
      const executedTours = this.tour(this.$route.name);
      const notExecutedTours = tours[this.$route.name].filter((t) => executedTours.indexOf(t.target) < 0);
      if (notExecutedTours.length) {
        this.startTour(false, true);
      }
    }
  }

  stopTour() {
    const q = { ...this.$route.query };
    delete q.tour;
    this.$router.replace({
      query: q,
    });
  }

  startTour(immediate: boolean = true, onlyNew: boolean = false) {
    if (!Object.prototype.hasOwnProperty.call(this.$route.query, 'tour')) {
      this.$root.$emit('tour', { immediate, onlyNew });
    } else {
      this.stopTour();
    }
  }
}
