
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'FullSizeColumn',
})
export default class FullSizeColumn extends Vue {
  top: number = null;

  get viewPortHeight() {
    return this.$viewport.height;
  }

  @Watch('viewPortHeight') handleViewportChange() {
    this.setTopPosition();
  }

  get height() {
    return `${this.$viewport.height - this.top - 10}px`;
  }

  async mounted() {
    this.setTopPosition();
  }

  updated() {
    this.setTopPosition();
  }

  setTopPosition() {
    const pos = this.$el.getBoundingClientRect();
    this.top = pos.top;
  }
}
