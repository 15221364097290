
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'LookupSkin',
  props: {
    value: {
      type: String,
      required: true,
    },
    inputVisible: {
      type: Boolean,
      required: true,
      default: true,
    },
    fetchSuggestions: {
      type: Function,
      required: false,
      default: null,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    autostart: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
})
export default class LookupSkin extends Vue {
  focused = false;

  handleBlur() {
    this.focused = false;
  }

  handleFocus() {
    this.focused = true;
  }

  handleBoxClick() {
    (this.$refs['input-element'] as any).focus();
  }

  focus() {
    (this.$refs['input-element'] as any).focus();
  }
}
