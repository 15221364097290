
import Vue from 'vue';
import Component from 'vue-class-component';
import { ListsType } from 'rt/Core/ListsType';
import BaseTag from './BaseTag';
import { ContactCategoryController } from 'rt/UIApiControllers/BusinessObject/DTO';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';

@Component({
  name: 'ContactCategoryTag',
})
export default class ContactCategoryTag extends BaseTag {
  contactCategoryController = new ContactCategoryController(Vue.axios);

  async createNewTag(text: string, global: boolean): Promise<number> {
    const newInstance = await this.contactCategoryController.GetNewInstance();
    newInstance.description = text;
    newInstance.personal = !global;
    return await this.contactCategoryController.CreateOrUpdate(newInstance);
  }

  getListType(): ListsType {
    return this.$enums.ListsType.ListContactCategory;
  }
}
