import { render, staticRenderFns } from "./GlobalSearchInput.vue?vue&type=template&id=0470ce74&scoped=true&"
import script from "./GlobalSearchInput.vue?vue&type=script&lang=ts&"
export * from "./GlobalSearchInput.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalSearchInput.vue?vue&type=style&index=0&id=0470ce74&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0470ce74",
  null
  
)

export default component.exports