//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { CancelToken, AxiosRequestConfig, AxiosInstance } from 'axios';
import { BusinessObjectType } from '../../../Core/BusinessObjectType';
import DefaultDtoControllerProxy from './DefaultDtoControllerProxy';
import { IAddressDTO } from '../../../DTO/IAddressDTO';
import { CrossType } from '../../../Core/CrossType';

//element.Name=AddressController result.Name=AddressController element.FullName=Digita.Tustena.UIApiControllers.BusinessObject.DTO.AddressController);
/** WebApiController: AddressController */
export class AddressController extends DefaultDtoControllerProxy<IAddressDTO>
{
	constructor (axios: AxiosInstance)
	{
		super(axios, 'Addresses', BusinessObjectType.Addresses);
	}
	public ConvertToDestination(dto: IAddressDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Addresses/ConvertToDestination', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public RemoveDestination(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Addresses/RemoveDestination?&id='+id, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public ValidateBranch(dto: IAddressDTO, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<boolean>
	{
		return (this.axios.post('Addresses/ValidateBranch', dto, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public AddressTypeChanged(companyId: number, addressTypeId: number, crossType: CrossType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Addresses/AddressTypeChanged?&companyId='+companyId+'&addressTypeId='+addressTypeId+'&crossType='+crossType, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetAddressList(crossId: number, crossType: BusinessObjectType, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<any>
	{
		return (this.axios.post('Addresses/GetAddressList?&crossId='+crossId+'&crossType='+crossType, null, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
	public GetFull(id: number, cancelToken?: CancelToken, axiosConfig?: AxiosRequestConfig) : Promise<IAddressDTO>
	{
		return (this.axios.get('Addresses/GetFull?&id='+id, { ...axiosConfig, cancelToken })).then(r => r.data);
	}
}
