
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IListResult } from 'rt/UIApiControllers/Controls/IListResult';
import { ListsType } from 'rt/Core/ListsType';
import TRemoteEditableOption from './RemoteEditableOption.vue';
import move from 'lodash-move';

// tslint:disable-next-line:variable-name
const ListsAction = namespace('lists').Action;
// tslint:disable-next-line:variable-name
const ListsGetter = namespace('lists').Getter;
@Component({
  name: 't-remote-select',
  components: {
    TRemoteEditableOption,
  },
  props: {
    value: {
      type: [Number, String, Array],
      required: false,
      default: null,
    },
    type: {
      type: Number,
      required: true,
    },
    captionValue: {
      type: [Number, String],
      required: false,
      default: null,
    },
    captionText: {
      type: String,
      required: false,
      default: null,
    },
    filter: {
      type: Function,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Object,
      required: false,
      default: null,
    },
  },
})
export default class RemoteSelect extends Vue {
  @ListsGetter
  list: (type: ListsType) => Array<IInt64ListResult | IListResult>;

  @ListsAction
  load: (type: ListsType) => Promise<any>;

  @ListsAction
  refresh: (type: ListsType) => Promise<any>;

  type: ListsType;

  captionValue: any;

  captionText: string;

  value: any;

  multiple: boolean;

  newItems: Array<any> = [];

  loading = false;

  disabled: boolean;

  dropdown: boolean;

  clearable: boolean;

  editable: { type: number; propertyName: string; orderPropertyName: string; delete: boolean };

  editing = false;

  filter: (items: IInt64ListResult | IListResult) => boolean;

  get filteredItems(): Array<IInt64ListResult | IListResult> {
    if (this.filter != null) {
      return this.newItems.filter(this.filter);
    }
    return this.newItems;
  }

  async mounted() {
    await this.load(this.type);
    this.loadItems();
    this.loading = false;
  }

  loadItems() {
    const items = [...this.list(this.type)];
    if (this.captionValue != null && !this.multiple) {
      if (!items.some((item) => item.value === this.captionValue)) {
        items.unshift({
          value: this.captionValue,
          text: this.captionText,
          infoData: null,
        });
      }
    }
    this.newItems = items;
  }

  change(value) {
    if (this.editing) {
      return;
    }
    this.$emit('input', value);
  }

  async handleEditOption(isNew = false) {
    if (isNew) {
      this.sortedItems = null;
    }
    await this.refresh(this.type);
    await this.load(this.type);
    this.loadItems();
  }

  startEditing() {
    this.editing = !this.editing;
  }

  sortedItems: [{ id: number }] = null;

  async handleComplete({ moved }: { moved: { newIndex: number; oldIndex: number } }) {
    const type = this.$enums.BusinessObjectType[this.editable.type];
    if (this.sortedItems === null) {
      const items = this.filteredItems;
      const ids = items.map((v) => v.value);
      this.sortedItems = await this.$http.post(`${type}/MultipleGet`, { iDs: ids, select: ['id', this.editable.orderPropertyName] }).then((res) => res.data.map((v) => v.item));
    }
    this.sortedItems = move(this.sortedItems, moved.oldIndex, moved.newIndex);
    this.newItems = move(this.newItems, moved.oldIndex, moved.newIndex);
    const promises = [];
    for (let i = 0; i < this.sortedItems.length; i += 1) {
      const si = this.sortedItems[i];
      if (si && si[this.editable.orderPropertyName] !== i) {
        promises.push(
          this.$http.put(type, {
            id: si.id,
            [this.editable.orderPropertyName]: i,
          }),
        );
        si[this.editable.orderPropertyName] = i;
      }
    }
    await Promise.all(promises);
    await this.refresh(this.type);
    await this.load(this.type);
    this.loadItems();
  }
}
