//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum HelperClassType {
	Relation = 0,
	MovementRows = 1,
	FreeFieldItem = 2,
	BoToXML = 3,
	UserData = 4,
	Addresses = 5,
	CatalogLink = 6,
	ProductPriceList = 7,
	ERPRow = 8,
	Geo = 9,
	Distance = 10,
	Location = 11,
	GeoAddress = 12,
	IncomeDocumentExpire = 13,
	IncomeDocumentRows = 14,
	TaxSummary = 15,
	BookMark = 16,
	Normalizer = 17,
	NormalizeCity = 18,
	NormalizeAddress = 19,
	InvoiceInstallment = 20,
	RollingStockInvoice = 21,
	FileCross = 22,
	TicketTemplate = 23,
	TicketAttach = 24,
	DocumentReference = 25,
	EMail = 26,
	EMailAttachment = 27,
	TustenaMessage = 28,
	InvoiceRate = 29,
	ContractJobOrderTaskPlanned = 30,
	ContractSection = 31,
	ContractTypeOfService = 32,
	ThreadManager = 33,
	CometImportUpdateProgress = 34,
	CometDownloadComplete = 35,
	CometExpire = 36,
	CometClientMessage = 37,
	CampaignFaxBuilder = 38,
	Sms = 39,
	Fax = 40,
	FaxDocument = 41,
	AlternativeUnitList = 42,
	ActivityRelation = 43,
	DebtRecoveryInvoices = 44,
	NormalizeEMail = 45,
	EMailNormalized = 46,
	ContractServiceExtensions = 47,
	FreeFieldTreeItem = 48,
	TustenaLiquid = 49,
	Finder = 50,
	CompanyFilter = 51,
	ContactFilter = 52,
	LeadFilter = 53,
	CometJavascriptCall = 54,
	Sharing = 55,
	SharePermission = 56,
	FlowRunning = 57,
	FlowActionResult = 58,
	JobOrderResource = 59,
	AssetLock = 60,
	JobOrderTaskResource = 61,
	ActivityHourBand = 62,
	JobOrderConsumptionConfig = 63,
	AlarmNotification = 64,
	JobOrderBilling = 65,
	OpportunityReferrer = 66,
	OpportunityPartner = 67,
	OpportunityCompetitor = 68,
	Direction = 69,
	WorkRangeSlot = 70,
	KnowledgeBaseLinkedCatalogCategory = 71,
	KnowledgeBaseLinkedComment = 72,
	KnowledgeBaseLinkedDocument = 73,
	KnowledgeBaseLinkedProduct = 74,
	KnowledgeBaseLinkedRelatedTopic = 75,
	KnowledgeBaseLinkedUrl = 76,
	KnowledgeBaseLinkedTitle = 77,
	CatiPermit = 78,
	SlaTemplateCrossElement = 79,
	SlaTemplateEvent = 80,
	SlaTemplateAction = 81,
	SlaTemplateCondition = 82,
	CampaignUser = 83,
	CampaignPhase = 84,
	ContractConsumptionConfig = 85,
	CatalogCallPlanned = 86,
	DebtRecoveryLog = 87,
	FileDossierSlot = 88,
	MyBookMark = 89,
	TrackingRecord = 90,
	PortalConfiguration = 91,
	List = 92,
	ContractRate = 93,
	PortalDocumentPermission = 94,
	PortalKnowledgeBasePermission = 95,
	PortalCatalogPermission = 96,
	PortalMailingListPermission = 97,
	PortalPersonalDataPermission = 98,
	PortalTicketPermission = 99,
	PortalReportPermission = 100,
	PortalQuoteRequestPermission = 101,
	Recurrence = 102,
	SharingTemplateRule = 103,
	SharingSettings = 104,
	QuoteExtensionConfiguration = 105,
	QuoteExtensionConfigurationPart = 106,
	ERPRowExtension = 107,
	ERPRowGrouping = 108,
	ERPRowGroupingExtension = 109,
	ApprovalRule = 110,
	ApprovalResult = 111,
	FlattenRowDetail = 112,
	FlattenDetail = 113,
	ApprovalPendingLinks = 114,
	OpportunityProduct = 115,
	Localizing = 116,
	FreeFieldsManager = 117,
	DTOSearchEngine = 118,
	ActivitiesFromListGenerator = 119,
	MarketingCampaignProduct = 120,
	ActivityPriceDetail = 121,
	TicketGrabberLog = 122,
	TicketGrab = 123,
	TicketGrabAttachment = 124,
	TicketGrabber = 125,
	TicketGrabberConfiguration = 126,
	CatalogVariantOption = 127,
	ErpRowDataStruct = 128,
	ErpDocumentConfiguration = 129,
	MessageAttachment = 130,
	ReportParameter = 131,
	ReportGroupDefinition = 132,
	FilePackageBuilder = 133,
	Account_Skills = 134,
	PriceListRequest = 135,
	DynamoRepeaterColumn = 136,
	FreeFieldItemLocalizing = 137,
	ExchangeSynchronizationRules = 138,
	ExchangeSchedulerModeSynchronizationService = 139,
	GoogleApiSettings = 140,
	RollingStockContractService = 141,
	ExchangeSchedulerModeSynchronizationContext = 142,
	PolyedroConfiguration = 143,
	ExternalReference = 144,
	ExternalReferences = 145,
	PolyedroAuAnagraphicSync = 146,
	AsyncHelpers = 147,
	ScriptManagerControlHelper = 148,
	ContractBillingRangeLock = 149,
	TotalDocumentResult = 150,
	ContractTemplateSection = 151,
	ContractTemplateTypeOfService = 152,
	ContractTemplateCallPlanned = 153,
	GroupMenuItem = 154,
	RollingStockMovementContext = 155,
	DynamoStandardParameterRequest = 156,
	FlowDataClass = 157,
	FlowSchemaData = 158,
	FlowData = 159,
	TustenaDBColumnLocalizing = 160,
	UserListFieldLocalizing = 161,
	TustenaDBDataItem = 162,
	TustenaDBColumn = 163,
	TustenaDBSearchSingleCriterion = 164,
	TustenaDBSearchConnectedCriteria = 165,
	FreeFieldsVisibilityManager = 166,
	FreeFieldParentOption = 167,
	ContactOtherCompany = 168,
	StandardCalendarAppointment = 169,
	NormalizePhone = 170,
	PhoneNormalized = 171,
	GoogleCalendarSettings = 172,
	GoogleContactSettings = 173,
	DavCalendarSettings = 174,
	RadicaleCalendarSettings = 175,
	ICloudCalendarSettings = 176,
	ICloudContactSettings = 177,
	RadicaleContactSettings = 178,
	DavContactSettings = 179,
	CometPasswordExpireMessage = 180,
	ReportBuilderExposer = 181,
	ReportSearchCondition = 182,
	AdvancedSearchBuilder = 183,
	ReportConverter = 184,
	ReportField = 185,
	FieldProperty = 186,
	ReportMapperFactory = 187,
	ActivityEMailData = 188,
	MailAddress = 189,
	ActivityFaxData = 190,
	AccountTierAccessPermit = 191,
	MailingListRights = 192,
	ListMailingListRights = 193,
	FlowLog = 194,
	TagReference = 195,
	CometLayoutStudioUpdate = 196,
	HookPush = 197,
	StandardCalendarAttendee = 198,
	WebApiKey = 199,
	WebApiKeyDomain = 200,
	CometOAuthTokenExpiredMessage = 201,
	FlowLabel = 202,
	SqlStructure = 203,
	SalesTerms = 204,
	PartnerSalesTerms = 205,
	ImageEmbedder = 206,
	Booking = 207,
	BookingSlot = 208,
	InternalStorageSharing = 209,
	ExternalStorageSharing = 210,
	PromotionReferrer = 211,
	PromotionPartner = 212,
	PromotionCompetitor = 213,
	PromotionProduct = 214,
	ActivityCrossRolling = 215,
	CrossSparePart = 216,
	TicketConfiguration = 217,
	ActivityColorConfiguration = 218,
	BillingServiceConfiguration = 219,
	ContractStatusPermits = 220,
	ErpRowDataStructure = 250,
	CompetitorProduct = 251,
	MapsAndGeoCodingConfiguration = 252,
	UnifiedMessageConfiguration = 253,
	SmsNames = 254,
	FaxNames = 255,
	ContractStatus = 256,
	DefaultCodeValues = 257,
	ContractServiceConsumptionPrice = 258,
	DateTimeRange = 300,
	CalendarAppointmentInserter = 301,
	StorageSharingInserter = 304,
	TustenaDBImportManager = 305,
	DBImportColumn = 306,
	LayoutBreakPoint = 307,
	ImportData = 308,
	CloneAccount = 309,
	MailingSignatures = 310,
	MailingSignature = 311,
	LeadConversion = 312,
	MailingListLimitConfiguration = 313,
	PortalLink = 314,
	ServerSettings = 315,
	KnowledgeBaseLinkedTicketType = 316,
	KnowledgeBaseLinkedTicketArea = 317,
	JobOrderToBill = 318,
	JobOrderTaskPlanner = 319,
	QuoteStageConfiguration = 320,
	ContractRenewManager = 321,
	PaymentCross = 322,
	FavoriteEntity = 323,
	HistoryEntity = 324,
	CatalogProductSparePartsActivity = 325,
	PortalUser = 2000,
	PortalTheme = 2001,
	PortalNewsHomePage = 2002,
	PortalTemplateTheme = 2003,
	PrintTemplate = 2004
}
