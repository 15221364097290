
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'ParameterVariable',
})
export default class ParameterVariable extends Vue {
  @Prop({ type: [Object, String, Number, Boolean], required: false })
  value: any;

  @Prop({ type: Boolean, required: false, default: true })
  active: boolean;

  lastValue: any = null;

  lastParameterValue: any = null;

  changeParameterType() {
    if (this.isParameter) {
      this.lastParameterValue = _.cloneDeep(this.value);
      this.$emit('input', this.lastValue);
    } else {
      this.lastValue = _.cloneDeep(this.value);
      this.$emit('input', this.lastParameterValue || { parameter: '{{ }}' });
      this.$nextTick(() => {
        (this.$refs['parameterInput'] as any).focus();
      });
    }
  }

  get isParameter(): boolean {
    if (this.value && typeof this.value === typeof {} && Object.prototype.hasOwnProperty.call(this.value, 'parameter')) {
      return true;
    }
    return false;
  }

  handleParameterValueChange(v) {
    this.$emit('input', {
      ...this.value,
      parameter: v,
    });
  }
}
