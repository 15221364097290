
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';

@Component({
  name: 'CurrencyValue',
})
export default class CurrencyValue extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  value: number;

  @Prop({
    type: Object,
    required: false,
  })
  currency: IInt64ListResult;

  @Prop({
    type: Number,
    default: null,
  })
  precision: number;

  @Getter user: IUser;

  get minimumFractionDigits(): number {
    return this.precision ?? this.currency?.infoData['Decimals']?.value ?? this.user.currencyPrecision;
  }

  get symbol(): number {
    return this.currency?.infoData['Symbol']?.value ?? this.user.currencySymbol;
  }

  get format(): number {
    return +(this.currency?.infoData['Format']?.value ?? 0);
  }
}
