
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'BackButton',
})
export default class BackButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  loading: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  router: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  small: boolean;

  @Prop({
    type: String,
    required: false,
    default: 'el-icon-icon-undo',
  })
  icon: string;

  back() {
    if (this.router) this.$router.back();
    else this.$emit('back');
  }
}
