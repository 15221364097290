
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'TAppointmentDateRangeLabel',
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    allDay: {
      type: Boolean,
      required: true,
    },
    split: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
export default class TAppointmentDateRangeLabel extends Vue {
  start: Date;

  end: Date;

  allDay: boolean;
}
