
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'FloatingPanel',
})
export default class FloatingPanel extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  top: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  scroll: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  spaced: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  opaque: boolean;

  optionsInPage = true;

  optionsWidth: number = null;

  containerHeight: number = null;

  handleVisibilityChanged(v: boolean) {
    const r = this.$refs['floatingPanel'] as any;
    const c = this.$refs['floatingContainer'] as any;
    if (!v) {
      this.optionsWidth = r.offsetWidth;
      this.containerHeight = c.offsetHeight;
    } else {
      this.optionsWidth = null;
      this.containerHeight = null;
    }
    this.optionsInPage = v;
  }

  handleVisibilityChangedDebounced: (v: boolean) => void;

  mounted() {
    this.handleVisibilityChangedDebounced = _.debounce((v) => this.handleVisibilityChanged(v), 300);
  }
}
