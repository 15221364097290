
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide } from 'vue-property-decorator';
import simpleMixin from './mixin';

@Component({
  name: 'LsForm',
  mixins: [simpleMixin],
})
export default class LsForm extends Vue {
  @Prop({ type: Object, required: true })
  schema: any;

  @Prop({ type: Object, required: true })
  data: any;

  @Prop({ type: Object, required: false })
  context: any;

  @Provide() get formSchema() {
    return this.schema;
  }

  valid = false;

  get notEmptySection() {
    return this.schema.sections.filter((s) => s.columns.some((c) => c.length));
  }
}
