
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'SpoolerSpoolerButton',
})
export default class SpoolerSpoolerButton extends Vue {
  visible = false;

  @Prop({ required: true, type: String })
  action: string;

  @Prop({ required: true, type: String })
  icon: string;

  handleConfirmCommand(e) {
    this.visible = false;
    this.$emit('click', e);
  }
}
