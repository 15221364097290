
import Vue from 'vue';
import Component from 'vue-class-component';
import { IRepeaterColumn } from 'rt/UIApiControllers/RepeaterControllers/RepeaterStructure';
import { Prop } from 'vue-property-decorator';
import HeaderMixin from './headerMixin';

@Component({
  name: 'BooleanFieldHeader',
})
export default class BooleanFieldHeader extends HeaderMixin {
  handleChangeFilter() {
    if (this.value === null) {
      this.value = true;
    } else if (this.value) {
        this.value = false;
      } else {
        this.value = null;
      }
    this.changeFilter();
  }

  get isValidValueFilter() {
    return this.value != null;
  }
}
