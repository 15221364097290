
import { IEMailNormalizedDTO } from 'dto/IEMailNormalizedDTO';
import { Watch, Prop, Inject } from 'vue-property-decorator';
import Vue from 'vue';
import Component from 'vue-class-component';
import simpleMixin from './mixin';
import { Getter } from 'vuex-class';
import { IInt64ListResult } from 'rt/UIApiControllers/Controls/IInt64ListResult';
import { ListsType } from 'rt/Core/ListsType';

@Component({
  name: 'EmailNormalizedInput',
})
export default class EmailNormalizedInput extends Vue {
  @Inject() formSchema;

  @Getter('lists/list')
  list: (type: ListsType) => Array<IInt64ListResult>;

  @Watch('value')
  handleValueChange(val) {
    this.model = this.value.map((v) => ({
      ...v,
    }));
    if (this.model.length === 0) {
      this.handlePlusClick();
    }
  }

  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: true,
  })
  value: IEMailNormalizedDTO[];

  limit: number = 10;

  listTypes: Array<IInt64ListResult> = [];

  model: IEMailNormalizedDTO[] = null;

  created() {
    this.model = this.value.map((v) => ({
      ...v,
    }));
  }

  async mounted() {
    await this.$store.dispatch('lists/load', this.$enums.ListsType.EmailType);
    this.listTypes = [...this.list(this.$enums.ListsType.EmailType)];
    if (this.model.length === 0) {
      this.handlePlusClick();
    }
  }

  get renderingModel() {
    if (this.model && this.model.length > this.limit) {
      return this.model.slice(0, this.limit);
    }
    return this.model;
  }

  handleEmailInput(item, newValue) {
    item.email = newValue;
    this.$emit('input', [...this.model]);
  }

  handleNoteInput(item, newValue) {
    item.note = newValue;
    this.$emit('input', [...this.model]);
  }

  handleRemoveEmail(item) {
    const index = this.model.indexOf(item);
    this.model.splice(index, 1);
    this.$emit('input', [...this.model]);
  }

  handlePlusClick() {
    this.model = [...this.model, {
      position: this.model.length + 1,
      macroType: this.listTypes[0].value,
      subType: 0,
      email: '',
      displayName: '',
      note: '',
    }];
  }

  handleTypeChange(item: IEMailNormalizedDTO, type: number) {
    if (type < 0) {
      item.macroType = -type;
      item.subType = 0;
    } else {
      item.macroType = 0;
      item.subType = type;
    }
    this.$emit('input', [...this.model]);
  }

  resolveTypeLabel(item: IEMailNormalizedDTO) {
    let value = item.subType;
    if (value === 0) value = -item.macroType;
    if (!this.listTypes || !this.listTypes.length) return '';
    const t = this.listTypes.find((l) => l.value === value);
    if (t) return t.text;
    return this.listTypes[0].text;
  }

  handleMailSendClick(item: IEMailNormalizedDTO) {
    this.$root.$emit('activity', {
      filter: [this.$enums.ActivityType.Email],
      schema: 'webmail',
      activity: {
        companyId: this.formSchema.businessObjectType === this.$enums.BusinessObjectType.Company ? this.data.id : 0,
        contactId: this.formSchema.businessObjectType === this.$enums.BusinessObjectType.Contact ? this.data.id : 0,
        leadId: this.formSchema.businessObjectType === this.$enums.BusinessObjectType.Lead ? this.data.id : 0,
        email: {
          to: [{ address: item.email }],
        },
      },
    });
  }
}
