
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import simpleMixin from './mixin';
import _ from 'lodash';

@Component({
  name: 'TagsInput',
  mixins: [simpleMixin],
})
export default class TagsInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  schema: any;

  @Prop({
    type: Object,
    required: true,
  })
  data: any;

  @Prop({
    type: Array,
    required: false,
    default() {
      return [];
    },
  })
  value: string[];

  inputVisible = false;

  inputValue = '';

  handleClose(tag) {
    const v = [...this.value];
    v.splice(this.value.indexOf(tag), 1);
    this.$emit('input', v);
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs.saveTagInput as any).focus();
    });
  }

  handleInputConfirm() {
    const { inputValue } = this;
    if (inputValue) {
      this.$emit('input', [...this.value, inputValue]);
    }
    this.inputVisible = false;
    this.inputValue = '';
  }
}
