
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';

// tslint:disable-next-line:variable-name
const AnonymousGetter = namespace('anonymous').Getter;
@Component({
  name: 'AnonymousHeader',
})
export default class AnonymousHeader extends Vue {
  @Getter brand;

  @AnonymousGetter logoUrl;
}
