
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import BaseLookup from './BaseLookup';
import addrs, { ParsedMailbox } from 'email-addresses';
import _ from 'lodash/array';
import { IMailAddressDTO } from 'dto/IMailAddressDTO';
import { AddressBookController } from '@/plugins/typings/UIApiControllers/UI/Smart/AddressBookController';

@Component({
  name: 'EMailLookup',
})
export default class EMailLookup extends Vue {
  /* component */
  @Prop({
    type: Array,
    required: true,
  })
  value: IMailAddressDTO[];

  emailValue: string = null;

  focus = false;

  async handleEmailConfirm() {
    const inputValue = this.emailValue;
    if (inputValue) {
      const mails = inputValue.split(/[;,]/g);
      if (mails) {
        const value = [...(this.value ?? [])];
        let newValue = null;
        for (const mail of mails) {
          if (mail && mail.trim().length) {
            const part = addrs.parseOneAddress(mail.trim()) as ParsedMailbox;
            if (part != null && part.parts != null && part.parts.address != null) {
              if (!value.some((m) => new RegExp(`^${m.address}$`, 'i').test(part.parts.address.semantic?.trim()))) {
                newValue = {
                  address: part.parts.address?.semantic?.trim(),
                  displayName: part.parts.name?.semantic?.trim(),
                };

                if (!newValue.displayName) {
                  const res = await this.addressBookController.Email(newValue.address, 1);
                  if (res.length) {
                    newValue.displayName = res[0].displayName;
                  }
                }
              }
            } else {
              newValue = {
                address: mail,
                displayName: '',
              };
            }
          }
        }
        if (newValue != null) {
          value.push(newValue);
          this.$emit('input', value);
        }
      }
    }
    this.emailValue = '';
  }

  handleBlur() {
    window.setTimeout(() => {
      this.handleEmailConfirm();
    }, 200);
    this.focus = false;
  }

  handleFocus() {
    this.focus = true;
  }

  removeEMail(email: IMailAddressDTO) {
    const na = [...this.value];
    _.remove(na, (m) => m.address === email.address);
    this.$emit('input', na);
  }

  cancellationTokenSource: CancelTokenSource;

  addressBookController: AddressBookController = new AddressBookController(this.$http);

  async querySearchAsync(term, cb) {
    // debounce
    if (term && term.length > 2) {
      if (this.cancellationTokenSource) {
        this.cancellationTokenSource.cancel();
      }
      this.cancellationTokenSource = axios.CancelToken.source();
      const res = await this.addressBookController.Email(term, 10, this.cancellationTokenSource.token);
      cb(res ?? []);
    } else {
      cb([]);
    }
  }

  handleSelect(item) {
    this.emailValue = '';
    const value = [...(this.value ?? [])];
    if (!value.some((m) => new RegExp(`^${m.address}$`, 'i').test(item.email))) {
      value.push({
        address: item.email,
        displayName: item.displayName,
      });
    }
    this.$emit('input', value);
  }

  handleBoxClick() {
    (this.$refs['input-element'] as any).focus();
  }
}
