
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';

@Component({
  name: 'App',
})
export default class App extends Vue {
  @Getter locked;

  @Getter brand;

  @Action unlock;

  @Action logout;

  get allowXs() {
    return true;
    /*
    if (this.$route.meta && this.$route.meta.xs) {
      return true;
    }
    return false;
    */
  }
}
