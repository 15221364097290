
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseLookup from './BaseLookup';
import { BusinessObjectType } from 'rt/Core/BusinessObjectType';
import { ISuggestion } from 'rt/Base/Search/Prompters/ISuggestion';
import { ResolveController } from 'rt/UIApiControllers/UI/Smart/ResolveController';
import { ParseController } from 'rt/UIApiControllers/UI/Smart/ParseController';
import TLookupSkin from './LookupSkin.vue';
import { ICloudUserLimit } from '@/plugins/typings/UI/Injector/ICloudUserLimit';

@Component({
  name: 'CrossAnagraphicLookup',
  components: {
    TLookupSkin,
  },
})
export default class CrossAnagraphicLookup extends BaseLookup {
  resolveController = new ResolveController(Vue.axios);

  dialogVisible = false;

  @Prop({
    type: Boolean,
    default: true,
  })
  enableCompany: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  enableContact: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  enableLead: boolean;

  @Prop({
    type: String,
    default: '',
  })
  suggestEMail: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  onlyGeo: boolean;

  enableCompanyPreference = true;

  enableContactPreference = true;

  enableLeadPreference = true;

  get enableCreate(): boolean {
    return this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Company) || this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Contact) || this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Lead);
  }

  get isSearchCompanyEnabled() {
    return this.enableCompany && this.enableCompanyPreference;
  }

  get isSearchContactEnabled() {
    return this.enableContact && this.enableContactPreference;
  }

  get isSearchLeadEnabled() {
    return this.enableLead && this.enableLeadPreference;
  }

  suggestionControllerName() {
    return 'Anagraphic';
  }

  keyPrefix(suggest: ISuggestion): string {
    return BusinessObjectType[suggest.type];
  }

  openDialog() {
    this.dialogVisible = true;
  }

  async handleSelect(val) {
    if ((this.user.limits as ICloudUserLimit).signatureEnrichment && val.key === null) {
      const signature = await new ParseController(Vue.axios).Signature(val.value);
      this.$root.$emit('anagraphic', {
        name: val.value,
        company: this.isSearchCompanyEnabled && this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Company),
        contact: this.isSearchContactEnabled && this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Contact),
        lead: this.isSearchLeadEnabled && this.user.isAuthorizedToCreate(this.$enums.BusinessObjectType.Lead),
        anagraphic: this.createDefaults(signature) ?? {
          companyName: val.value,
        },
        callback: async (payload: { id: number; type: number; title: string }) => {
          if (payload?.id > 0) {
            await this.getAnagraphicTitleOrResolve(payload);
            this.addItem({
              key: `${this.keyPrefix(payload as any)}-${payload.id}`,
              title: payload.title,
            });
          }
        },
      });
    } else {
      this.addItem({
        key: val.key,
        title: val.value,
      });
    }
  }

  private createDefaults(signature) {
    if (signature) {
      const def: any = {};
      if (signature.name) {
        def.name = signature.name;
      }
      if (signature.surname) {
        def.surname = signature.surname;
      }
      if (signature.companyName) {
        def.companyName = signature.companyName;
      }
      return def;
    }
    return null;
  }

  async linkClick(crossAnagraphicId, row) {
    const item = {
      key: `${this.keyPrefix({
        type: +row.TYPE.value,
      } as ISuggestion)}-${crossAnagraphicId}`,
      title: (await this.resolveController.Get(crossAnagraphicId, +row.TYPE.value)).name,
    };

    this.addItem(item);
    this.dialogVisible = false;
  }

  async getAnagraphicTitleOrResolve(crossAnagraphic) {
    if (!crossAnagraphic.title) {
      crossAnagraphic.title = (await this.resolveController.Get(crossAnagraphic.id, crossAnagraphic.type)).name;
    }
  }
}
