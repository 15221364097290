
import Vue from 'vue';
import Component from 'vue-class-component';
import ElementUI from 'element-ui';
import { Getter } from 'vuex-class';
import _ from 'lodash';
import { IUser } from 'rt/UIApiControllers/Authentication/IUser';
import { SuggestController } from 'rt/UIApiControllers/UI/Smart/SuggestController';
import axios, { CancelTokenSource } from 'axios';
import { RouteConfig } from 'vue-router';
import { ISuggestion } from 'rt/Base/Search/Prompters/ISuggestion';

@Component({
  name: 'GlobalSearchInput',
})
export default class GlobalSearchInput extends Vue {
  @Getter routes: RouteConfig[];

  @Getter user: IUser;

  @Getter baseUrl: string;

  private controller: SuggestController = new SuggestController(Vue.axios);

  globalSearch: { term: string } = { term: null };

  suggestions: ISuggestion[] = null;

  cancellationToken: CancelTokenSource;

  loading: boolean = false;

  selectedItem: number = null;

  mounted() {
    this.cancellationToken = axios.CancelToken.source();
  }

  beforeDestroy() {
    this.cancellationToken.cancel();
  }

  get orderedSuggestion(): ISuggestion[] {
    if (this.suggestions) {
      return this.suggestions.slice(0, 10);
      // return _.orderBy(this.suggestions, ['score'], ['desc']).slice(0, 10);
    }
    return null;
  }

  executeGlobalSearch() {
    (this.$refs.globalSearch as ElementUI.Form).validate((valid: boolean) => {
      if (valid) {
        this.$router.push({
          name: this.$routeNames.globalSearch,
          query: { q: this.globalSearch.term },
        });
      }
    });
  }

  queryGlobalSearchSuggestionOnDelay = _.debounce((t) => {
    if (t) {
      this.globalSearchSuggestion();
    }
  }, 400);

  async globalSearchSuggestion() {
    if (this.cancellationToken) {
      this.loading = false;
      this.cancellationToken.cancel();
    }
    try {
      this.loading = true;
      this.selectedItem = null;
      this.cancellationToken = axios.CancelToken.source();

      this.suggestions = [];
      const takeFirsts = (s) => s?.slice(0, 4) ?? [];
      const alls = await Promise.all(
        [
          // this.controller.Anagraphic(this.globalSearch.term, {}, this.cancellationToken.token).then(fullFill),
          this.controller.Company(this.globalSearch.term, {}, this.cancellationToken.token).then(takeFirsts),
          this.controller.Contact(this.globalSearch.term, {}, this.cancellationToken.token).then(takeFirsts),
          this.controller.Lead(this.globalSearch.term, {}, this.cancellationToken.token).then(takeFirsts),
          this.controller.Opportunity(this.globalSearch.term, {}, this.cancellationToken.token).then(takeFirsts),
        ],
      );
      this.suggestions = _.flatten(alls);

      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  goToBORedirect(sugg: ISuggestion) {
    const route = this.$routeHelper.findRouteFromBusinessObjectType(this.routes, sugg.type, sugg.id);
    if (route) {
      this.$router.push(route);
    }
  }

  handleGlobalSearchInput(term: string) {
    if (term !== this.globalSearch.term) {
      this.globalSearch = {
        ...this.globalSearch,
        term,
      };
      this.queryGlobalSearchSuggestionOnDelay(term);
    }
  }

  handleArrowDown() {
    if (this.orderedSuggestion && this.orderedSuggestion.length) {
      if (this.selectedItem === null) {
        this.selectedItem = 0;
      } else {
        this.selectedItem += 1;
        if (this.selectedItem > this.orderedSuggestion.length - 1) {
          this.selectedItem = null;
        }
      }
    }
  }

  handleArrowUp() {
    if (this.orderedSuggestion && this.orderedSuggestion.length) {
      if (this.selectedItem === null) {
        this.selectedItem = this.orderedSuggestion.length - 1;
      } else {
        this.selectedItem -= 1;
        if (this.selectedItem < 0) {
          this.selectedItem = null;
        }
      }
    }
  }

  async handleEnterKey() {
    if (this.selectedItem !== null) {
      const item = this.orderedSuggestion[this.selectedItem];
      if (item != null) {
        this.goToBORedirect(item);
      }
    } else {
      this.executeGlobalSearch();
    }
  }
}
